

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useServerUpdate } from '../../services/apiService';
import { addModel, createDiagram, createPalette } from './ProcessMapUtils';

import './ProcessMap.scss';

const ProcessMap = ({ data: rawData, id, projectId }) => {
  const [diagram, setDiagram] = useState();
  const [_palette, setPalette] = useState();
  const [bound, setBounds] = useState();
  const [data, _setData] = useServerUpdate(rawData, (_, newState) => newState);

  useEffect(() => {
    const diagram = createDiagram(`diagram-${projectId}-${id}`);
    const changeEvent = () => setBounds(diagram.documentBounds);
    diagram.addDiagramListener('DocumentBoundsChanged', changeEvent);
    setDiagram(diagram);
    setPalette(createPalette(`palette-${projectId}-${id}`));
    return () => diagram.removeDiagramListener('DocumentBoundsChanged', changeEvent);    
  }, [id, projectId]);
  
  useEffect(() => {
    if (data) {
      addModel(diagram, data);
    }
  }, [diagram, data]);

  return (
    <div className='companion-processmap'>
      <div className='palette' id={`palette-${projectId}-${id}`}></div>
      <div className='diagram' diagram-height={bound && bound.height} diagram-width={bound && bound.width} id={`diagram-${projectId}-${id}`}></div>
      <pre>
        {JSON.stringify(data, null, 2)}
      </pre>
    </div>
  );
};
     
export default ProcessMap;
    
ProcessMap.propTypes = {
  data       : PropTypes.object.isRequired,
  id         : PropTypes.string.isRequired,
  projectId  : PropTypes.string.isRequired,
  setUndoRedo: PropTypes.func
};
    