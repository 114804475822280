import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from './Spinner';
import './splash-screen.scss';

const SplashScreen = () => {
  const [t] = useTranslation('shared');

  return (
    <div className='mtb-splash-screen-container'>
      <div className='mtb-splash-screen-content'>
        <div className='mtb-splash-screen-icon'>
          <img alt="app-logo" height="96" src="/favicon.png" width="96" />
          <h2>{t('application')}</h2>
        </div>
        <div className='mtb-splash-screen-spinner'>
          <Spinner />
        </div>
      </div>
      <div className='mtb-splash-screen-logo'>
        <img alt="minitab-logo" height="70px" src="/assets/images/minitab-logo.png" width="140px" />
      </div>
    </div>
  );
};

export default SplashScreen;