import React from 'react';

import { useTranslation } from 'react-i18next';

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

import { axisLabelStyle, getReferenceLines, getResponsiveRules } from './Graph.Util';
import { formatValue, fromDate } from '../Control.Utils';
import { useCulture } from '../../../services/i18n';
import { toColor } from '../../../styles/StyleUtils';

HighchartsMore(Highcharts);

const referenceColor = '#ce0000';
const incompletedColor = '#ccc';

const GanttChart = ({ data, resolver }) => {
  const [t] = useTranslation('shared');
  const { 
    activityNames,
    barColor,
    dueDates,
    milestoneDates,
    milestoneNames,
    order,
    progress,
    showToday,
    startDates,
    title, 
    xAxisLabel, 
    yAxisLabel,
  } = data;
  const culture = useCulture();
  const color = toColor(barColor);
  const startValues = resolver(startDates).values[0]?.map(Number.parseFloat);
  const dueValues = resolver(dueDates).values[0]?.map(Number.parseFloat);
  const labels = resolver(activityNames, true).values[0];
  const progressValues = resolver(progress).values[0];
  let seriesData = startValues?.map((start, i) => {
    const due = dueValues && dueValues[i];
    if (!Number.isFinite(start) || !Number.isFinite(due) || start >= due) {
      return null;
    }
    const progressValue = progress ? (progressValues ? Math.max(0, Math.min(1, progressValues[i])) : 0) : 1;
    return {
      low  : start,
      high : due,
      color: progressValue === 0 ? incompletedColor :
        progressValue === 1 ? color :
          {
            linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
            stops         : [
              [0, color],          
              [progressValue, color],
		      [progressValue, incompletedColor],
              [1, incompletedColor]
            ]
          },
      custom: { progress: progress && progressValue },
      name  : activityNames ? ((labels && labels[i]) || '*') : ''
    };
  }).filter(Boolean);
  switch (order) {
    case 'alphanumericorder':
      seriesData = seriesData?.sort((a, b) => a.name.localeCompare(b.name));
      break;
    case 'duedate':
      seriesData = seriesData?.sort((a, b) => a.high - b.high);
      break;
    case 'orderintable':
      break;
    case 'startdate':
      seriesData = seriesData?.sort((a, b) => a.low - b.low);
      break;
    default:
      console.warn(`Unknown order ${order}`);
      break;
  }
  let plotLines = getReferenceLines(
    'control', [milestoneDates, milestoneNames].join(':'), referenceColor, true, resolver);
  if (showToday) {
    plotLines = plotLines.concat(getReferenceLines('manual', [fromDate(new Date()).toString(), t('graphs.today')].join(':'), referenceColor, true));
  }
  const formatDate = value => formatValue({ culture, formatting: 'd' }, value);
  const softMax = Math.max(...plotLines.map(({ value }) => value));
  const softMin = Math.min(...plotLines.map(({ value }) => value));
  return <HighchartsReact
    containerProps={{ style: { height: '100%' } }} 
    highcharts={Highcharts} 
    options={{
      title: { text: title },
      chart: {
        type     : 'columnrange',
        inverted : true,
        animation: false,
      },
      plotOptions: {
        columnrange: {
          animation   : false,
          borderColor : '#333',
          groupPadding: 0.1,
          pointPadding: 0,
          borderWidth : 1.0,
          tooltip     : {
            headerFormat  : '<b>{point.key}</b><br>',
            pointFormatter: function() {
              return `${formatDate(this.low)} - ${formatDate(this.high)} ${this.custom.progress ? Intl.NumberFormat(culture.id, { style: 'percent' }).format(this.custom.progress) : ''}`;
            }
          }
        },
        series: {
          label: { enabled: false }
        }
      },
      xAxis: {
        categories       : seriesData?.map(x => x.name),
        tickWidth        : 1,
        tickLength       : 5,
        tickmarkPlacement: 'on',
        title            : { text: yAxisLabel, style: axisLabelStyle },      
      },
      series: [{
        data: seriesData || []
      }],
      yAxis: {
        title        : { text: xAxisLabel, style: axisLabelStyle },
        lineWidth    : 1,
        gridLineWidth: 0,
        endOnTick    : false,
        startOnTick  : false,
        tickWidth    : 1,
        tickLength   : 5,
        labels       : {
          padding  : 15,
          formatter: ({ value }) => formatDate(value)
        },
        tickPositioner: function() {
          return this.tickPositions.filter(x => Math.floor(x) === x);        
        },
        plotLines,
        softMax,
        softMin,
        max: seriesData?.length ? null : softMax,
        min: seriesData?.length ? null : softMin,
      },
      legend    : { enabled: false },
      responsive: getResponsiveRules(data),
      credits   : {
        enabled: false,
      } }} />;
};
  

export default GanttChart;