import React, { useEffect, useState } from 'react';

const defaultTools = ['form', 'analysiscapture'];

const buildData = data => Object.values(data).reduce((prev, curr) => {
  const { order, link, linkStorageType, itemType, parentId, runtimeId } = curr;
  const parent = (parentId && data[parentId]) ? parentId : 'root';
  return {
    ...prev,
    [runtimeId]: {
      ...prev[runtimeId], 
      order   : Number.parseInt(order),
      itemType: itemType === 'related' && linkStorageType === 'internal' && (link.endsWith('.mpx') || link.endsWith('.mpj')) ? 'mss' : itemType,
    },
    [parent]: {
      ...prev[parent],
      children: [...(prev[parent]?.children || []), runtimeId]
    }
  };
}, data);

const ProjectManager = ({ items, selected, setSelected }) => {
  const [data, setData] = useState();
  useEffect(() => {
    setData(buildData(items || {}));
  }, [items]);

  const supportedTools = [
    ...defaultTools,
    window.enableBrainstorms && 'brainstorm',
    window.enabledProcessMaps && 'processmap',
    window.enabledProcessMaps && 'valuestreammap'
  ].filter(Boolean);
  
  const buildTree = parent => 
    data[parent]?.children && <ul>
      {
        data[parent]?.children
          .map(id => data[id]).sort((l, r) => l.order - r.order)
          .map(({ displayName, itemType, runtimeId }) => 
            <li key={runtimeId}>
              <button className={runtimeId === selected ? 'selected' : ''} disabled={!supportedTools.includes(itemType)}
                onClick={() => setSelected(runtimeId)}>{displayName}
              </button>
              {buildTree(runtimeId)}
            </li>)
      }
    </ul>;

  return (
    <div className="companion-projectmanager">
      {data && data['root'] && buildTree('root')}
    </div>
  );
};

export default ProjectManager;