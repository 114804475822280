import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { init18n, getActiveCulture } from './services/i18n';
import { releaseProject } from './services/apiService';
import { setProjectName } from './services/apiService';
import messageService from './services/messageService';
import Project from './Project';
import './styles/styles.scss';

if (process.env.REACT_APP_AI_IKEY) {
  const appInsights = new ApplicationInsights({ config: {
    connectionString: process.env.REACT_APP_AI_CONNECTIONSTRING
  } });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

let Dev = null;
if (process.env.REACT_APP_ALLOW_DEV) {
  Dev = lazy(() => import('./Dev'));
}

window.addEventListener('unload', () => {
  const id = sessionStorage.getItem('project');
  if (!id) {
    return;
  }
  releaseProject(id);
});

init18n(getActiveCulture()?.id);

const App = () => {
  const [undoRedo, setUndoRedo] = useState({});
  const [renamed, setRename] = useState(false);

  const keyDown = useCallback(e => {
    const ctrlOrMeta = e.ctrlKey || e.metaKey;
    if (ctrlOrMeta && !e.shiftKey && e.key === 'z') {
      undoRedo.canUndo && undoRedo.undo();
      return e.preventDefault();
    }
    if ((ctrlOrMeta && e.shiftKey && e.key === 'z') || (ctrlOrMeta && e.key === 'y')) {
      undoRedo.canRedo && undoRedo.redo();
      return e.preventDefault();
    }
  }, [undoRedo]);

  useEffect(() => {
    window.addEventListener('keydown', keyDown);
    return () => {
      window.removeEventListener('keydown', keyDown);
    };
  }, [keyDown]);

  useEffect(() => {
    messageService.registerUndo(() => undoRedo.canUndo && undoRedo.undo());
    messageService.registerRedo(() => undoRedo.canRedo && undoRedo.redo());
    messageService.sendCanUndo(undoRedo.canUndo);
    messageService.sendCanRedo(undoRedo.canRedo);
  }, [undoRedo]);

  useEffect(() => {
    messageService.registerCloseFile(closedFile => releaseProject(closedFile));
    messageService.registerSetProjectName((file, data) => {
      setProjectName(file, data).then(result => {
        setRename(true);
        messageService.sendRenameProjectComplete(file, { ...result, networkError: false });
      }).catch(() => {
        messageService.sendRenameProjectComplete(file, { success: false, networkError: true });
      });
    });
  }, []);

  return (
    <Router>
      <Switch>
        {process.env.REACT_APP_ALLOW_DEV ?
          <Route exact path="/dev">
            <Suspense fallback={<div></div>}>
              <Dev setUndoRedo={setUndoRedo} undoRedo={undoRedo} />
            </Suspense>
          </Route>
          : null}
        <Route exact path="/ideas/:projectId">
          {
            ({
              match: { params: { projectId } }
            }) => <Project projectId={projectId} renamed={renamed}
              setUndoRedo={setUndoRedo} showProjectManager={false} />
          }
        </Route>
        <Route exact path="/projects/:projectId">
          {
            ({
              match: { params: { projectId } }
            }) => <Project projectId={projectId} renamed={renamed}
              setUndoRedo={setUndoRedo} showProjectManager={true} />
          }
        </Route>
        <Route/>
      </Switch>
    </Router>
  );
};

export default App;
