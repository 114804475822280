
import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const getHost = () => document.querySelector('.companion-form');

export const OverlayAnchor = {
  TopLeft   : 0,
  BottomLeft: 1,
};

export const getOverlayOffset = element => {
  const host = getHost();
  const { top, left } = host.getBoundingClientRect();
  const { top: elementTop, left: elementLeft } = element.getBoundingClientRect();
  // subtracting one to eliminate border
  return { top: elementTop - top - 1, left: elementLeft - left - 1 };
};

const OverlayContainer = ({ children, anchor, position, ...props }) => {
  const ref = useRef();
  const [loaded, setLoaded] = useState(false);
  const [adjustedTop, setAdjustedTop] = useState(position.top);
  const [adjustedLeft, setAdjustedLeft] = useState(position.left);

  useEffect(() => {
    if (loaded && ref.current) {
      const { bottom, height } = ref.current.getBoundingClientRect();
      const newTop = position.top - Math.max(0, bottom - (window.innerHeight || document.documentElement.clientHeight));
      const adjusted = newTop - (anchor === OverlayAnchor.BottomLeft ? height : 0);
      setAdjustedTop(Math.max(0, adjusted));
    }
  }, [loaded, anchor, position.top]);

  useEffect(() => {
    if (loaded && ref.current) {
      const { right } = ref.current.getBoundingClientRect();
      setAdjustedLeft(position.left - Math.max(0, right - (window.innerWidth || document.documentElement.clientWidth)));
    }
  }, [loaded, position.left]);

  return <div 
    ref={element => {
      ref.current = element;
      setLoaded(true);
    }}
    style={{ 
      position: 'absolute',
      top     : adjustedTop,
      left    : adjustedLeft,
    }}
    {...props}>
    {children}
  </div>;
   
};

export const show = (position, content, props) => {
  return createPortal(<OverlayContainer position={position} {...props}>
    {content}
  </OverlayContainer>, getHost());
};

