import React from 'react';
import { useTranslation } from 'react-i18next';

import { AddDirection, DeleteContext } from './Control.Utils';

const Menu = ({ children }) =>
  <ul className="companion-menu">
    {children && children.map((x, index) => {
      const action = {
        visible: true,
        enabled: true,
        ...x
      };
      return !action.visible ? null :
        <li key={index} className={action.enabled ? '' : 'disabled'} onClick={action.enabled ? action.action : undefined}>{action.text}</li>;
    })}
  </ul>;

export const RowFloatieMenu = ({
  id,
  actionSelected,
  actions: { addRows, deleteRows },
  data: { tableId, isRowDataShared, rowCanCreate, rowCanDelete, rowCategoryExists, rowSourceName: source, rowFilter }
}) => {
  const [t] = useTranslation('shared');
  return <Menu>
    {[
      {
        visible: !isRowDataShared || rowCanCreate,
        enabled: !isRowDataShared || (rowCategoryExists && rowFilter !== 'condition'), 
        text   : isRowDataShared ? t('forms.addShared', { source }) : t('forms.addRow'),
        action : async () => {    
          actionSelected(); 
          await addRows(tableId, 1, AddDirection.below, id === 'disabled' ? undefined : id);
        }
      },
      {
        visible: !isRowDataShared || rowCanDelete,
        enabled: id !== 'disabled' && (!isRowDataShared || (rowCategoryExists && rowCanDelete)), 
        text   : isRowDataShared ? t('forms.deleteShared', { source }) : t('forms.deleteRow'), 
        action : async () => {
          actionSelected(); 
          await deleteRows(tableId, DeleteContext.project, [id]);
        }
      }, 
      {
        visible: isRowDataShared, 
        enabled: id !== 'disabled' && rowCategoryExists && rowFilter === 'manual', 
        text   : t('forms.deleteTableShared', { source }),
        action : async () => {
          actionSelected(); 
          await deleteRows(tableId, DeleteContext.table, [id]);
        }
      }
    ]}
  </Menu>;
};

export const ColumnFloatieMenu = ({
  id,
  actionSelected,
  actions: { addColumns, deleteColumns },
  data: { tableId, 
    isColumnDataShared, columnCanCreate, columnCanDelete, columnCategoryExists, columnSourceName :source, columnFilter }
}) => {
  const [t] = useTranslation('shared');
  return <Menu>
    {[ 
      {
        visible: !isColumnDataShared || columnCanCreate,
        enabled: !isColumnDataShared || (columnCategoryExists && columnFilter !== 'condition'), 
        text   : isColumnDataShared ? t('forms.addShared', { source }) : t('forms.addColumn'),
        action : async () => {    
          actionSelected(); 
          await addColumns(tableId, 1, AddDirection.right, id === 'disabled' ? undefined : id);
        }
      },
      {
        visible: !isColumnDataShared || columnCanDelete,
        enabled: id !== 'disabled' && (!isColumnDataShared || (columnCategoryExists && columnCanDelete)), 
        text   : isColumnDataShared ? t('forms.deleteShared', { source }) : t('forms.deleteColumn'), 
        action : async () => {
          actionSelected(); 
          await deleteColumns(tableId, DeleteContext.project, [id]);
        }
      }, 
      {
        visible: isColumnDataShared, 
        enabled: id !== 'disabled' && (columnCategoryExists && columnFilter === 'manual'), 
        text   : t('forms.deleteTableShared', { source }),
        action : async () => {
          actionSelected(); 
          await deleteColumns(tableId, DeleteContext.table, [id]);
        }
      }
    ]}
  </Menu>;
};

export default Menu;