import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const ObservedLazy = props => {
  const { width, height, children, type : Type } = props;
  const placeholderRef = useRef();
  const [inViewport, setInViewport] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
          placeholderRef?.current && observer.unobserve(placeholderRef.current);
          setInViewport(true);
      }
    });
    
    observer.observe(placeholderRef.current);
    return () => observer.disconnect();
  }, []);
  return !inViewport ? (
    <Type
      ref={placeholderRef}
      style={{
        height: `${height}px`,
        width : `${width}px`
      }}
    />
  ) : (
    children
  );
};

ObservedLazy.defaultProps = {
  width : 1,
  height: 1,
  type  : 'div'
};

ObservedLazy.propTypes = {
  width : PropTypes.number,
  height: PropTypes.number,
  type  : PropTypes.string
};

export default ObservedLazy;
