import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { xml2js } from 'xml-js';

import { createClass, createCss, ZERO_WIDTH_SPACE } from '../../styles/StyleUtils';
import { checkForDataOverrides } from './Control.Utils';

import OS from './OS';
import G from './G';
import dataTable from './DataTable';
import LayoutTable from './LayoutTable';
import FC from './FC';

import './FormContent.scss';

const ControlContext = React.createContext();
const ControlProvider = ({ state, children }) => 
  <ControlContext.Provider value={state}>
    {children}
  </ControlContext.Provider>; 

const createControlConsumer = (id, key, getChildren) =>
  <ControlContext.Consumer key={key} >
    { ({ actions, controls }) => {
      const data = controls[id];
      if (!data) {
        return null;
      }
      return getChildren ({ ...data, actions }, controls); 
    }}
  </ControlContext.Consumer>;


const addStyle = (prev, style) => ({ ...prev, style: { ...prev?.style, ...style } });
const setProps = props =>
  Object.entries(props || {}).reduce((prev, [name, value]) => {
    const lower = value?.toLowerCase();
    switch (name) {
      case 'StyleId':
        return { ...prev, className: `${createClass(value)}` };
      case 'RowSpan':
        return { ...prev, rowSpan: value };
      case 'ColumnSpan':
        return { ...prev, colSpan: value };
      case 'MarkerStyle':
        return addStyle(prev, { 'listStyleType': lower });
      case 'Padding':
        const padding = value.split(/[ ,]/);
        return addStyle(prev, {
          paddingLeft  : `${padding[0] || 0}px`,
          paddingTop   : `${padding[1] || padding[0]}px`,
          paddingRight : `${padding[2] || padding[0]}px`,
          paddingBottom: `${padding[3] || padding[1] || padding[0]}px`
        });
      case 'Margin':
        const margin = value.split(/[ ,]/);
        return addStyle(prev, {
          marginLeft  : `${margin[0] || 0}px`,
          marginTop   : `${margin[1] || margin[0]}px`,
          marginRight : `${margin[2] || margin[0]}px`,
          marginBottom: `${margin[3] || margin[1] || margin[0]}px`
        });
      case 'MarkerOffset':
      case 'Column':
      case 'Id':
      case 'Row':
      case 'xmlns':
      case 'xmlns:System':
      case 'xmlns:C':
      case 'xml:space':
        return prev;
      default:
        console.warn(`Unknown attribute ${name} : ${value}`);
        return prev;
    }
  }, { className: '' });

const _continue = (children, context) => children.map((x, index) => replace(x, context, index));
  
const replace = ({ children, name, attributes, text, type }, context, key) => {
  if (type === 'text') {
    return context.allowText ? text : null;
  }  
  const { Id: id } = attributes || {};
  const props = setProps(attributes);
  const tunnel = () => _continue(children, context);
  switch (name) {
    case 'C:FormTable' :
      const rows = children
        .find(x => x.name === 'C:FormTable.Rows')
        .children.filter(x => x.name === 'C:RowInformation')
        .map(({ children }) => 
          children.filter(x => x.name === 'C:CellInformation')
            .map(({ attributes, children }) => ({ attributes, children })));
      return createControlConsumer(id, key, data => {
        const Table = data.type === 'datatable' ? dataTable : LayoutTable;
        return <Table {...props} context={context} data={data} getProps={setProps}
          processChildren={_continue} rows={rows} tableid={id} />;
      }); 
    case 'C:P' :
      return children.length ? 
        <div key={key} {...{ ...props, className: `p ${props.className}` }}>
          {
            _continue(children, { ...context, writeEmpty: children.some(x => x.Name !== 'C:R' || children.length) })
          }
        </div> : 
        <div key={key} {...{ ...props, className: `p ${props.className}` }}><span key={key} className='run empty'>{ZERO_WIDTH_SPACE}</span></div>;
    case 'C:R' :
      const writeEmpty = { context };
      return children.length ? 
        <span key={key} {...{ ...props, className: `run ${props.className}` }}>{_continue(children, { ...context, allowText: true })}</span> : 
        writeEmpty ? <span key={key} className='run empty'>{ZERO_WIDTH_SPACE}</span> : null;
    case 'C:FC':
      return context && (context.rowId === 'disabled' || context.columnId === 'disabled') ?
        null :        
        createControlConsumer(id, key, data => {
          const {
            columnId, columnValueIndex = 0, rowId, rowValueIndex = 0, setUndoRedo, styles, overrideBackground, t 
          } = context || {};
          const {
            id, 
            rawValue,
            conditionalFormat: formats,
            pairwiseQuickFill,
            quickFillOptions,
            style, 
            actions: { update }, 
            actions  
          } = data;
          data = { 
            ...data,
            quickFillOptions: quickFillOptions && (pairwiseQuickFill ? (quickFillOptions[rowId || ''] || {})[columnId || (rowId && id) || ''] : quickFillOptions['']['']),
            value           : (rawValue && (rawValue[columnValueIndex] || [])[rowValueIndex]) || ''
          };
          return <FC context={{ setUndoRedo, rootUri: [context.rootUri, 'controls', id].join('/') }} data={{
            ...data,
            id               : [id, rowId, columnId].filter(Boolean).join('-'),
            baseStyle        : styles[style] || {},
            controlId        : id,
            conditionalFormat: formats && (formats[rowId || ''] || {})[columnId || (rowId && id) || ''],
            ...checkForDataOverrides(data, t),
            actions          : {
              ...actions, 
              overrideBackground,
              update: (id, payload) => update(id, 
                { ...payload,
                  rowId   : rowId,
                  columnId: columnId, 
                })
            }        
          }}/>;
        });
    case 'C:G' :
      return createControlConsumer(id, key, (data, controls) => {
        const { width, height } = data;
        return (
          <div {...{ ...props, className: `g ${props.className}` }} 
            style={{
              width    : width === 'NaN' ? '100%' : `${width}px`,
              height   : height !== 'NaN' && `${height}px`,
              minHeight: 200,
            }}>
            <G controls={controls} data={data}/>
          </div>);
      });
    case 'C:L' :
      return <ul key={key} {...props}>{tunnel()}</ul>;
    case 'C:IMG' :
      return createControlConsumer(id, key, ({ width, height, imageId }) => <img {...props} alt="" height={height} src={`/api/projects/${context.projectId}/media/${imageId}`} width={width} />);
    case 'C:OS' :
      return createControlConsumer(id, key, data => <OS children={_continue(children.find(x => x.name === 'C:OS.Content').children, context)} data={data}/>);
    case 'ListItem' :
      return <li key={key}>{tunnel()}</li>;
    case 'C:PB' :
    case 'InlineUIContainer' :
    case 'FlowDocument' :
      return <Fragment key={key}>{tunnel()}</Fragment>;
    default:
      return <div key={key}><span style={{ fontSize: 36, color: 'red' }}>{`Unknown type ${name}`}</span>{tunnel()}</div>;
  }
};

const FormContent = ({ actions, id, projectId, layoutData, controls, setUndoRedo, styles, size }) => {
  const [t] = useTranslation('shared');
  const [children, setChildren] = useState();
  const [parsed, setParsed] = useState();
  const [controlState, setControlState] = useState({ projectId, controls, actions });
  const [rootUri] = useState(`/api/projects/${projectId}/forms/${id}`);

  useEffect(() => {
    setParsed(xml2js(layoutData, {
      elementsKey                 : 'children',
      alwaysChildren              : true,
      captureSpacesBetweenElements: true,
      ignoreDeclaration           :	true,	
      ignoreInstruction           :	true,
      ignoreComment               :	true,
      ignoreDoctype               : true
    }).children);
  }, [layoutData]);  

  useEffect(() => setControlState({ projectId, controls, actions }), [projectId, controls, actions]);
  useEffect(() => createCss(Object.values(styles), size), [styles, size]);

  useEffect(() => {
    if (!parsed) {
      return parsed;
    }
    setChildren(parsed.map((x, index) => replace(x, { projectId, rootUri, setUndoRedo, styles, t }, index)));
  }, [parsed, projectId, rootUri, setUndoRedo, styles, t]);  


  return (
    <ControlProvider state={controlState}>
      <div className="companion-form-container">
        <div className='companion-form-wrapper'>
          <div className='companion-form'>
            {children}
          </div>
        </div>
      </div>
    </ControlProvider>
  );
};
 
export default FormContent;

FormContent.defaultProps = {};

FormContent.propTypes = {
  controls   : PropTypes.object.isRequired,
  id         : PropTypes.string.isRequired,
  layoutData : PropTypes.string.isRequired,
  projectId  : PropTypes.string.isRequired,
  size       : PropTypes.object.isRequired,
  styles     : PropTypes.object.isRequired,
  setUndoRedo: PropTypes.func,
};
