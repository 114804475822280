import React, { useState, useEffect, useRef } from 'react';

import { ObservedLazy } from '../Common';
import { Floatie } from './Floatie';
import { RowFloatieMenu, ColumnFloatieMenu } from './Menu';
import { createClass } from '../../styles/StyleUtils';
import { AddDirection } from './Control.Utils';
import { getOverlayOffset } from '../../services/overlayService';

const outlineSelector = context => {
  return [
    context.rowId && `table[tableid='${context.tableId}'] tr[rowid='${context.rowId}']`, 
    context.columnId && `table[tableid='${context.tableId}'] td[columnid='${context.columnId}']`
  ].filter(Boolean).join(',');
};

const focusSelectors = (rowIndex, columnIndex, includeReadOnly = true) => {
  const cell = `td[rowindex='${rowIndex}'][columnindex='${columnIndex}']`;
  const control = includeReadOnly ? '.fc' : '.fc:not(.readonly)';
  const selectors = ['input[checked]', 'textarea', 'div[tabindex="0"]', 'input'].map(x => `${control} ${x}`);
  selectors.push(`select${control}`);
  return selectors.map(x => `${cell} ${x}`);
}; 

const getWidth = (widths, { Column, ColumnSpan }) => 
  widths.slice(+Column, +Column + +(ColumnSpan || 1)).reduce((a, b) => a + b);

const RepeatingRowTableCell = ({
  children, context, processChildren, 
  navigation: { nextRow, previousRow }, ...props 
}) => {
  const [overrideBackground, setOverrideBackground] = useState();
  const { rowIndex, columnIndex } = context;
  return <td {...props} columnindex={columnIndex} rowindex={rowIndex} 
    style={{ ...props.style, backgroundColor: overrideBackground }}
    onBlur={() => document.querySelectorAll(outlineSelector(context)).forEach(x => x.classList.remove('highlight'))} 
    onFocus={() => document.querySelectorAll(outlineSelector(context)).forEach(x => x.classList.add('highlight'))}
    onKeyDown={e => {
      const { key, shiftKey, ctrlKey } = e;
      let move;
      switch (key) {
        case 'Enter':
          if (ctrlKey) {
            return;
          }
          move = shiftKey ? previousRow : nextRow;
          e.preventDefault();
          break;
        default:
          return;
      }
      move(rowIndex, columnIndex);
    } }
  >
    {processChildren(children, { ...context, overrideBackground: setOverrideBackground })}
  </td>;
};

const DataTable = ({ context, data, processChildren, rows : domRows, getProps, ...props }) => {   
  const [rowFloatie, setRowFloatie] = useState();
  const [columnFloatie, setColumnFloatie] = useState();
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const ref = useRef();
  const { 
    actions, tableId, columns : columnCount = 0, columnWidths, repeatingColumnIndex, repeatingColumnIds,
    rows: rowCount = 0, repeatingRowIndex, rowIds, style
  } = data;

  useEffect(() => {
    const totalRepeatingRows = Math.max(1, rowIds.length);
    const totalRepeatingRowColumns = domRows[repeatingRowIndex].length
    + (repeatingColumnIndex === -1 ? 0 : Math.max(0, repeatingColumnIds.length - 1));

    const focusCell = (rowIndex, columnIndex) => {
      const element = focusSelectors(rowIndex, columnIndex)
        .reduce((prev, curr) => prev ?? ref.current.querySelector(curr), undefined);
      if (element) {
        element.focus();
        setTimeout(() => {
          if (document.activeElement instanceof HTMLInputElement) {
            document.activeElement.select();
          } 
        }, 50);
        return true;
      }
      return false;
    };

    const nextRow = (rowIndex, columnIndex) => {
      ++rowIndex;
      if (rowIndex >= totalRepeatingRows) {
        return;
      }
      if (!focusCell(rowIndex, columnIndex)) {
        nextRow(rowIndex, columnIndex);
      }
    };
    
    const previousRow = (rowIndex, columnIndex) => {
      --rowIndex;
      if (rowIndex < 0) {
        return;
      }
      if (!focusCell(rowIndex, columnIndex)) {
        previousRow(rowIndex, columnIndex);
      }
    };
    
    const nextColumn = (rowIndex, columnIndex) => {
      ++columnIndex;
      if (columnIndex >= totalRepeatingRowColumns) {
        columnIndex = 0; 
        ++rowIndex;
        if (rowIndex >= totalRepeatingRows) {
          return;
        }
      }
      if (!focusCell(rowIndex, columnIndex)) {
        nextColumn(rowIndex, columnIndex);
      }
    };
    
    const previousColumn = (rowIndex, columnIndex) => {
      --columnIndex;
      if (columnIndex < 0) {
        columnIndex = totalRepeatingRowColumns - 1; 
        --rowIndex;
        if (rowIndex < 0) {
          return;
        }
      }
      if (!focusCell(rowIndex, columnIndex)) {
        previousColumn(rowIndex, columnIndex);
      }
    };

    const onRepeatingRowKeyDown = async ({ key, ctrlKey }, id) => {
      if (ctrlKey && key === 'Enter') {
        await actions.addRows(tableId, 1, AddDirection.below, id === 'disabled' ? undefined : id);
      }
    };

    const navigation = { nextRow, previousRow, nextColumn, previousColumn };
    setRows(Array.from(Array(rowCount).keys())
      .map((_, index) => 
        index === repeatingRowIndex ? (rowIds.length ? rowIds : ['disabled']).map((rowId, rowValueIndex) => 
          <tr key={rowId} className='repeating' rowid={rowId} onKeyDown={e => onRepeatingRowKeyDown(e, rowId)}>
            <ObservedLazy height={30} type='td'>
              { domRows[index].map(({ attributes, children }, columnIndex) => {
                const rowContext = { ...context, tableId, rowId, rowValueIndex, rowIndex: rowValueIndex };
                let props = getProps(attributes);
                const width = getWidth(columnWidths, attributes);
                props = { 
                  ...props, 
                  style: {
                    ...props.style,
                    maxWidth: width,
                    minWidth: width 
                  } 
                };
                return +attributes.Column === repeatingColumnIndex ? 
                  (repeatingColumnIds.length ? repeatingColumnIds : ['disabled']).map((columnId, columnValueIndex) => 
                    <RepeatingRowTableCell key={columnId} className='repeating' columnid={columnId} navigation={navigation}
                      processChildren={processChildren} rowid={rowId} {...props} children={children} 
                      context={{ 
                        ...rowContext, 
                        columnId, 
                        columnValueIndex, 
                        columnIndex: columnIndex + columnValueIndex
                      }}/>
                  ) :
                  <RepeatingRowTableCell key={columnIndex} processChildren={processChildren} 
                    rowid={rowId} {...props} children={children} 
                    context={{
                      ...rowContext, 
                      columnIndex: columnIndex
                       + (+attributes.Column > repeatingColumnIndex ? Math.max(repeatingColumnIds.length, 1) : 0) 
                    } }
                    navigation={navigation}/>;
              })}
            </ObservedLazy>
          </tr>
        )
          : <tr key={index}>
            {domRows[index].map(({ attributes, children }, index) => {
              let props = getProps(attributes);
              const width = getWidth(columnWidths, attributes);
              props = { 
                ...props, 
                style: {
                  ...props.style,
                  maxWidth: width,
                  minWidth: width
                } 
              };
              return +attributes.Column === repeatingColumnIndex ? 
                (repeatingColumnIds.length ? repeatingColumnIds : ['disabled']).map((columnId, columnValueIndex) => 
                  <td key={columnId} className='repeating' columnid={columnId} columnindex={columnValueIndex} 
                    {...props}>
                    { processChildren(children, {
                      ...context, 
                      tableId, 
                      columnId, 
                      columnValueIndex })}
                  </td>
                ) :
                <td key={index} {...props} >
                  { processChildren(children, { ...context, tableId })}
                </td>;
            }
              
            )}
          </tr>
      ));
  }, [actions, domRows, processChildren, getProps, tableId, context,
    rowCount, repeatingRowIndex, rowIds,
    columnCount, columnWidths, repeatingColumnIndex, repeatingColumnIds ]);

  useEffect(() => {
    setColumns(Array.from(Array(columnCount).keys())
      .map((_, index) => columnWidths[index])
      .map((width, index) => index === repeatingColumnIndex ? 
        (repeatingColumnIds.length ? repeatingColumnIds : [null]).map((_, repeating) => <col key={`${index}-${repeating}`} style={{ width: `${width}px` }}/>) 
        : <col key={index} style={{ width: `${width}px` }}/>));
  }, [columnCount, columnWidths, repeatingColumnIndex, repeatingColumnIds]);

  const setRowFloatieInfo = target => {
    if (!target) {
      return;
    }
    const row = target.closest('tr.repeating');
    const { top, left } = getOverlayOffset(ref.current);
    setRowFloatie({
      ...rowFloatie,
      id      : row?.attributes?.rowid?.value,
      position: { top: top + row?.offsetTop, left }
    });
  };

  const setColumnFloatieInfo = target => {
    if (!target) {
      return;
    }
    const cell = target?.closest('td');
    const { top, left } = getOverlayOffset(ref.current);
    setColumnFloatie({
      ...columnFloatie,
      id      : cell?.attributes?.columnid?.value,
      position: { top, left: left + cell?.offsetLeft }
    });
  };

  return <div ref={ref} className='companion-table-adorner'
    onMouseLeave={() => {
      setRowFloatie(undefined);
      setColumnFloatie(undefined);
    }} 
    onMouseMove={({ clientX, clientY, target }) => {
      if (!target || target.closest('.companion-floatie-button') || target.closest('.companion-menu')) {
        return;
      }
      setRowFloatieInfo(target.classList.contains('companion-table-adorner') ? document.elementFromPoint(clientX + 16, clientY) : target);
      setColumnFloatieInfo(target.classList.contains('companion-table-adorner') ? document.elementFromPoint(clientX, clientY + 16) : target);
    }}>
    <table {...props} className={style && createClass(style)}>
      <colgroup>
        {columns}
      </colgroup>
      <tbody>
        {rows}        
      </tbody>
    </table>
    {rowFloatie?.id &&
        <Floatie className="row" position={rowFloatie.position}>
          <RowFloatieMenu actions={actions} 
            actionSelected={() => setRowFloatie(undefined)} data={data} id={rowFloatie.id} />
        </Floatie>
    }
    {columnFloatie?.id &&
          <Floatie className="column" position={columnFloatie.position}>
            <ColumnFloatieMenu actions={actions} 
              actionSelected={() => setColumnFloatie(undefined)} data={data} id={columnFloatie.id} />
          </Floatie>
    }
  </div>;
};

export default DataTable;