import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import messageService from './messageService';

const defaultOptions = {
  headers     : {},
  responseType: 'json',
  contentType : 'json'
};

const verbs = {
  DELETE : 'delete',
  GET    : 'get',
  OPTIONS: 'options',
  PATCH  : 'patch',
  POST   : 'post',
  PUT    : 'put'
};

const request = async (method, url, options) => {
  options = {
    ...defaultOptions,
    ...options
  };
  try {
    const { data } = await axios.request({ url, method, ...options });
    return data;
  } catch (e) {
    if (e.response.status === 401) {
      messageService.sendUnauthorized();
    }
    throw e;
  }
};

export const releaseProject = id => id && navigator.sendBeacon(`/api/projects/${id}/stale`);
export const getProject = id => request(verbs.GET, `/api/projects/${id}`);
export const getTool = (projectId, toolId) => request(verbs.GET, `/api/projects/${projectId}/tools/${toolId}`);
export const redo = (projectId, toolId) => request(verbs.POST, `/api/projects/${projectId}/tools/${toolId}/redo`);
export const undo = (projectId, toolId) => request(verbs.POST, `/api/projects/${projectId}/tools/${toolId}/undo`);

export const upload = async file => {
  const data = new FormData();
  data.append('project', file);
  return await request(verbs.POST, '/api/open', 
    {
      data, 
      headers: { 'Content-Type': 'multipart/form-data' }
    });
};
export const updateWorkflowFields = async (projectId, data) => request(verbs.PUT, `/api/projects/${projectId}/workflow`, { data });
export const setProjectName = async (projectId, data) => request(verbs.PUT, `/api/projects/${projectId}/name`, { data });
export const heartbeat = async (projectId) => request(verbs.GET, `/api/projects/${projectId}/heartbeat`);
export const FormActions = {
  getTeamMembers : async (projectId, formId) => request(verbs.GET, `/api/projects/${projectId}/forms/${formId}/members`),
  setControlValue: async (projectId, formId, controlId, data) => request(verbs.PUT, `/api/projects/${projectId}/forms/${formId}/controls/${controlId}`, { data }),
  addRows        : async (projectId, formId, tableId, data) => request(verbs.POST, `/api/projects/${projectId}/forms/${formId}/controls/${tableId}/rows`, { data }),
  deleteRows     : async (projectId, formId, tableId, data) => request(verbs.DELETE, `/api/projects/${projectId}/forms/${formId}/controls/${tableId}/rows`, { data }),
  addColumns     : async (projectId, formId, tableId, data) => request(verbs.POST, `/api/projects/${projectId}/forms/${formId}/controls/${tableId}/columns`, { data }),
  deleteColumns  : async (projectId, formId, tableId, data) => request(verbs.DELETE, `/api/projects/${projectId}/forms/${formId}/controls/${tableId}/columns`, { data }),
};

export const BrainstormActions = {
  setIdeaExpanded: async (projectId, brainstormId, ideaId, expanded) => request(verbs.POST, `/api/projects/${projectId}/brainstorms/${brainstormId}/ideas/${ideaId}/${expanded ? 'expand' : 'collapse'}`),
};

export const useServerUpdate = (rawData, mashFunc) => {
  const [data, setData] = useState();
  const latestState = useRef();
  const pendingUpdates = useRef([]);
  
  useEffect(() => {
    latestState.current = mashFunc(rawData, rawData);
    setData(latestState.current);
  }, [rawData, mashFunc]);

  const processPending = useCallback(async () => {
    let allChanges = latestState.current;
    while (pendingUpdates.current.length > 0) {
      const action = pendingUpdates.current[0];
      if ((action.dedup && action.dedup === pendingUpdates.current[1]?.dedup) ||
          (action.shouldExecute && !action.shouldExecute(allChanges))) {
        // if there is a dedup key then we can remove any duplicates and just use the last version
        pendingUpdates.current.shift().resolve();
        continue;
      }
      try {
        const latest = await action.callback();
        allChanges = mashFunc(allChanges, latest);       
        pendingUpdates.current.shift().resolve();
      } catch (e) {
        pendingUpdates.current.shift().reject(e);
      }
    }
    latestState.current = allChanges;
    setData(latestState.current);
  }, [mashFunc]);

  const runUpdate = useCallback((callback, options) => new Promise((resolve, reject) => {
    pendingUpdates.current.push({ resolve, reject, callback, ...options });
    if (pendingUpdates.current.length === 1) {
      processPending();
    }
  }), [processPending]);

  return [data, runUpdate, pendingUpdates.current.length];
};
