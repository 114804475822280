import React, { Fragment, useState, useEffect, forwardRef, useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';

import { xml2js } from 'xml-js';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/anchor';
import { Editor } from '@tinymce/tinymce-react';

import { ObservedLazy } from '../Common';
import { toColor, ZERO_WIDTH_SPACE } from '../../styles/StyleUtils';
import { toFlowDocument } from '../../styles/flowDocumentUtils';

import './Controls.scss';
require.context('!file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins/ui/oxide', true, /.*/);

const imageFormats = ['.jpg', '.jpeg', '.png', '.bmp'].join(',');

const addStyle = (prev, style) => ({ ...prev, style: { ...prev?.style, ...style } });
const unknown = (name, value) => console.warn(`Unknown attribute ${name} : ${value}`);
const setProps = (props, name) => {
  props = props || {};
  if (name === 'Bold') {
    props['FontWeight'] = 'Bold';
  }
  if (name === 'Italic') {
    props['FontStyle'] = 'Italic';
  }
  if (name === 'Underline') {
    props['TextDecorations'] = 'Underline';
  }
  // eslint-disable-next-line complexity
  return Object.entries(props).reduce((prev, [name, value]) => {
    const lower = value?.toLowerCase();
    switch (name) {
      case 'Background':
        return addStyle(prev, { background: toColor(value) });
      case 'BorderThickness':
        const border = value.split(/[ ,]/);
        return addStyle(prev, {
          borderLeftWidth  : `${border[0] || 0}px`,
          borderTopWidth   : `${border[1] || border[0]}px`,
          borderRightWidth : `${border[2] || border[0]}px`,
          borderBottomWidth: `${border[3] || border[1] || border[0]}px`
        });
      case 'BorderBrush':
        return addStyle(prev, { borderStyle: 'solid', borderColor: toColor(lower) });
      case 'ColumnSpan':
        return { ...prev, colSpan: lower };
      case 'CellSpacing':
        return addStyle(prev, { borderSpacing: lower });
      case 'Stretch':
        if (lower !== 'fill' && lower !== 'none' && lower !== 'uniform') {
          unknown(name, value);
        }
        return prev;
      case 'FontFamily':
        return addStyle(prev, { fontFamily: lower });
      case 'FontSize':
        return addStyle(prev, { fontSize: `${lower}px` });
      case 'FontStretch':
        return addStyle(prev, { fontStretch: lower });
      case 'FontStyle':
        return addStyle(prev, { fontStyle: lower });
      case 'FontWeight':
        return addStyle(prev, { fontWeight: lower });
      case 'Foreground':
        return addStyle(prev, { color: toColor(value) });
      case 'Height':
        return addStyle(prev, { height: `${lower}px` });
      case 'LineHeight':
        return addStyle(prev, { lineHeight: lower === 'auto' || Number.parseFloat(lower) < 10 ? '' : `${lower}px` });
      case 'MarkerOffset':
        return prev;
      case 'MarkerStyle':
        return addStyle(prev, { listStyleType: lower });
      case 'NavigateUri':
        return { ...prev, href: value };
      case 'Padding':
        const padding = value.split(/[ ,]/);
        return addStyle(prev, {
          paddingLeft  : `${padding[0] || 0}px`,
          paddingTop   : `${padding[1] || padding[0]}px`,
          paddingRight : `${padding[2] || padding[0]}px`,
          paddingBottom: `${padding[3] || padding[1] || padding[0]}px`
        });
      case 'Margin':
        const margin = value.split(/[ ,]/);
        return addStyle(prev, {
          marginLeft  : `${margin[0] || 0}px`,
          marginTop   : `${margin[1] || margin[0]}px`,
          marginRight : `${margin[2] || margin[0]}px`,
          marginBottom: `${margin[3] || margin[1] || margin[0]}px`
        });
      case 'RowSpan':
        return { ...prev, rowSpan: lower };
      case 'TextAlignment':
      case 'Textalignment':
        return addStyle(prev, { textAlign: lower });
      case 'TextDecorations':
        if (lower !== 'underline' && lower !== 'strikethrough') {
          unknown(name, value);
          return prev;
        }
        return addStyle(prev, { textDecoration: lower === 'strikethrough' ? 'line-through' : lower });
      case 'Width':
        return addStyle(prev, { width: `${lower}px` });
      case 'ToolTip':
        return { ...prev, title: value };
      case 'xmlns':
      case 'xml:space':
      case 'xml:lang':
      case 'FlowDirection':
      case 'IsHyphenationEnabled':
      case 'HasTrailingParagraphBreakOnPaste':
        return prev;
      default:
        if (name.startsWith('NumberSubstitution.') || name.startsWith('Typography.')) {
          return prev;
        }
        unknown(name, value);
        return prev;
    }
  }, {});
};

const childElementStyles = (child, context) => {
  const { children, name, attributes } = child;
  const tunnel = () => children.reduce((prev, curr) => childElementStyles(curr, prev), context);
  switch (name) {
    case 'Span.TextDecorations' :
    case 'Run.TextDecorations' :
    case 'Paragraph.TextDecorations' :
    case 'TextDecorationCollection' :
      return tunnel();
    case 'TextDecoration' :
      const { Location } = attributes;
      const decoration = Location.toLowerCase();
      context.style = { ...context.style, textDecoration: `${context.style.textDecoration || ''} ${decoration === 'strikethrough' ? 'line-through' : decoration}` };
      return context;
    default:
      context.filteredChildren.push(child);
      return context;
  }
};

const toJSX = ({ children, name, attributes, type, text }, context, key) => {
  if (type === 'text') {
    // \u00a0 is the &nbsp; character, if field starts with space replace with nbsp to preserve
    // to preserve whitespace replace consevutive spaces with alternating nbsp and space
    return context.allowText ? (text === ' ' ? '\u00a0' : text.replace(/ {2}/g, '\u00a0 ')) : null;
  }  
  const tunnel = (allowText, filtered) =>
    (filtered || children).map((x, index) => toJSX(x, { ...context, allowText }, index));
  if (type !== 'element') {
    return <p key={key}><span style={{ fontSize: 36, color: 'red' }}>{`Unknown type ${type}`}</span>{tunnel()}</p>;
  }  
  switch (name) {
    case 'Table' :
      return <table {...setProps(attributes)} key={key}>{tunnel()}</table>;
    case 'Table.Columns' :
      return <colgroup {...setProps(attributes)} key={key}>{tunnel()}</colgroup>;
    case 'TableColumn' :
      return <col {...setProps(attributes)} key={key}/>;
    case 'TableRowGroup' :
      return <tbody {...setProps(attributes)} key={key}>{tunnel()}</tbody>;
    case 'TableRow' :
      return <tr {...setProps(attributes)} key={key}>{tunnel()}</tr>;
    case 'TableCell' :
      return <td {...setProps(attributes)} key={key}>{tunnel()}</td>;
    case 'Paragraph' :
      return !children || children.length === 0 ? 
        <p key={key}>{ZERO_WIDTH_SPACE}</p> :
        <p {...setProps(attributes)} key={key}>{tunnel(true)}</p>;
    case 'LineBreak' :
      return <Fragment key={key}><br/>{ZERO_WIDTH_SPACE}{tunnel()}</Fragment>;
    case 'Bold':
    case 'Italic':
    case 'Underline':
    case 'Run' :
    case 'Span' :
      const { style, filteredChildren } = 
        children.reduce((prev, curr) => childElementStyles(curr, prev), { style: {}, filteredChildren: [] });
      const props = setProps(attributes, name);
      const content = tunnel(true, filteredChildren);
      if (!content || !content.length) {
        return null;
      }
      return <span {...props} key={key} style={{ ...props.style, ...style }}>{content}</span>;
    case 'List' :
      return <ul {...setProps(attributes)} key={key}>{tunnel()}</ul>;
    case 'ListItem' :
      return <li {...setProps(attributes)} key={key}>{tunnel()}</li>;
    case 'Section' : 
      return <section {...setProps(attributes)} key={key}>{tunnel()}</section>;
    case 'InlineUIContainer' :
      return <Fragment key={key}>{tunnel()}</Fragment>;
    case 'Image':
      const source = children[0].children[0].attributes.UriSource.replace(/^\./g, 'xaml');
      return <img alt={source.split('/').pop()} src={[context.rootUri, source].join('/')} {...setProps(attributes)} key={key}/>;
    case 'Hyperlink' :
      return <a {...setProps(attributes)} key={key}>{tunnel(true)}</a>;
    default:
      return <p key={key}><span style={{ fontSize: 36, color: 'red' }}>{`Unknown type ${name}`}</span>{tunnel()}</p>;
  }
};

const RichTextContent = ({ context, data : { value, isReadOnly }, setLoaded, submitValue }) => {
  const [content, setContent ] = useState(null);
  const [dirty, setDirty ] = useState(false);
  useEffect(() => {
    if (!value) {
      setLoaded(true);
      return;
    }
    setLoaded(false);
    // when/if we switch to tinymce offically then we could bypass jsx and just do a string of html with the conversion
    const reactDOM = xml2js(value, {
      elementsKey                 : 'children',
      alwaysChildren              : true,
      captureSpacesBetweenElements: true,
      ignoreDeclaration           :	true,	
      ignoreInstruction           :	true,
      ignoreComment               :	true,
      ignoreDoctype               : true
    }).children.map((x, index) => toJSX(x, context, index));
    if (window.enableRichTextBox) {
      const markup = ReactDOMServer.renderToStaticMarkup(reactDOM);
      setContent(markup);
    } else {
      setContent(reactDOM);
    }
    setDirty(false);
    setLoaded(true);
  }, [value, context, setLoaded]);

  const submit = useCallback(() => {
    if (!dirty) {
      return;
    }
    const flowDocument = (content && xml2js(content, {
      elementsKey                 : 'children',
      alwaysChildren              : true,
      captureSpacesBetweenElements: true,
      ignoreDeclaration           :	true,	
      ignoreInstruction           :	true,
      ignoreComment               :	true,
      ignoreDoctype               : true
    })?.children?.map((x, index) => toFlowDocument(x, context, index))[0]) || '';
    submitValue(flowDocument);
  }, [content, context, dirty, submitValue]);

  const onKeyDown = ({ key }) => {
    switch (key) {
      case 'Enter':
        submit();
        break;
      default:
        break;
    }
  };
  
  // should wait til on blur to commit to server
  // looks like the nice thing is this is raw html with inline styles
  // i am hoping we can convert this back to xaml right here in js, reverse of xml2js/replace
  return window.enableRichTextBox ? <Editor
    disabled={isReadOnly}
    init={{ 
      menubar       : false, 
      preformatted  : true,
      toolbar_sticky: true,
      skin_url      : '/skins/ui/oxide',
      setup         : editor => {
        editor.ui.registry.addButton('uploadimage', {
          icon    : 'image',
          onAction: () => {
            // the standard tinymce image toolbar opens a dialog and wants a url from a web page
            // you can enable on that dialog a way to upload an image, the problem is it wants to use a tinymce cloud blob storage to store it for you
            // that won't work for us we want ot embed in the companion project so for now
            // lets just let the user pick a file and embed the image in the html, then when we translate to flow document we can embed there as well
            // once it hits the server we can pull out the image and insert into the xaml package and just reference it
            // we could at flow document translation resolve the loaded image if it is from web and emebed it in the flow doc
            // i am not sure we want to open that can of worms, would we allow desktop to just pull from web? 
            // If we do the embedding then the user need to know we are pulling it from web just one time and embedding, not sure what is the best scenario
            // also the desktop only suppoer certain formats we can pull all kinds of crap from the web like gifs that it does not support
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', imageFormats);
            input.onchange = e => {
              const reader = new FileReader();
              reader.onload = () => editor.insertContent(`<img src="${reader.result}" />`);
              reader.readAsDataURL(e.target.files[0]);
            };
            input.click();
          }
        });
      }
    }}
    inline={true}
    plugins='autolink lists link image charmap anchor'
    toolbar={[
      'undo redo | fontselect fontsizeselect | link unlink charmap | uploadimage',
      'forecolor backcolor | bold italic underline strikethrough  | alignleft aligncenter alignright | bullist numlist outdent indent'
    ]}
    value={content}
    onBlur={submit}
    onEditorChange={content => {
      setContent(content);
      setDirty(true);
    }}
    onKeyDown={onKeyDown}
  /> : content;
};

const RichText = forwardRef(({ context, className, data:{ name, isReadOnly }, data, id, style, submitValue }, ref) => {
  const [loaded, setLoaded] = useState();
    
  return <div ref={ref} className={`fc richtext ${name} ${className} ${isReadOnly ? 'readonly' : ''} ${loaded ? '' : 'shimmer'}`} id={id} style={{ ...style, minHeight: style.height, height: undefined }}>
    <ObservedLazy>
      <RichTextContent context={context} data={data} setLoaded={setLoaded} submitValue={submitValue}/>
    </ObservedLazy>
  </div>;
});

export default RichText;