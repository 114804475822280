
import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import DatePicker from 'react-datepicker';
import { parse } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import { formatValue, formatValueNoUnit, getBaseValue, getUnit, fromEditValue, toEditValue, fromDate, toDate, splitWithEscape, useUndoRedo } from './Control.Utils';
import { ZERO_WIDTH_SPACE } from '../../styles/StyleUtils';
import RichText from './RichText';
import { show, getOverlayOffset } from '../../services/overlayService';
import './Controls.scss';

const QuickFillList = ({ header, options, style, onSelected }) =>
  <div className='companion-quickfill-source' style={style}>
    <div className='companion-quickfill-header'>{ZERO_WIDTH_SPACE}{header}</div>
    {options.map(({ display, value }, index) => {
      return <div key={index} className='companion-quickfill-option' onMouseDown={() => onSelected(value)}>{display}</div>;
    })}
  </div>;

const QuickFillHost = ({
  children,
  data: {
    isReadOnly,
    quickFillCategory,
    quickFillField,
    quickFillOptions: { header, sort, items: options } = {},
    rotated
  },
  submitValue,
  findUsers,
  getTeamMembers,
  focusRef,
  focused,
  value
}) => {
  const [hovered, setHovered] = useState();
  const [showList, setShowList] = useState();
  const container = useRef(null);
  const [list, setList] = useState(options);

  useEffect(() => {
    setList(options);
  }, [options]);

  useEffect(() => {
    if (!focused) {
      setShowList(false);
    }
  }, [focused]);

  let listDimensions;
  if (showList) {
    const coordinates = getOverlayOffset(container.current);
    const { width, height } = container.current.getBoundingClientRect();
    listDimensions = {
      top : coordinates.top + height,
      left: coordinates.left,
      width,
      height
    };
  }

  useEffect(() => {
    let latest = true;
    if (findUsers && quickFillCategory === 'teammember' && quickFillField === 'name' && !isReadOnly) {
      setShowList(false);
      setList();
      if (value && value.trim()) {
        setTimeout(async () => {
          if (latest) {
            const [lpUsers, existingMembers] = await Promise.all([findUsers(value), getTeamMembers()]);
            const existingMembersUuids = existingMembers.map(m => {
              const parts = m.fullTeamMember.split(' - ');
              const splitName = parts[0].split(' ');
              return {
                fullname : parts[0],
                firstname: splitName[0],
                lastname : splitName.length > 0 ? splitName[1] : ' ',
                email    : parts[1],
                uuid     : parts[2],
                projectId: parts[3] };
            });

            // if value changed then wait for the next set or results
            if (latest) {
              // if lp user is preexisting team member, then use team member project id if available
              const updatedLpUsers = lpUsers.map(user => {
                const match = existingMembersUuids.find(x => x.uuid === user.Uuid);
                user.ProjectId = match !== undefined ? match.projectId : '00000000-0000-0000-0000-000000000000';
                return user;
              });

              // find any existing team members not already in the lp list, but meeting the quickfill
              // search criteria
              const splitValue = value.split(' ');
              const filteredTeamMembers = existingMembersUuids.filter(member => {
                const doesMatch = (name, normalizedValue) => name && normalizedValue
                  && name.toUpperCase().startsWith(normalizedValue);
                let result = false;
                splitValue.forEach(v => {
                  const search = v.toUpperCase();
                  if (doesMatch(member.firstname, search) ||
                    doesMatch(member.lastname, search) ||
                    doesMatch(member.email, search)) {
                    const alreadyInLP = lpUsers.find(x => x.Uuid === member.uuid);
                    if (!alreadyInLP) {
                      result = true;
                    }
                  }
                });
                return result;
              });
              const newValue = `${value} (NEW)`;
              setList([
                ...updatedLpUsers.map(({ FirstName, LastName, EmailAddress, Uuid, ProjectId }) => ({
                  display: `${FirstName} ${LastName} - ${EmailAddress}`,
                  value  : `${FirstName} ${LastName} - ${EmailAddress} - ${Uuid} - ${ProjectId}`
                })),
                ...filteredTeamMembers.map(({ fullname, email, uuid, projectId }) => ({
                  display: `${fullname} ${email}`,
                  value  : `${fullname} - ${email} - ${uuid} - ${projectId}`
                })),
                { display: newValue, value }
              ]);
              setShowList(true);
            }
          }
        }, 200); // only search when we are done typing
      }
    }
    return () => latest = false;
  }, [findUsers, getTeamMembers, quickFillCategory, quickFillField, value, isReadOnly]);

  return isReadOnly ? children
    : <div ref={container}
      className='companion-quickfill-container'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false) }>
      {children}
      {quickFillCategory !== 'teammember' && quickFillField !== 'name' && list && hovered ? <button className='companion-quickfill-button' tabIndex='-1' onClick={() => {
        setShowList(true);
        focusRef.current.focus();
      }}/> : null}
      {list && showList && focused && show(listDimensions,
        <QuickFillList header={header}
          options={sort && quickFillCategory !== 'teammember' && quickFillField !== 'name' ? list.sort((a, b) => a.display.localeCompare(b.display)) : list}
          style={{
            ...(rotated ? {
              transformOrigin: 'top left',
              transform      : `rotate(-90deg) translateY(${listDimensions.width}px)`,
              minWidth       : listDimensions.height
            } : {
              minWidth: listDimensions.width
            }) }} onSelected={async value => {
            setHovered(false);
            setShowList(false);
            submitValue(value);
          }}/>)}
    </div>;
};

const Textbox = forwardRef(({
  id,
  context: { setUndoRedo },
  className,
  data, findUsers, getTeamMembers, style, submitValue: submitRawValue, value }, ref) => {
  let { textWrapping } = data;
  const {
    controlType,
    controlLocation,
    defaultUnit,
    name,
    quickFillCategory,
    quickFillField,
    rotated,
    isReadOnly,
    type,
    tooltip,
    unitDefinition: { unitList } = {},
    unitType
  } = data;
  const firstUpdate = useRef(true);
  const [hovered, setHovered] = useState();
  const [focused, setFocused] = useState();
  const [display, setDisplay] = useState(() => formatValueNoUnit(data, value));
  const [
    edit, setEdit, resetEdit, focusRef
  ] = useUndoRedo(() => isReadOnly ? display : toEditValue(data, value), setUndoRedo);
  const [unit, setUnit] = useState(() => getUnit(data, value));

  useEffect(() => {

    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setUnit(getUnit(data, value));

    const newDisplay = formatValueNoUnit(data, value);
    setDisplay(newDisplay);
    if (isReadOnly || !focused) {
      resetEdit(isReadOnly ? newDisplay : toEditValue(data, value));
    }
  }, [focused, data, isReadOnly, quickFillCategory, quickFillField, resetEdit, value]);

  const submitValue = (editValue, newUnit) => {
    let raw;
    if (newUnit && (type === 'formula' || controlType === 'formula')) {
      raw = `${editValue}?${newUnit}`;
    } else if (isReadOnly) {
      return;
    } else {
      raw = fromEditValue(data, editValue, newUnit === undefined ? unit : newUnit);
    }
    setUnit(getUnit(data, raw));
    setDisplay(formatValueNoUnit(data, raw));
    resetEdit(toEditValue(data, raw));
    if (value !== raw) {
      submitRawValue(raw);
    }
  };

  textWrapping = textWrapping && (!unitType || unitType === 'none');
  className += ` ${rotated ? 'rotated' : ''} ${textWrapping ? 'wrapping' : ''}`;
  style = textWrapping ? { ...style, height: undefined } : style;
  const currentValue = focused ? edit : display;

  const onKeyDown = e => {
    const { key, altKey, shiftKey } = e;
    switch (key) {
      case 'Enter':
        if (type === 'memo') {
          if (controlLocation === 'repeatingrow' || controlLocation === 'intersecting') {
            if (altKey) {
              e.stopPropagation();
              if (!shiftKey) {
                setEdit(`${edit}\n`);
              }
            } else {
              e.preventDefault();
            }
          }
        } else {
          submitValue(edit);
        }
        break;
      default:
        break;
    }
  };

  return <div ref={ref} className={`fc ${type} ${name} ${isReadOnly ? 'readonly' : ''}`} id={id}
    style={{
      ...style,
      ...(rotated ? {
        height: style.width,
        width : undefined
      } : {}) }}
    title={tooltip}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)} >
    <div className={`${className} textarea-placeholder`}
      style={{
        ...style,
        visibility: 'hidden',
        ...(rotated ? { height: style.width, width: undefined } : {})
      }}>
      {currentValue || ZERO_WIDTH_SPACE}{textWrapping && currentValue.endsWith('\n') && ZERO_WIDTH_SPACE}
    </div>
    <div className={`edit-container ${rotated ? 'rotated' : ''}`} style={style}>
      <QuickFillHost data={data} findUsers={edit !== display && findUsers} focused={focused} focusRef={focusRef}
        getTeamMembers={getTeamMembers} submitValue={submitValue}
        value={currentValue}>
        {textWrapping ?
          (hovered || focused) ?
            <TextareaAutosize
              autoFocus={focused}
              className={className}
              inputRef ={focusRef}
              readOnly={isReadOnly}
              style= {style}
              value={currentValue}
              onBlur={async () => {
                edit !== display && submitValue(edit);
                setFocused(false);
              } }
              onChange={({ target: { value } }) => setEdit(type === 'memo' ? value : value.replace(/[\r\n]*/g, ''))}
              onFocus={() => setFocused(true)}
              onKeyDown={onKeyDown} />
            : <div className={`${className} textarea-placeholder`} style={style} tabIndex='0' onFocus={() => setFocused(true) }>
              {currentValue || ZERO_WIDTH_SPACE}{textWrapping && currentValue.endsWith('\n') && ZERO_WIDTH_SPACE}
            </div>
          :
          <input ref={focusRef} className={className} readOnly={isReadOnly} style={style} type='text'
            value={ currentValue }
            onBlur={async () => {
              edit !== display && submitValue(currentValue);
              setFocused(false);
            } }
            onChange={({ target: { value } }) => setEdit(value)}
            onFocus={() => setFocused(true)}
            onKeyDown={onKeyDown}/>
        }
      </QuickFillHost>
      {unitType && unitType !== 'none' ?
        <select className={`${className} companion-unit-select dropdown`} disabled={type !== 'formula' && controlType !== 'formula' && isReadOnly}
          style={{ ...style, width: undefined, height: undefined }}
          value={unit || defaultUnit}
          onChange={({ target: { value : unit } }) => submitValue((type === 'formula' || controlType === 'formula') ? getBaseValue(value) : edit, unit) }>
          {unitList.map((name, value) => <option key={value} value={value}>{name}</option>)}
        </select>
        : null
      }
    </div>
  </div>;
});

const SelectList = forwardRef(
  ({ data: { listValues, isReadOnly, name, tooltip, type }, id, submitValue, value, className, style }, ref) => {
    const options = (listValues && splitWithEscape(listValues, ',', false)) || [];
    return <select ref={ref} className={`fc ${name} ${type} ${className} ${isReadOnly ? 'readonly' : ''}`}
      id={id} size={type === 'listbox' ? options.length : undefined} style={style} title={tooltip} value={value} onChange={e => !isReadOnly && submitValue(e.target.value)}
      onMouseDown={isReadOnly && (e => e.preventDefault())}>
      {listValues && splitWithEscape(listValues, ',', false).map(x => {
        const [name, value, id, attributes] = splitWithEscape(x, '|');
        return (
          <option key={id} attributes={attributes} underlying-value={value} value={id} >
            {name}
          </option>
        );
      })}
    </select>;
  });

const Richedit = RichText;

const Formula = forwardRef(
  ({ data, ...props }, ref) => <Textbox ref={ref} data={{ ...data, isReadOnly: true, textWrapping: true }} {...props}/>
);

const Memo = forwardRef(
  ({ data, ...props }, ref) => <Textbox ref={ref} data={{ ...data, textWrapping: true }} {...props}/>
);

const RadioButtons = forwardRef(({
  data: { controlLayout, listValues, isReadOnly, name, type },
  id: parentId, submitValue, value: selected, className, style
}, ref) =>
  <div ref={ref} className={`fc radio-buttons ${name} ${type} ${isReadOnly ? 'readonly' : ''}`} id={parentId} style={style}>
    <div className={`${className} ${controlLayout}`} >
      {listValues && splitWithEscape(listValues, ',', false).map(x => {
        const [name, value, id] = splitWithEscape(x, '|');
        return <div key={id} className={`radio-option ${controlLayout}`}>
          <input checked={id === selected} id={`${parentId}-${id}`} name={parentId} readOnly={isReadOnly} type="radio" underlying-value={value} value={id} onChange={({ target: { checked } }) => !isReadOnly && checked && submitValue(id)}/>
          <label htmlFor={`${parentId}-${id}`}>{name}</label><br/>
        </div>;
      })
      }
    </div>
  </div>
);

const Dropdown = SelectList;
const Listbox = SelectList;

const Datepicker = forwardRef(({
  context: { setUndoRedo },
  id, value, showError, submitValue, className, style,
  data, data:{ culture : { id: cultureId, dateLocale }, name, type, isReadOnly, tooltip }
}, ref) => {
  const firstUpdate = useRef(true);
  const [display, setDisplay, resetDisplay, focusRef] = useUndoRedo(() => value ? formatValue(data, value) : '', setUndoRedo);
  const [hovered, setHovered] = useState();
  const [open, setOpen] = useState();

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    resetDisplay(value ? formatValue(data, value) : '');
  }, [value, resetDisplay, data]);

  // ideally we also accept PPPP but there are issues https://github.com/date-fns/date-fns/pull/1795, https://github.com/date-fns/date-fns/pull/1792
  const parseRaw = useCallback(raw => ['P', 'PPP', 'PP'].reduce((curr, format) => {
    try {
      return Number.isNaN(+curr) ? parse(raw, format, new Date(), { locale: dateLocale }) : curr;
    } catch (e) {
      return new Date(Number.NaN);
    }
  }, new Date(Number.NaN)), [dateLocale]);

  const submit = rawValue => {
    if (!rawValue) {
      if (value) {
        submitValue('');
      }
      resetDisplay('');
      return;
    }
    let parsed = parseRaw(rawValue);
    if (!parsed || Number.isNaN(+parsed)) {
      showError('InvalidDate', '', rawValue);
    } else {
      if (parsed.getUTCFullYear() < 100) {
        parsed = new Date(parsed.getUTCFullYear() + (Math.floor(new Date().getUTCFullYear() / 1000) * 1000),
          parsed.getUTCMonth(),
          parsed.getUTCDate());
      }
      const newValue = fromDate(parsed).toString();
      if (value !== newValue) {
        submitValue(newValue);
      }
      resetDisplay(formatValue(data, newValue));
    }
  };

  const onKeyDown = ({ key }) => {
    switch (key) {
      case 'Enter':
        submit(display);
        break;
      case 'ArrowDown':
      case 'ArrowUp':
        setOpen(true);
        break;
      default:
        break;
    }
  };

  let pickerPosition;
  let openDate;
  if (open) {
    openDate = parseRaw(display);
    if (Number.isNaN(+openDate)) {
      openDate = !value ? new Date() : toDate(value);
    }
    pickerPosition = getOverlayOffset(ref.current);
    const { height, width } = ref.current.getBoundingClientRect();
    pickerPosition.top += height;
    pickerPosition.left += width;
  }

  return <div ref={ref} className={`fc ${name} ${type} ${isReadOnly ? 'readonly' : ''}`} id={id} style={style} title={tooltip}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
  >
    <input ref={focusRef}
      className={className}
      readOnly={isReadOnly}
      style={style}
      type='text'
      value={display}
      onBlur={() => submit(display)}
      onChange={({ target:{ value } }) => setDisplay(value)}
      onKeyDown={onKeyDown}
    />
    {hovered && !isReadOnly && <button className='companion-datepicker-button' onClick={() => {
      document.activeElement.blur();
      setOpen(true);
    } }/>}
    {open && show(pickerPosition, <DatePicker
      autoFocus
      inline
      locale={cultureId}
      openToDate={openDate}
      onClickOutside={() => setOpen(false)}
      onSelect={date => {
        submit(formatValue(data, fromDate(date)));
        focusRef.current.focus();
        setHovered(false);
        setOpen(false);
      }}
    />, { className: 'datepicker-popup' })}
  </div>;
});

const Checkbox = forwardRef(({
  id, data:{ label, isReadOnly, name, type }, className, style, value, submitValue
}, ref) =>
  <div ref={ref} className={`fc checkbox ${name} ${type} ${className} ${isReadOnly ? 'readonly' : ''}`} style={style}>
    <input checked={value === 'true'} id={id} readOnly={isReadOnly} type="checkbox" onChange={({ target: { checked } }) => !isReadOnly && submitValue(checked.toString())}/>
    <label htmlFor={id}>{label}</label>
  </div>
);

const Hyperlink = forwardRef(({ data: { linkDisplayText, linkType, link, name, type }, className }, ref) =>
  <a ref={ref} className={`fc ${name} ${type} ${className}`} href={linkType === 'Web' ? link : ''} target={linkType === 'Web' ? '_blank' : ''}>{linkDisplayText}</a>
);

export const createControl = props => {
  const { data:{ type, controlType } } = props;
  switch (type) {
    case 'richedit':
      return <Richedit {...props}/>;
    case 'formula':
      return <Formula {...props}/>;
    case 'memo':
      return <Memo {...props}/>;
    case 'textbox':
      return controlType === 'formula' ? <Formula {...props}/> : <Textbox {...props}/>;
    case 'radiobuttons':
      return <RadioButtons {...props}/>;
    case 'dropdown':
      return <Dropdown {...props}/>;
    case 'datepicker':
      return <Datepicker {...props}/>;
    case 'checkbox':
      return <Checkbox {...props}/>;
    case 'hyperlink':
      return <Hyperlink {...props}/>;
    case 'listbox':
      return <Listbox {...props}/>;
    default:
      console.error(`Unknown type: ${type}`);
      return <div style={{ fontSize: 36, color: 'red' }}>{`Unknown type ${type}`}</div>;
  }
};
