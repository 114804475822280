import React, { useState } from 'react';
import { show, getOverlayOffset } from '../../services/overlayService';

export const Floatie = ({ position, className, children }) => {
  const [menuOpen, setMenuOpen] = useState();
  const [menuPosition, setMenuPosition] = useState();
  return <>
    {show(position, (
      <button className={`companion-floatie-button ${className}`}
        onClick={({ target, nativeEvent:{ offsetX, offsetY } }) => {
          const { top, left, } = getOverlayOffset(target);
          setMenuPosition({ top: top + offsetY, left: left + offsetX });
          setMenuOpen(true);
        } } />
    ))}
    {menuOpen && show(menuPosition, children)}
  </>;
};