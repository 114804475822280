
import { 
  Adornment, Binding, Diagram, GraphObject, Link, Margin, Map, Node, Palette,
  Panel, Placeholder, Point, Shape, Size, Spot, GraphLinksModel, TextBlock, LinkingTool, RelinkingTool, LinkingBaseTool,
} from 'gojs';

import 'gojs/extensions/Figures';

import { toColor, toCSSFont, toDashArray } from '../../styles/StyleUtils';

Diagram.licenseKey = '73f14fe4b70537c702d90776423d6af919a17564ce8149a4080412f6ec0d6b06329fe92802d3df90d5af4efe1c7f93d0d5c039209348023ce131d7db10e484aaba3375e5431a5788f15320c3cbaa2bb3ec7b70f1c3aa73bdda7a';
const $ = GraphObject.make;

const createPoint = ({ x, y }) => new Point(x, y);
const makePort = (name, spot) => ([
  (name && $(Shape, { 
    opacity       : 0,
    width         : 8, 
    height        : 8, 
    portId        : name,
    alignment     : spot, 
    alignmentFocus: spot,
    fromSpot      : spot, 
    toSpot        : spot,
    fromLinkable  : true, 
    toLinkable    : true,
  })),
  $(Shape, 'Circle',
    {
      fill          : '#d90000',
      stroke        : '#8b0000', 
      strokeWidth   : 1,
      width         : 8, 
      height        : 8,
      alignment     : spot, 
      alignmentFocus: Spot.Center,
      cursor        : 'pointer',
    }, new Binding('opacity', '', ({ isHighlighted, diagram:{ currentTool } }) => {
      return isHighlighted && currentTool instanceof LinkingBaseTool ? 1 : 0;
    }).ofObject())
].filter(Boolean));

const textBlockStyles = () => ([
  { visible: false },
  new Binding('visible', 'text', text => text.trim()),
  new Binding('text', 'text', text => text.trim()),
  new Binding('textAlign', 'style', ({ textAlign }) => textAlign),
  new Binding('verticalAlignment', 'style', ({ verticalAlign }) => verticalAlign ? (verticalAlign === 'top' ? Spot.Top : Spot.Bottom) : Spot.Center),
  new Binding('font', 'style', toCSSFont),
  new Binding('isStrikethrough', 'style', ({ strikethrough }) => !!strikethrough),
  new Binding('isUnderline', 'style', ({ underline }) => !!underline),
  new Binding('stroke', 'style', ({ color }) => toColor(color)),
  new Binding('angle', 'textAngle'),
]);

const nodeSelectionAdornmentTemplate = () =>
  $(Adornment, 'Auto',
    $(Shape, { fill: null, stroke: 'deepskyblue', strokeWidth: 1.5, strokeDashArray: [4, 2] }),
    $(Placeholder)
  );

const nodeResizeAdornmentTemplate = () =>
  $(Adornment, 'Spot',
    { locationSpot: Spot.Right },
    $(Placeholder),
    $(Shape, { alignment: Spot.TopLeft, cursor: 'nw-resize', desiredSize: new Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
    $(Shape, { alignment: Spot.Top, cursor: 'n-resize', desiredSize: new Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
    $(Shape, { alignment: Spot.TopRight, cursor: 'ne-resize', desiredSize: new Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),

    $(Shape, { alignment: Spot.Left, cursor: 'w-resize', desiredSize: new Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
    $(Shape, { alignment: Spot.Right, cursor: 'e-resize', desiredSize: new Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),

    $(Shape, { alignment: Spot.BottomLeft, cursor: 'se-resize', desiredSize: new Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
    $(Shape, { alignment: Spot.Bottom, cursor: 's-resize', desiredSize: new Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' }),
    $(Shape, { alignment: Spot.BottomRight, cursor: 'sw-resize', desiredSize: new Size(6, 6), fill: 'lightblue', stroke: 'deepskyblue' })
  );

const nodeRotateAdornmentTemplate = () =>
  $(Adornment,
    { locationSpot: Spot.Center, isPanelMain: true, locationObjectName: 'ELLIPSE' },
    $(Shape, 'Ellipse', { name: 'ELLIPSE', cursor: 'pointer', desiredSize: new Size(7, 7), fill: 'lightblue', stroke: 'deepskyblue' }),
    $(Shape, { geometryString: 'M3.5 7 L3.5 30', isGeometryPositioned: true, stroke: 'deepskyblue', strokeWidth: 1.5, strokeDashArray: [4, 2] })
  );

const node = (contents, { options = {}, hasPorts = true } = {}) => $(Node, 'Spot',
  new Binding('location', ({ location:{ x, y }, size:{ height, width } }) => new Point(x + (width / 2), y + (height / 2)))
    .makeTwoWay(({ x, y }, data, model) => model.setDataProperty(data, 'location', { x: x - (data.size.width / 2), y: y - (data.size.height / 2) })),
  new Binding('zOrder', 'zIndex'),
  new Binding('shadowColor', 'style', ({ shadowColor }) => toColor(shadowColor)),
  new Binding('isShadowed', 'style', ({ shadowColor }) => !!shadowColor),
  {
    selectable                : true, 
    selectionAdornmentTemplate: nodeSelectionAdornmentTemplate(),
    resizable                 : true,
    resizeObjectName          : 'panel',
    resizeAdornmentTemplate   : nodeResizeAdornmentTemplate(),
    rotatable                 : true, 
    rotateAdornmentTemplate   : nodeRotateAdornmentTemplate(),
    layerName                 : 'Foreground',
    shadowBlur                : 8,
    locationSpot              : Spot.Center,
    mouseEnter                : (_e, node) => node.isHighlighted = true,
    mouseLeave                : (_e, node) => node.isHighlighted = false,
    ...options
  },
  contents || unsupportedShape(),
  $(TextBlock,
    { margin: new Margin(2), isMultiline: true, stretch: GraphObject.Fill },
    textBlockStyles()
  ),
  [
    hasPorts && makePort('Left', Spot.LeftCenter),
    hasPorts && makePort('Right', Spot.RightCenter),
    hasPorts && makePort('Top', Spot.TopCenter),
    hasPorts && makePort('Bottom', Spot.BottomCenter),
    hasPorts && makePort('', Spot.Center)
  ].filter(Boolean),
);


const shapeBindings = () => ([
  new Binding('fill', 'style', (({ backgroundColor }) => toColor(backgroundColor))),
  new Binding('strokeDashArray', 'style', ({ borderStyle, borderWidth }) => toDashArray(borderStyle, borderWidth)),
  new Binding('strokeWidth', 'style', ({ borderWidth }) => borderWidth),
  new Binding('stroke', 'style', ({ borderColor }) => toColor(borderColor)),
  new Binding('desiredSize', 'size', ({ height, width }) => new Size(width, height)),
  new Binding('angle', 'angle'),
]);

const unsupportedShape = () => $(Shape, 'Thinx', { fill: 'lightpink', stroke: '#C2185B', layer: 'Foreground' }, new Binding('desiredSize', 'size', ({ height, width }) => new Size(width, height)));

const fromGeometry = geometryString => $(Shape,
  {    
    portId         : 'Center',
    fromLinkable   : true, 
    toLinkable     : true,
    geometryStretch: GraphObject.Fill,
    geometryString,
  }, shapeBindings());

const getArrowScaleFactor = (linkSize, arrowSize) => (linkSize / 2) * ((arrowSize || 9) / 4);
const createBoltCenterPoints = () => {};
const link = (routing = Link.Normal) => $(Link, 
  { 
    routing, 
    curve                     : routing === Link.AvoidsNodes ? Link.JumpOver : Link.None,
    selectable                : true, 
    selectionAdornmentTemplate: $(Adornment, 'Link', $(Shape, { isPanelMain: true, fill: null, stroke: 'deepskyblue', strokeWidth: 0 })),
    relinkableFrom            : true,
    relinkableTo              : true, 
    reshapable                : true,
  },
  new Binding('points', ({ type, to, from, startpoint, points, endpoint }) => {
    if (type === 'ElectronicInformationFlow') {
      createBoltCenterPoints(from, to);
    }
    return ([
      !from && startpoint && createPoint(startpoint), 
      ...points.map(createPoint), 
      !to && endpoint && createPoint(endpoint)
    ]).filter(Boolean);
  }),
  new Binding('fromShortLength', 'style', ({ arrowBegin, borderWidth }) => arrowBegin ? borderWidth : undefined),
  new Binding('toShortLength', 'style', ({ arrowEnd, borderWidth }) => arrowEnd ? borderWidth : undefined),
  (routing === Link.AvoidsNodes ? [
    new Binding('toEndSegmentLength', 'style', ({ arrowBegin, arrowEndSize }) => arrowBegin ? 10 + arrowEndSize : undefined),
    new Binding('fromEndSegmentLength', 'style', ({ arrowEnd, arrowBeginSize }) => arrowEnd ? 10 + arrowBeginSize : undefined),
  ] : []),
  new Binding('shadowColor', 'style', ({ shadow }) => toColor(shadow)),
  new Binding('isShadowed', 'style', ({ shadow }) => !!shadow),
  $(Shape, { isPanelMain: true }, // have to use a double shape here so we can have the two borders, go doesn't support fill on the links so we can't use that either
    new Binding('strokeWidth', 'style', ({ borderWidth, outerBorderWidth }) => borderWidth + outerBorderWidth),
    new Binding('stroke', 'style', ({ outerBorderColor }) => toColor(outerBorderColor)),
    new Binding('strokeDashArray', 'style', ({ outerBorderStyle, outerBorderWidth }) => toDashArray(outerBorderStyle, outerBorderWidth)),
  ),
  $(Shape, { isPanelMain: true },
    new Binding('strokeWidth', 'style', ({ borderWidth }) => borderWidth),
    new Binding('stroke', 'style', ({ borderColor }) => toColor(borderColor)),
    new Binding('strokeDashArray', 'style', ({ borderStyle, borderWidth }) => toDashArray(borderStyle, borderWidth)),
  ),
  $(TextBlock, 
    { alignment: Spot.Center, visible: false },
    new Binding('segmentOffset', 'labelPosition', ({ location, offset }) => new Point(location === 'End' ? -offset : offset, 0)),
    new Binding('segmentIndex', 'labelPosition', ({ location }) => (!location || location === 'Middle') ? Number.NaN : (location === 'End' ? -1 : 0)),
    new Binding('segmentFraction', 'labelPosition', ({ location }) => (!location || location === 'Middle') ? 0.5 : 0),
    new Binding('alignmentFocus', 'labelPosition', ({ location }) => (!location || location === 'Middle') ? Spot.Center : (location === 'End' ? Spot.Right : Spot.Left)),
    textBlockStyles(),
    new Binding('background', 'style', (({ backgroundColor }) => toColor(backgroundColor))),
  ), 
  [
    $(Shape, 
      new Binding('visible', 'style', ({ arrowBegin }) => !!arrowBegin),
      new Binding('fromArrow', 'style', ({ arrowBegin }) => `Backward${arrowBegin}`),
      new Binding('scale', 'style', ({ arrowBeginSize, borderWidth }) => getArrowScaleFactor(borderWidth, arrowBeginSize)), // you have to use scale to get the arrows biggeer, this causes issues then with stroke where we need to invert the scale
      new Binding('fill', 'style', ({ borderColor }) => toColor(borderColor)),
      new Binding('strokeWidth', 'style', ({ outerBorderWidth, arrowBeginSize, borderWidth }) => outerBorderWidth / getArrowScaleFactor(borderWidth, arrowBeginSize)),
      new Binding('stroke', 'style', ({ outerBorderColor }) => toColor(outerBorderColor)),
      new Binding('strokeDashArray', 'style', ({ outerBorderStyle, outerBorderWidth, arrowBeginSize, borderWidth }) => toDashArray(outerBorderStyle, outerBorderWidth / getArrowScaleFactor(borderWidth, arrowBeginSize)))),
    $(Shape,
      new Binding('visible', 'style', ({ arrowEnd }) => !!arrowEnd),
      new Binding('toArrow', 'style', ({ arrowEnd }) => arrowEnd),
      new Binding('scale', 'style', ({ arrowEndSize, borderWidth }) => getArrowScaleFactor(borderWidth, arrowEndSize)),
      new Binding('fill', 'style', ({ borderColor, }) => toColor(borderColor)),
      new Binding('strokeWidth', 'style', ({ outerBorderWidth, arrowEndSize, borderWidth }) => outerBorderWidth / getArrowScaleFactor(borderWidth, arrowEndSize)),
      new Binding('stroke', 'style', ({ outerBorderColor }) => toColor(outerBorderColor)),
      new Binding('strokeDashArray', 'style', ({ outerBorderStyle, outerBorderWidth, arrowEndSize, borderWidth }) => toDashArray(outerBorderStyle, outerBorderWidth / getArrowScaleFactor(borderWidth, arrowEndSize)))),
  ].filter(Boolean)
);

const baseStyle = { // nneed to figure out default sizes, and style etc
  borderColor    : '#FF666666',
  borderWidth    : 1,
  color          : '#FF464646',
  fontName       : 'Segoe UI',
  fontSize       : 13.333333333333334,
  textAlign      : 'center',
  backgroundColor: '#FFF2F2F2'
};
const nodeDefaults = {
  Shape: { size: { height: 60, width: 100 }, style: baseStyle },
  Text : {
    size : { height: 24, width: 96 }, 
    text : 'text',
    style: { 
      ...baseStyle,
      borderColor    : '#00FFFFFF',
      borderWidth    : 0,
      backgroundColor: '#00FFFFFF'
    }  
  },
  Image                : { size: { height: 60, width: 100 }, style: baseStyle },
  Start                : { size: { height: 60, width: 100 }, style: baseStyle },
  End                  : { size: { height: 60, width: 100 }, style: baseStyle },
  Process              : { size: { height: 60, width: 100 }, style: baseStyle },
  Decision             : { size: { height: 60, width: 100 }, style: baseStyle },
  Transport            : { size: { height: 60, width: 100 }, style: baseStyle },
  TransportLeft        : { size: { height: 60, width: 100 }, style: baseStyle },
  Storage              : { size: { height: 60, width: 100 }, style: baseStyle },
  Delay                : { size: { height: 60, width: 100 }, style: baseStyle },
  DelayLeft            : { size: { height: 60, width: 100 }, style: baseStyle },
  Data                 : { size: { height: 60, width: 100 }, style: baseStyle },
  Display              : { size: { height: 60, width: 100 }, style: baseStyle },
  Document             : { size: { height: 60, width: 100 }, style: baseStyle },
  DiskStorage          : { size: { height: 60, width: 100 }, style: baseStyle },
  AlternateProcess     : { size: { height: 60, width: 100 }, style: baseStyle },
  Connection           : { size: { height: 60, width: 60 }, style: baseStyle },
  Department           : { size: { height: 60, width: 100 }, style: baseStyle },
  Phase                : { size: { height: 60, width: 100 }, style: baseStyle },
  Title                : { size: { height: 60, width: 100 }, style: baseStyle },
  VsmInventory         : { size: { height: 43, width: 43 }, style: baseStyle },
  VsmSupplierOrCustomer: { size: { height: 58, width: 96 }, style: baseStyle },
  VsmTruck             : { size: { height: 58, width: 96 }, style: baseStyle },
  VsmWithdrawal        : { size: { height: 48, width: 48 }, style: baseStyle },
  VsmKanbanPost        : { size: { height: 60, width: 100 }, style: baseStyle },
  VsmSupermarket       : { size: { height: 96, width: 29 }, style: baseStyle },
  VsmProcess           : { size: { height: 58, width: 125 }, style: baseStyle },
  VsmBufferStock       : { size: { height: 29, width: 96 }, style: baseStyle },
  VsmKaizenBurst       : { size: { height: 72, width: 115 }, style: baseStyle },
  VsmInformation       : { size: { height: 38, width: 77 }, style: baseStyle },
  VsmLoadLeveling      : { size: { height: 27, width: 107 }, style: baseStyle },
  VsmSignalKanban      : { size: { height: 43, width: 43 }, style: baseStyle },
  VsmProductionKanban  : { size: { height: 27, width: 47 }, style: baseStyle },
  VsmWithdrawalKanban  : { size: { height: 27, width: 47 }, style: baseStyle },
  VsmBatchesKanban     : { size: { height: 60, width: 33 }, style: baseStyle },
  VsmSequencedPullBall : { size: { height: 23, width: 23 }, style: baseStyle },
  VsmFIFOSequenceFlow  : { size: { height: 27, width: 107 }, style: baseStyle },
  VsmOperator          : { size: { height: 17, width: 23 }, style: baseStyle },
  VsmGoSeeScheduling   : { size: { height: 30, width: 48 }, style: baseStyle },
  VsmDecision          : { size: { height: 58, width: 96 }, style: baseStyle },
  VsmWorkCell          : { size: { height: 48, width: 96 }, style: baseStyle },
  VsmPlane             : { size: { height: 80, width: 50 }, style: baseStyle },
  VsmShip              : { size: { height: 58, width: 96 }, style: baseStyle },
  VsmTrain             : { size: { height: 58, width: 96 }, style: baseStyle },
  VsmConveyor          : { size: { height: 20, width: 80 }, style: baseStyle },
  VsmForklift          : { size: { height: 62, width: 80 }, style: baseStyle },
  VsmHandCarrier       : { size: { height: 60, width: 40 }, style: baseStyle },
  VsmHandcart          : { size: { height: 60, width: 56 }, style: baseStyle },
  VsmWaiting           : { size: { height: 43, width: 43 }, style: baseStyle },
};


const createNodeTemplates = () => Object.entries({
  Shape                : node(),
  Text                 : node($(Shape, 'Rectangle', shapeBindings())),
  Image                : node(),
  Start                : node(fromGeometry('FM0.55 0C0.8 0 1 0.2 1 0.45C1 0.45 1 0.45 1 0.45L1 0.55C1 0.8 0.8 1 0.55 1L0.45 1C0.2 1 0 0.8 0 0.55L0 0.45C0 0.2 0.2 0 0.45 0z')),
  End                  : node(fromGeometry('FM0.25 0L0.75 0L1 0.5L0.75 1L0.25 1L0 0.5z')),
  Process              : node(fromGeometry('FM0 0L1 0L1 1L0 1z')),
  Decision             : node(fromGeometry('FM0.5 0L1 0.5L0.5 1L0 0.5z')),
  Transport            : node(fromGeometry('FM0 0.25L0.7 0.25L0.7 0L1 0.5L0.7 1L0.7 0.75L0 0.75z')),
  TransportLeft        : node(fromGeometry('FM0 0.5L0.3 0L0.3 0.25L1 0.25L1 0.75L0.3 0.75L0.3 1z')),
  Storage              : node(fromGeometry('FM0 0L1 0L0.5 1z')),
  Delay                : node(fromGeometry('FM0 0L0.666666 0C1.2 0.25 1.2 0.75 0.66666 1L0 1z')),
  DelayLeft            : node(fromGeometry('FM1 0L0.333333 0C-0.2 0.25 -0.2 0.75 0.33333 1L1 1z')),
  Data                 : node(fromGeometry('FM0.25 0L1 0L0.75 1L0 1z')),
  Display              : node(fromGeometry('FM0 0.5L0.2 0L0.8 0C1.066667 0.25 1.066667 0.75 0.8 1L0.2 1z')),
  Document             : node(fromGeometry('FM0 0L1 0L1 0.9166667C0.75 0.6666667 0.25 1.166667 0 0.9166667z')),
  DiskStorage          : node(fromGeometry('FM0 0.1L0 0.9A0.5 0.1 0 0 0 1 0.9L1 0.1A0.5 0.1 0 0 0 0 0.1zA0.5 0.1 0 0 0 1 0.1')),
  AlternateProcess     : node(fromGeometry('FM0.75 0C0.89 0 1 0.11 1 0.25C1 0.25 1 0.25 1 0.25L1 0.75C1 0.89 0.89 1 0.75 1L0.25 1C0.11 1 0 0.89 0 0.75L0 0.25C0 0.11 0.11 0 0.25 0z')),
  Connection           : node(fromGeometry('FM1 0.5C1 0.78 0.78 1 0.5 1C0.22 1 0 0.78 0 0.5C0 0.22 0.22 0 0.5 0C0.78 0 1 0.22 1 0.5z')),
  Department           : node($(Shape, 'Rectangle', shapeBindings()), { hasPorts: false, options: { layerName: 'Background', selectable: false } }),
  Phase                : node($(Shape, 'Rectangle', shapeBindings()), { hasPorts: false, options: { layerName: 'Background', selectable: false } }),
  Title                : node($(Shape, 'Rectangle', shapeBindings()), { hasPorts: false, options: { layerName: 'Background', selectable: false } }),
  VsmInventory         : node(fromGeometry('FM0.5 0L1 1L0 1zFM0.4 0.5L0.6 0.5M0.5 0.5L0.5 0.9M0.4 0.9L0.6 0.9')),
  VsmSupplierOrCustomer: node(fromGeometry('FM0 0.25L0.3333333 0L0.3333333 0.25L0.6666667 0L0.6666667 0.25L1 0L1 1L0 1z')),
  VsmTruck             : node(fromGeometry('FM0 0L0.6666667 0L0.6666667 0.75L0 0.75zFM0.6666667 0.3333333L1 0.3333333L1 0.7499999L0.6666667 0.7499999zFM0.4166667 0.875C0.4166667 0.9440356 0.3607022 1 0.2916667 1C0.2226311 1 0.1666667 0.9440356 0.1666667 0.875C0.1666667 0.8059644 0.2226311 0.75 0.2916667 0.75C0.3607022 0.75 0.4166667 0.8059644 0.4166667 0.875zFM1 0.875C1 0.9440356 0.9440356 1 0.875 1C0.8059644 1 0.75 0.9440356 0.75 0.875C0.75 0.8059644 0.8059644 0.75 0.875 0.75C0.9440356 0.75 1 0.8059644 1 0.875z')),
  VsmWithdrawal        : node(fromGeometry('FM0.8535526 0.1464458C0.8155519 0.1084454 0.7716736 0.07681973 0.7236062 0.05278617C0.4766169 -0.07070825 0.1762805 0.02940425 0.05278617 0.2763937C-0.07070825 0.5233827 0.02940407 0.8237192 0.2763934 0.9472136C0.5233825 1.070708 0.823719 0.970596 0.9472136 0.7236066L1.036656 0.768328L1 0.5L0.8488266 0.6744131L0.9382693 0.7191345C0.8172446 0.961184 0.5229148 1.059294 0.2808655 0.9382694C0.03881595 0.8172448 -0.05929413 0.522915 0.06173044 0.2808658C0.1827549 0.03881613 0.4770846 -0.05929412 0.7191341 0.06173042C0.7662401 0.0852833 0.8092409 0.1162764 0.8464815 0.1535169z')),
  VsmKanbanPost        : node(fromGeometry('FM0.25 1L0.75 1L0.5 1L0.5 0.5L0.12 0.5L0 0L0.12 0.5L0.5 0.5L0.88 0.5L1 0L0.88 0.5L0.5 0.5L0.5 1')),
  VsmSupermarket       : node(fromGeometry('FM0 0L1 0L1 0.33L0 0.33L1 0.33L1 0.67L0 0.67L1 0.67L1 1L0 1L1 1L1 0')),
  VsmProcess           : node(fromGeometry('FM0 0L1 0L1 1L0 1zFM0 0.25L1 0.25')),
  VsmBufferStock       : node(fromGeometry('FM0 0L1 0L1 1L0 1zFM0 0.3333333L1 0.3333333M0 0.6666667L1 0.6666667')),
  VsmKaizenBurst       : node(fromGeometry('FM0 0.45L0.15 0.35L0 0.15L0.2 0.25L0.275 0L0.35 0.27L0.425 0.05L0.5 0.22L0.575 0L0.65 0.25L0.725 0L0.775 0.225L1 0L0.82 0.335L1 0.395L0.83 0.5L1 0.575L0.85 0.65L0.95 0.75L0.825 0.775L1 1L0.775 0.85L0.725 1L0.65 0.8L0.575 0.95L0.5 0.8L0.425 1L0.35 0.8L0.275 1L0.25 0.8L0 1L0.15 0.775L0 0.7L0.17 0.6z')),
  VsmInformation       : node(fromGeometry('FM0 0L1 0L1 1L0 1z')),
  VsmLoadLeveling      : node(fromGeometry('FM0 0L1 0L1 1L0 1zFM0.3 0.5C0.3 0.7209139 0.2552285 0.9 0.2 0.9C0.1447715 0.9 0.1 0.7209139 0.1 0.5C0.1 0.2790861 0.1447715 0.09999999 0.2 0.09999999C0.2552285 0.09999999 0.3 0.2790861 0.3 0.5zFM0.3 0.1L0.5 0.9M0.5 0.1L0.3 0.9M0.6999999 0.5C0.6999999 0.7209139 0.6552284 0.9 0.6 0.9C0.5447714 0.9 0.4999999 0.7209139 0.4999999 0.5C0.4999999 0.2790861 0.5447714 0.09999999 0.6 0.09999999C0.6552284 0.09999999 0.6999999 0.2790861 0.6999999 0.5zFM0.7 0.1L0.9 0.9M0.9 0.1L0.7 0.9')),
  VsmSignalKanban      : node(fromGeometry('FM0 0L1 0L0.5 1z')),
  VsmProductionKanban  : node(fromGeometry('FM0 0L0.65 0L1 0.35L1 1L0 1z')),
  VsmWithdrawalKanban  : node(fromGeometry('FM0 0L0.65 0L1 0.35L1 1L0 1z')),
  VsmBatchesKanban     : node(fromGeometry('FM0 0.2L0.1 0.2L0.1 0.1L0.2 0.1L0.2 0L0.65 0L1 0.35L1 0.8L0.9 0.8L0.9 0.9L0.8 0.9L0.8 1L0 1zFM0.2 0.1L0.55 0.1M0.55 0.1L0.9 0.45M0.9 0.45L0.9 0.8M0.1 0.2L0.45 0.2M0.45 0.2L0.8 0.55M0.8 0.55L0.8 0.9')),
  VsmSequencedPullBall : node(fromGeometry('FM1 0.5C1 0.7761424 0.7761424 1 0.5 1C0.2238576 1 0 0.7761424 0 0.5C0 0.2238576 0.2238576 0 0.5 0C0.7761424 0 1 0.2238576 1 0.5zFM0.7499999 0.5C0.7499999 0.6380711 0.6380712 0.7499999 0.5 0.7499999C0.3619289 0.75 0.25 0.6380712 0.25 0.5000001C0.2499999 0.3619289 0.3619287 0.25 0.4999999 0.25C0.638071 0.2499999 0.7499998 0.3619286 0.7499999 0.4999998z')),
  VsmFIFOSequenceFlow  : node(fromGeometry('FM0 0L1 0M0 1L1 1M0 0.5L0.1811321 0.5M0.2264151 0.1L0.3773585 0.1M0.2264151 0.1L0.2264151 0.9M0.2264151 0.5L0.3773585 0.5M0.4150943 0.1L0.4150943 0.9M0.4528302 0.1L0.6037736 0.1M0.4528302 0.1L0.4528302 0.9M0.4528302 0.5L0.6037736 0.5M0.7735849 0.5C0.7735849 0.7209139 0.739795 0.9 0.6981132 0.9C0.6564313 0.9 0.6226415 0.7209139 0.6226415 0.5C0.6226415 0.2790861 0.6564313 0.09999999 0.6981132 0.09999999C0.739795 0.09999999 0.7735849 0.2790861 0.7735849 0.5zFM0.8 0.5L0.9811321 0.5M0.9358491 0.3666667L0.9811321 0.5M0.9358491 0.6333333L0.9811321 0.5')),
  VsmOperator          : node(fromGeometry('FM0.75 0.3333333C0.75 0.5174282 0.6380712 0.6666667 0.5 0.6666667C0.3619288 0.6666667 0.25 0.5174282 0.25 0.3333333C0.25 0.1492384 0.3619288 0 0.5 0C0.6380712 0 0.75 0.1492384 0.75 0.3333333zFM0 0.3333333C0 0.3333333 0 0.3333333 0 0.3333333C4.259497E-08 0.7015232 0.2238577 1 0.5000001 0.9999999C0.7761424 0.9999999 0.9999999 0.7015231 0.9999999 0.3333333L0.8999999 0.3333333C0.9 0.6462946 0.7209139 0.8999999 0.5000001 0.8999999C0.2790861 0.9 0.1 0.6462947 0.09999999 0.3333333C0.09999999 0.3333333 0.09999999 0.3333333 0.09999999 0.3333333z')),
  VsmGoSeeScheduling   : node(fromGeometry('FM0 0.75L0 0.625M0.7142857 0.75L0.7142857 0.625M0 0.625L0.2142857 0M0.7142857 0.625L0.9285715 0M0.2142857 0L0.2857143 0.1666667M0.9285715 0L1 0.1666667M0.2857143 0.75C0.2857143 0.8880712 0.221755 1 0.1428571 1C0.06395932 1 0 0.8880712 0 0.75C0 0.6119288 0.06395932 0.5 0.1428571 0.5C0.221755 0.5 0.2857143 0.6119288 0.2857143 0.75zFM0.7142857 0.75C0.7142857 0.8880712 0.6503263 1 0.5714285 1C0.4925307 1 0.4285714 0.8880712 0.4285714 0.75C0.4285714 0.6119288 0.4925307 0.5 0.5714285 0.5C0.6503263 0.5 0.7142857 0.6119288 0.7142857 0.75zFM0.2857143 0.75L0.4285714 0.75')),
  VsmDecision          : node(fromGeometry('FM0.5 0L1 0.5L0.5 1L0 0.5z')),
  VsmWorkCell          : node(fromGeometry('FM0 0L1 0L1 1L0.9 1L0.9 0.1L0.1 0.1L0.1 1L0 1z')),
  VsmPlane             : node(fromGeometry('FM0.5 0L0.575 0.05L0.575 0.3L1 0.6L1 0.7L0.575 0.5L0.575 0.85L0.7 0.95L0.7 1L0.5 0.95L0.3 1L0.3 0.95L0.425 0.85L0.425 0.5L0 0.7L0 0.6L0.425 0.3L0.425 0.05z')),
  VsmShip              : node(fromGeometry('FM0.05 0.75L0 0.55L0.06 0.55L0.06 0.45L0.1 0.45L0.11 0.25L0.18 0.2L0.19 0.05L0.24 0L0.24 0.3L0.3 0.3L0.3 0.5L0.33 0.5L0.35 0.65L0.81 0.65L0.84 0.52L1 0.4L0.91 0.75zFM0.05 0.75L0.1 1L0.88 1L0.91 0.75zFM0.38 0.45L0.5 0.45L0.5 0.63L0.38 0.63zFM0.52 0.45L0.64 0.45L0.64 0.63L0.52 0.63zFM0.66 0.45L0.78 0.45L0.78 0.63L0.66 0.63zFM0.52 0.43L0.64 0.43L0.64 0.25L0.52 0.25zFM0.66 0.43L0.78 0.43L0.78 0.25L0.66 0.25z')),
  VsmTrain             : node(fromGeometry('FM0 0L0 0.7L0.72 0.7L0.77 0.97L0.77 1L1 1L1 0.93L0.85 0.7C0.9 0.6125 0.95 0.525 0.9 0.4375L0.85 0.35L0.74 0.35L0.8 0.1L0.8 0L0.6 0L0.6 0.1L0.66 0.35L0.35 0.35L0.35 0.1L0.4 0zFM0.4 0.85C0.4 0.9328427 0.3328427 0.9999999 0.25 0.9999999C0.1671573 0.9999999 0.09999999 0.9328427 0.09999999 0.85C0.09999999 0.7671572 0.1671573 0.6999999 0.25 0.6999999C0.3328427 0.6999999 0.4 0.7671572 0.4 0.85zFM0.8 0.85C0.8 0.9328427 0.7328427 0.9999999 0.65 0.9999999C0.5671572 0.9999999 0.5 0.9328427 0.5 0.85C0.5 0.7671572 0.5671572 0.6999999 0.65 0.6999999C0.7328427 0.6999999 0.8 0.7671572 0.8 0.85z')),
  VsmConveyor          : node(fromGeometry('FM0.1000001 0.9999999C0.04477159 1 2.929185E-08 0.7761427 -1.409066E-09 0.5000004C-5.117234E-08 0.2238581 0.04477142 2.96439E-07 0.09999988 -2.503253E-08L0.1 0L0.9 0L0.8999998 3.818773E-08C0.9552283 -4.462233E-07 0.9999999 0.2238568 0.9999999 0.4999993C1 0.7761416 0.9552286 0.9999996 0.9000002 0.9999999C0.9000002 0.9999999 0.9000002 0.9999999 0.9000002 0.9999999L0.9 1L0.1 1M0.15 0.5C0.15 0.6380712 0.1276142 0.75 0.1 0.75C0.07238576 0.75 0.05 0.6380712 0.05 0.5C0.05 0.3619288 0.07238576 0.25 0.1 0.25C0.1276142 0.25 0.15 0.3619288 0.15 0.5zFM0.55 0.5C0.55 0.6380712 0.5276142 0.75 0.5 0.75C0.4723857 0.75 0.45 0.6380712 0.45 0.5C0.45 0.3619288 0.4723857 0.25 0.5 0.25C0.5276142 0.25 0.55 0.3619288 0.55 0.5zFM0.9499999 0.5C0.9499999 0.6380712 0.9276142 0.75 0.9 0.75C0.8723857 0.75 0.85 0.6380712 0.85 0.5C0.85 0.3619288 0.8723857 0.25 0.9 0.25C0.9276142 0.25 0.9499999 0.3619288 0.9499999 0.5z')),
  VsmForklift          : node(fromGeometry('FM0.4 0.5L1 0.5L1 0.85L0.4 0.85zFM0.6 0.925C0.6 0.9664214 0.5664213 1 0.525 1C0.4835786 1 0.45 0.9664214 0.45 0.925C0.45 0.8835786 0.4835786 0.85 0.525 0.85C0.5664213 0.85 0.6 0.8835786 0.6 0.925zFM0.95 0.925C0.95 0.9664214 0.9164214 1 0.875 1C0.8335786 1 0.8 0.9664214 0.8 0.925C0.8 0.8835786 0.8335786 0.85 0.875 0.85C0.9164214 0.85 0.95 0.8835786 0.95 0.925zFM0.35 0.05L0.35 0.85M0 0.85L0.35 0.85M0.45 0.5L0.55 0M0.55 0L0.9 0M0.9 0L0.9 0.5M0.45 0.5L0.58 0.35M0.55 0.3L0.62 0.4')),
  VsmHandCarrier       : node(fromGeometry('FM0.05 0.2L0.05 0.45L0.18 0.45L0.36 0.35L0.36 0.2zFM0 0.45L0.18 0.45L0.54 0.25L0.71 0.25L0.26 0.5L0 0.5zFM0.45 0.4L0.45 0.62L0.18 0.97L0.3 1L0.7 0.5L0.71 0.25zFM0.6 0.63L0.9 1L1 0.95L0.7 0.5zFM0.7499999 0.125C0.7499999 0.1940356 0.6828427 0.25 0.6 0.25C0.5171572 0.25 0.45 0.1940356 0.45 0.125C0.45 0.05596441 0.5171572 0 0.6 0C0.6828427 0 0.7499999 0.05596441 0.7499999 0.125z')),
  VsmHandcart          : node(fromGeometry('FM0.65 0.5L0.9 0.5L0.9 0.8L0.65 0.8zFM0.5 0.8L1 0.8L1 0.9L0.5 0.9zFM0.12 0.25L0.3 0.25L0.5 0.37L0.5 0.45L0.3 0.36L0.3 0.6L0.44 0.97L0.36 1L0.12 0.6zFM0.12 0.6L0.2 0.74L0.04 1L0 0.9zFM0.3 0.125C0.3 0.1940356 0.2597056 0.25 0.21 0.25C0.1602944 0.25 0.12 0.1940356 0.12 0.125C0.12 0.05596441 0.1602944 0 0.21 0C0.2597056 0 0.3 0.05596441 0.3 0.125zFM0.6999999 0.9499999C0.6999999 0.9776142 0.6776142 0.9999999 0.65 0.9999999C0.6223857 0.9999999 0.6 0.9776142 0.6 0.9499999C0.6 0.9223856 0.6223857 0.8999999 0.65 0.8999999C0.6776142 0.8999999 0.6999999 0.9223856 0.6999999 0.9499999zFM0.9499999 0.9499999C0.9499999 0.9776142 0.9276142 0.9999999 0.9 0.9999999C0.8723857 0.9999999 0.85 0.9776142 0.85 0.9499999C0.85 0.9223856 0.8723857 0.8999999 0.9 0.8999999C0.9276142 0.8999999 0.9499999 0.9223856 0.9499999 0.9499999zFM0.5 0.4L0.5 0.8')),
  VsmWaiting           : node(fromGeometry('FM0.5 0L1 1L0 1zFM0.7 0.7C0.7 0.8104569 0.6104569 0.9 0.5 0.9C0.389543 0.9 0.3 0.8104569 0.3 0.7C0.3 0.589543 0.389543 0.5 0.5 0.5C0.6104569 0.5 0.7 0.589543 0.7 0.7zFM0.7 0.9L0.6 0.8')),
}).map(([key, value]) => ({ key, value }));


const createLinkTemplates = () => Object.entries({
  Connector                : link(),
  Orthogonal               : link(Link.AvoidsNodes),
  Free                     : link(),
  Oblique                  : link(),
  StraightEndArrow         : link(Link.Normal),
  StraightDoubleArrow      : link(Link.Normal),
  StraightBeginArrow       : link(Link.Normal),
  Straight                 : link(Link.Normal), 
  RightAngleEndArrow       : link(Link.AvoidsNodes),
  RightAngleDoubleArrow    : link(Link.AvoidsNodes),
  RightAngleBeginArrow     : link(Link.AvoidsNodes),
  RightAngle               : link(Link.AvoidsNodes),
  ManualInformationFlow    : link(Link.Normal),
  ElectronicInformationFlow: link(),
  KanbanPath               : link(Link.AvoidsNodes),
  PushArrow                : link(Link.Normal),
  FinishedGoods            : link(Link.Normal),
}).map(([key, value]) => ({ key, value }));

const createGraphModel = () => {
  const model = new GraphLinksModel();
  model.nodeKeyProperty = 'id';
  model.linkKeyProperty = 'id';
  model.linkFromPortIdProperty = 'startAnchor';
  model.linkToPortIdProperty = 'endAnchor';
  model.nodeCategoryProperty = 'type';
  model.linkCategoryProperty = 'type';
  return model;
};

export const createDiagram = elementId => {
  const diagram = $(Diagram, elementId, {   
    padding                : new Margin(15),
    initialContentAlignment: Spot.TopLeft,
    isReadOnly             : false,
    allowHorizontalScroll  : true,
    allowVerticalScroll    : true,
    allowZoom              : true,
    allowSelect            : true,
    allowMove              : true,
    allowDragOut           : false,
    allowRelink            : true,
    allowReshape           : true,
    allowResize            : true,
    allowInsert            : true,
    allowDelete            : true,
    allowGroup             : true,
    allowLink              : true,
    allowTextEdit          : true,
    allowUngroup           : true,
    contentAlignment       : Spot.TopLeft,
    maxSelectionCount      : 1,
    nodeTemplateMap        : new Map(createNodeTemplates()),
    linkTemplateMap        : new Map(createLinkTemplates()),
    model                  : createGraphModel(),
  });  
  diagram.animationManager.isEnabled = false;
  diagram.undoManager.isEnabled = true;
  diagram.toolManager.dragSelectingTool.isEnabled = true;
  [
    [diagram.toolManager.linkingTool, LinkingTool.prototype ],
    [diagram.toolManager.relinkingTool, RelinkingTool.prototype ]
  ].forEach(([tool, prototype]) => {
    // setup both link tools the same
    tool.isEnabled = true;
    tool.isUnconnectedLinkValid = true;
    tool.portGravity = 5;
    tool.doMouseMove = function() {
      const part = this.diagram.findPartAt(this.diagram.lastInput.documentPoint, true);
      if (this.lastPart !== part) {
        this.lastPart && (this.lastPart.isHighlighted = false);
        part && (part.isHighlighted = true);
        this.lastPart = part;
      }
      prototype.doMouseMove.call(tool);
    };
    tool.doDeactivate = function() {
      this.lastPart && (this.lastPart.isHighlighted = false);
      this.lastPart = null;
      prototype.doDeactivate.call(tool);
    };
  });
  diagram.toolManager.draggingTool.isEnabled = true;
  diagram.toolManager.draggingTool.dragsLink = true;
  diagram.toolManager.rotatingTool.handleAngle = 270;
  diagram.toolManager.rotatingTool.handleDistance = 30;
  diagram.toolManager.rotatingTool.snapAngleMultiple = 15;
  diagram.toolManager.rotatingTool.snapAngleEpsilon = 15;
  diagram.addDiagramListener('LayoutCompleted', e => {
    e.diagram.links.each(x => {
      if (!!x.toNode !== !!x.fromNode && x.pointsCount === 1) {
        // we have a one link that is connected to only one node.
        // in these cases we don't have the exact points in the link 
        // so lets tell go to figure it out for us 
        setTimeout(() => x.invalidateRoute());
      } else if (!x.toNode && !x.fromNode && x.pointsCount === 2
         && (x.routing === Link.AvoidsNodes || x.routing === Link.Orthogonal)) {
        // if we have a loose right angle link without 2 points we need to have go compute the mid point angle
        setTimeout(() => x.invalidateRoute());
      }
    });
  });
  return diagram;
};

export const createPalette = elementId => {
  const palette = $(Palette, elementId, {
    padding         : new Margin(5, 20, 5, 5),
    contentAlignment: Spot.Center,
    nodeTemplateMap : new Map(createNodeTemplates()),
    linkTemplateMap : new Map(createLinkTemplates()),
    model           : createGraphModel(),
  });
  palette.model.nodeDataArray = createNodeTemplates().filter(({ key }) => key !== 'Shape' && key !== 'Image').map(({ key }) => ({ type: key, ...nodeDefaults[key] }));
  palette.animationManager.isEnabled = false;
  return palette;
};

const organizeCrossFunctionalParts = ({ departments, phases, title }) => {
  if (!departments || departments.length === 0) {
    return [];
  }
  title.location = { x: 0, y: 0 };
  phases = phases.sort(({ index: a }, { index: b }) => a - b);
  phases = [
    { ...phases[0], text: '', rtf: '', id: '', size: { ...phases[0].size, width: departments[0].size.width } } // top left corner 
    , ...phases
  ];
  departments = departments.sort(({ index: a }, { index: b }) => a - b);
  return [
    title,
    ...phases.reduce(({ top, left, shapes }, curr) => ({
      shapes: [
        ...shapes,
        { ...curr, location: { x: left, y: top } }, // phase header
        ...departments.reduce(({ top, left, shapes, phase }, curr) => ({ // each row
          shapes: [
            ...shapes, 
            { ...curr,
              // if the phase doesn't have an id then its the header column for the department
              id      : phase.id ? curr.id : '', 
              text    : phase.id ? '' : curr.text, 
              rtf     : phase.id ? '' : curr.rtf, 
              location: { x: left, y: top }, 
              size    : { ...curr.size, width: phase.size.width }
            }
          ],
          top: top + curr.size.height,
          left,
          phase
        }), { shapes: [], top: curr.size.height + top, left, phase: curr }).shapes
      ],
      top,
      left: left + curr.size.width,
    }), { shapes: [], top: title.size.height || 0, left: 0 }).shapes,
  ];
};

export const addModel = (diagram, { gridVisible, snapToGrid, shapes, links, crossFunctionalTable = {} }) => {
  diagram.model.nodeDataArray = [...shapes, ...organizeCrossFunctionalParts(crossFunctionalTable)];
  diagram.model.linkDataArray = links;
  diagram.toolManager.draggingTool.isGridSnapEnabled = !!snapToGrid;
  diagram.toolManager.resizingTool.isGridSnapEnabled = !!snapToGrid;
  if (gridVisible) {
    diagram.grid = $(Panel, Panel.Grid, 
      { gridCellSize: new Size(20, 20), visible: true },
      $(Shape, 'LineH', { stroke: '#d3d3d3', strokeWidth: 1, strokeDashArray: [1, 1] }),
      $(Shape, 'LineV', { stroke: '#d3d3d3', strokeWidth: 1, strokeDashArray: [1, 1] })
    );
  } else {
    diagram.grid = null;
  }
 
};
