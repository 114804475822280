import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { axisLabelStyle, getLegend, getReferenceLines, getResponsiveRules } from './Graph.Util';
import { toColor } from '../../../styles/StyleUtils';


const BarChart = ({ data, resolver }) => {
  const { 
    comparisonBarColor,
    comparisonDataValues,
    dataValues: dataValueSource, 
    mainBarColor,
    referenceLines,
    referenceLineColor,
    referenceLinesType,
    title, 
    xAxisLabel, 
    xLabels,
    xLabelAngle,
    xLabelDisplayOrder,
    yAxisLabel,
  } = data;
  const valueSource = resolver(dataValueSource);
  const compareSource = resolver(comparisonDataValues);
  const comparisonValues = compareSource.values[0]?.map(Number.parseFloat);
  const dataValues = valueSource.values[0]?.map(Number.parseFloat);
  let groups;
  if (xLabels) {
    const labels = resolver(xLabels, true).values[0];
    groups = dataValues?.map((value, i) => {
      const label = labels && labels[i];
      if (!label && !Number.isFinite(value) && (!comparisonDataValues || !Number.isFinite(comparisonValues[i]))) {
        return null;
      }
      return {
        category: (label || '*'), 
        value   : Number.isFinite(value) ? value : 0, 
        compare : (comparisonDataValues && comparisonValues && Number.isFinite(comparisonValues[i])) 
          ? comparisonValues[i] : 0
      };
    }).filter(Boolean);
  } else {
    groups = dataValues?.map((value, i) => ({
      category: '', 
      value, 
      compare : comparisonDataValues ? comparisonValues[i] : 0
    })).filter(({ value, compare }) => Number.isFinite(value) || (comparisonDataValues && Number.isFinite(compare)));
  }
  if (xLabelDisplayOrder === 'alphanumericorder') {
    groups = groups?.sort((a, b) => a.category.localeCompare(b.category));
  }
  const plotLines = getReferenceLines(
    referenceLinesType, referenceLines, toColor(referenceLineColor), false, resolver);
  const softMax = Math.max(...plotLines.map(({ value }) => value));
  const softMin = Math.min(...plotLines.map(({ value }) => value));
  return <HighchartsReact
    containerProps={{ style: { height: '100%' } }} 
    highcharts={Highcharts}
    options={{
      title: { text: title },
      chart: {
        type     : 'column',
        animation: false,
      },
      plotOptions: {
        column: {
          animation   : false,
          borderColor : '#333',
          groupPadding: 0.1,
          pointPadding: 0,
          borderWidth : 1.0,
        },
        series: {
          label: { enabled: false }
        }
      },
      xAxis: {
        categories: groups?.map(x => x.category),
        labels    : {
          rotation: -xLabelAngle || null
        },
        tickWidth        : 1,
        tickLength       : 5,
        tickmarkPlacement: 'on',
        title            : { text: xAxisLabel, style: axisLabelStyle },
      },
      series: [
        { 
          color: toColor(mainBarColor),
          data : groups?.map(x => x.value) || [], 
          name : valueSource.name,
        }, 
        comparisonDataValues ?
          { 
            color: toColor(comparisonBarColor),
            data : groups?.map(x => x.compare) || [],
            name : compareSource.name
          }
          : null
      ].filter(Boolean),
      yAxis: {
        title        : { text: yAxisLabel, style: axisLabelStyle },
        lineWidth    : 1,
        gridLineWidth: 0,
        endOnTick    : false,
        tickWidth    : 1,
        tickLength   : 5,
        softMax,
        softMin,
        max          : groups?.length ? null : softMax,
        min          : groups?.length ? null : softMin,
        plotLines    
      },
      legend: {
        ...getLegend(data),
        enabled: !!comparisonDataValues,
      },
      responsive: getResponsiveRules(data),
      credits   : {
        enabled: false,
      } }} />;
};
  

export default BarChart;