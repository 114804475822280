import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useCulture } from '../../services/i18n';
import { createClass, createConditionalFormat } from '../../styles/StyleUtils';
import { createControl } from './Controls';
import { formatValue } from './Control.Utils';
import { show, getOverlayOffset, OverlayAnchor } from '../../services/overlayService';

const focusSelector = 'input, textarea, div[tabindex="0"]';

const FC = ({ context, data }) => {
  const { 
    actions: { overrideBackground, update, findUsers, getTeamMembers },
    baseStyle,
    dataError,
    conditionalFormat : rawFormat,
    controlId,
    height,
    id,
    rotated,
    style, 
    tooltip,
    width,
    value : initialValue = '',
  } = data;

  const firstUpdate = useRef(true); 
  const [value, setValue] = useState(initialValue);
  const [conditionalFormat, setConditionalFormat] = useState(() => {
    const format = createConditionalFormat(baseStyle, rawFormat);
    if (overrideBackground) {
      setImmediate(() => {
        overrideBackground((format || {}).backgroundColor); 
      });
    }
    return format;
  });
  const [error, setError] = useState();
  const [timer, setTimer] = useState();
  const culture = useCulture();
  const [t] = useTranslation('shared');
  const ref = useRef(null);
  
  data = { ...data, culture };
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const format = createConditionalFormat(baseStyle, rawFormat);
    setConditionalFormat(format);
    if (overrideBackground) {
      overrideBackground((format || {}).backgroundColor); 
    }
  }, [overrideBackground, baseStyle, rawFormat]);

  useEffect(() => setValue(initialValue), [initialValue]);

  const createValidationMessage = useCallback((code, context, value) => {
    const { dataFormat, displayName, maximum, minimum, name } = data;
    const max = maximum ? formatValue(data, maximum)
      : dataFormat === 'Integer' ? Number.MAX_SAFE_INTEGER : dataFormat === 'Float' ? Number.MAX_VALUE : '';
    const min = minimum ? formatValue(data, minimum) 
      : dataFormat === 'Integer' ? Number.MIN_SAFE_INTEGER : dataFormat === 'Float' ? Number.MIN_VALUE : '';
    return t(`errors.validation.${code}`, { tool: context, value, property: displayName || name, min, max });
  }, [data, t]);

  const showError = useCallback((errorCode, validationContext, value) => {
    const focusElement = ref.current.querySelector(focusSelector);
    focusElement && setTimeout(() => focusElement.focus(), 50);
    setError(createValidationMessage(errorCode, validationContext, value));
    clearTimeout(timer);
    setTimer(setTimeout(setError, 10000));
    setValue(initialValue);
  }, [createValidationMessage, initialValue, timer]);

  const [submitValue, _cancel, submitPending] = useDebouncedCallback(useCallback(async newValue => {
    try {      
      clearTimeout(timer);
      await update(controlId, { value: newValue });   
    } catch ({ response:{ data: { errorCode, validationContext } } }) {
      showError(errorCode, validationContext);
    }
  }, [timer, update, controlId, showError]), 50);

  useEffect(() => () => submitPending(), [submitPending]);

  const props = {
    context,
    id,
    className: createClass(style),
    ref,
    data,
    style    : {
      width : width === 'NaN' ? '100%' : `${width}px`,
      height: height !== 'NaN' && `${height}px`,
      ...conditionalFormat,
    },
    value,
    showError,
    findUsers,
    getTeamMembers,
    submitValue: newValue => {
      setValue(newValue);
      setError();
      submitValue(newValue);
    }
  };

  return <>
    {error && show(getOverlayOffset(ref.current), (
      <div onClick={() => {
        clearTimeout(timer);
        setError();
        const focusElement = ref.current.querySelector(focusSelector);
        focusElement && focusElement.focus();
      }}>
        <p>
          <img alt='' src='/assets/images/error_16x16.png'/>
          <span>{error}</span>
        </p>
      </div>), { anchor: OverlayAnchor.BottomLeft, className: 'errorBubble' })}
    {dataError ? 
      <div className='companion-data-error' style={rotated ? { 
        height: props.style.width,
        width : undefined
      } : { width: props.style.width }} title={tooltip}>{createControl(props)}<img alt='' className={rotated ? 'rotated' : ''} src='/assets/images/database_disconnect_16x16.png'/></div>
      : createControl(props)}
  </>;
};

export default FC;

