import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

import { registerLocale } from 'react-datepicker';
import { enUS, enGB, fr, de, pt, es, ja, ko, zhCN } from 'date-fns/locale';
import buildMatchFn from 'date-fns/locale/_lib/buildMatchFn';
import buildFormatLongFn from 'date-fns/locale/_lib/buildFormatLongFn';
const dateLocales = {
  'en-US': enUS,
  'en-GB': enGB,
  'fr-FR': fr,
  'de-DE': de,
  'pt-BR': pt,
  'es-MX': es,
  'ja-JP': ja,
  'ko-KR': ko,
  'zh-CN': zhCN
};

// https://github.com/date-fns/date-fns/pull/1792
es.match.day = buildMatchFn({
  matchPatterns: {
    narrow     : /^[dlmjvs]/i,
    short      : /^(do|lu|ma|mi|ju|vi|sa)/i,
    abbreviated: /^(dom|lun|mar|mie|jue|vie|sab)/i,
    wide       : /^(domingo|lunes|martes|mi[ée]rcoles|jueves|viernes|s[áa]bado)/i
  },
  defaultMatchWidth: 'wide',
  parsePatterns    : {
    narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^j/i, /^v/i, /^s/i],
    any   : [/^do/i, /^lu/i, /^ma/i, /^mi/i, /^ju/i, /^vi/i, /^sa/i]
  },
  defaultParseWidth: 'any'
});


// changes to match companion dashboard
zhCN.formatLong.date = buildFormatLongFn({
  formats: {
    full  : 'y\'年\'M\'月\'d\'日\' EEEE',
    long  : 'y\'年\'M\'月\'d\'日\'',
    medium: 'yyyy/MM/dd',
    short : 'yyyy/M/d'
  },
  defaultWidth: 'short'
});

ko.formatLong.date = buildFormatLongFn({
  formats: {
    full  : 'y년 M월 d일 EEEE',
    long  : 'y년 M월 d일',
    medium: 'yyyy-MM-dd',
    short : 'yyyy-MM-dd'
  },
  defaultWidth: 'short'
});



Object.entries(dateLocales).forEach(([key, value]) => registerLocale(key, value));

export const init18n = (language = 'en') =>
  i18n
    .use(initReactI18next)
    .use(XHR)
    .init(
      {
        react: {
          nsMode     : 'fallback',
          bindI18n   : 'languageChanged loaded',
          wait       : true,
          useSuspense: false
        },
        lng          : new Intl.Locale(language).language,
        fallbackLng  : 'en',
        wait         : true,
        ns           : ['shared'],
        defaultNS    : 'shared',
        // debug: true,
        interpolation: {
          escapeValue: false
        },
        format : value => value,
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        parseMissingKeyHandler: s => (s.length ? <span className="mtb-ellipsis" /> : '')
      }
    );

export function changeLanguage(language) {
  return new Promise((res, _rej) => {
    i18n.changeLanguage(language, err => {
      if (err) {
        console.error(err);
      }
      res();
    });
  });
}

const subscribers = [];
let culture;
export const getActiveCulture = () => {
  if (!culture) {
    const defaultCulture = getCookieCulture() || localStorage.getItem('culture') || new Intl.NumberFormat().resolvedOptions().locale;
    setActiveCulture(defaultCulture && getMatchingCulture(defaultCulture) ? defaultCulture : 'en-US');
  }
  return culture;
};

const getMatchingCulture = code => {
  return getSupportedCultures().find(({ id }) => id.toLowerCase() === code?.toLowerCase());
};

const getCookieCulture = () => {
  try {
    const cookieCulture = document.cookie.split(';')
      .map(c => c.trim().split('='))
      .find(c => c[0]?.trim() === 'prefCulture')[1]?.trim();
    return new Intl.Locale(getMatchingCulture(cookieCulture).id)?.baseName;
  } catch {
    return ''; // allow fallback to localStorage
  }
};

export const setActiveCulture = newCulture => {
  culture = getMatchingCulture(newCulture);
  const { id } = culture;
  culture.dateLocale = dateLocales[id];
  subscribers.forEach(x => x(culture));
  localStorage.setItem('culture', id);
};

export const getSupportedCultures = () => [
  { id: 'en-US', name: 'United States' },
  { id: 'en-GB', name: 'United Kingdom' },
  { id: 'fr-FR', name: 'France' },
  { id: 'de-DE', name: 'Germany' },
  { id: 'pt-BR', name: 'Brazil' },
  { id: 'es-MX', name: 'Mexico' },
  { id: 'ja-JP', name: 'Japan' },
  { id: 'ko-KR', name: 'Korea' },
  { id: 'zh-CN', name: 'China' }
];

export const useCulture = () => {
  const [culture, setCulture] = useState(getActiveCulture());
  useEffect(() => {
    subscribers.push(setCulture);
    return () => subscribers.splice(subscribers.indexOf(setCulture), 1);
  }, []);
  return culture;
};

export default i18n;