
import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { formatValue } from '../Control.Utils';
import { useCulture } from '../../../services/i18n';
import { toColor } from '../../../styles/StyleUtils';
import { axisLabelStyle, getCalculatedLines, getLegend, getMax, getMin, getReferenceLines, getResponsiveRules } from './Graph.Util';

Highcharts.SVGRenderer.prototype.symbols.triangleup = Highcharts.SVGRenderer.prototype.symbols.triangle;
Highcharts.SVGRenderer.prototype.symbols.triangledown = Highcharts.SVGRenderer.prototype.symbols['triangle-down'];
Highcharts.SVGRenderer.prototype.symbols.triangleleft = (b, a, f, c) => ['M', b, a + c / 2, 'L', b + f, a, b + f, a + c, 'Z'];
Highcharts.SVGRenderer.prototype.symbols.triangleright = (b, a, f, c) => ['M', b, a, 'L', b + f, a + c / 2, b, a + c, 'Z'];
if (Highcharts.VMLRenderer) {
  Highcharts.VMLRenderer.prototype.symbols.triangleup = Highcharts.SVGRenderer.prototype.symbols.triangleup;
  Highcharts.VMLRenderer.prototype.symbols.triangledown = Highcharts.SVGRenderer.prototype.symbols.triangledown;
  Highcharts.VMLRenderer.prototype.symbols.triangleleft = Highcharts.SVGRenderer.prototype.symbols.triangleleft;
  Highcharts.VMLRenderer.prototype.symbols.triangleright = Highcharts.SVGRenderer.prototype.symbols.triangleright;
}

const colorCycle = [ '#0054A6', '#931313', '#00841F', '#9112B6', '#515151', '#CF8703' ];

const toMarkerSymbol = symbol => {
  symbol = symbol.toLowerCase();
  if (![ 'circle', 'square', 'diamond', 'triangleup', 'triangledown', 'triangleleft', 'triangleright'].includes(symbol)) {
    console.warn(`Unknown Symbol ${symbol}`);
  }
  return symbol;
};

const Scatterplot = ({ data, resolver }) => {
  const {
    calculatedLines,
    connectWithLine,
    series: rawSeries,
    showDataLabelsAsToolTip,
    showDataLabelsOnGraph,
    title, 
    xAxisDataType,
    xAxisMaximum,
    xAxisMinimum,
    xReferenceLineColor,
    xReferenceLinesType,
    xReferenceLines, 
    xAxisLabel,
    yAxisMaximum,
    yAxisMinimum,
    yReferenceLineColor,
    yReferenceLinesType, 
    yReferenceLines,
    yAxisLabel,
  } = data;
    
  const culture = useCulture();
  const series = rawSeries.flatMap(({ 
    dynamic,
    name,
    ySource,
    xSource,
    labelSource,
    shape,
    color
  }) => {
    const labels = resolver(labelSource, true);
    const names = dynamic ? resolver(name, true).values.flatMap(col => col.map(row => row)) : [name];
    const xValues = resolver(xSource).values[0]?.map(Number.parseFloat);
    return resolver(ySource).values.map((yValues, seriesIndex) => {
      const data = yValues.map((y, i) => ({ 
        x         : xValues && xValues[i], 
        y         : Number.parseFloat(y), 
        name      : labelSource && labels.values[0] ? labels.values[0][i] : null,
        dataLabels: { enabled: showDataLabelsOnGraph, format: '{point.name}', padding: 2, style: { fontWeight: 'Normal', fontSize: 10 } }
      }))
        .filter(({ x, y }) => Number.isFinite(x) && Number.isFinite(y))
        .sort((l, r) => (l.x - r.x) || (l.y - r.y));
      if (data.length === 0) {
        return null;
      }
      return {
        color              : color ? toColor(color) : null,
        data,
        name               : (names && names[seriesIndex]) || '',
        lineWidth          : connectWithLine ? 1 : 0,
        enableMouseTracking: true,
        showInLegend       : true,
        marker             : {
          enabled: true,
          radius : 5,
          symbol : shape ? toMarkerSymbol(shape) : null,
          states : {
            hover: {
              enabled  : true,
              lineColor: 'rgb(100,100,100)'
            }
          }
        },
        tooltip: {
          headerFormat  : '<b>{series.name}</b><br>',
          pointFormatter: function() {
            return `${showDataLabelsAsToolTip && this.name ? `Name: ${this.name}<br/>` : ''}Y: ${this.y}<br/> X: ${xAxisDataType === 'datetime' ? formatDate(this.x) : this.x}`;
          }
        }
      };
    });
  }).filter(Boolean);
  
  const lines = getCalculatedLines(calculatedLines);
   
  const axis = { 
    lineWidth    : 1,
    gridLineWidth: 0,
    maxPadding   : 0.075,
    minPadding   : 0.075,
    tickWidth    : 1,
    tickLength   : 5,
  };
  const xLines = getReferenceLines(
    xReferenceLinesType, xReferenceLines, toColor(xReferenceLineColor), true, resolver);
  
  const yLineColor = rawSeries.length === 1 && rawSeries[0].dynamic ? colorCycle : toColor(yReferenceLineColor);
  const yLines = getReferenceLines(yReferenceLinesType, yReferenceLines, yLineColor, false, resolver);
  const formatDate = value => formatValue({ culture, formatting: 'd' }, value);

  const softXMax = getMax(xAxisMaximum && Number.parseFloat(xAxisMaximum), 
    ...[
      ...xLines.map(({ value }) => value),
      ...lines.flatMap(({ data }) => data.map(values => Array.isArray(values) ? values[0] : values.x))
    ]);
  const softXMin = getMin(xAxisMinimum && Number.parseFloat(xAxisMinimum), 
    ...[
      ...xLines.map(({ value }) => value),
      ...lines.flatMap(({ data }) => data.map(values => Array.isArray(values) ? values[0] : values.x))
    ]);

  const softYMax = getMax (yAxisMaximum && Number.parseFloat(yAxisMaximum), 
    ...[
      ...yLines.map(({ value }) => value),
      ...lines.flatMap(({ data }) => data.map(values => Array.isArray(values) ? values[1] : values.y))
    ]);
  const softYMin = getMin(yAxisMinimum && Number.parseFloat(yAxisMinimum), 
    ...[
      ...yLines.map(({ value }) => value),
      ...lines.flatMap(({ data }) => data.map(values => Array.isArray(values) ? values[1] : values.y))
    ]);
  const finalSeries = series.concat(lines);
  return <HighchartsReact
    containerProps={{ style: { height: '100%' } }} 
    highcharts={Highcharts} 
    options={{
      title: { text: title },
      chart: {
        type     : 'scatter',
        animation: false,
      },
      colors     : colorCycle,
      symbols    : ['circle', 'square', 'diamond', 'triangleup', 'triangleright', 'triangleleft', 'triangledown'],
      plotOptions: {
        line   : { animation: false },
        scatter: {
          animation     : false,
          stickyTracking: false,
          states        : {
            hover: {
              marker: {
                enabled: false
              }
            }
          }          
        }
      },
      xAxis: { 
        ...axis,
        title         : { text: xAxisLabel, style: axisLabelStyle },
        tickPositioner: xAxisDataType === 'datetime' ? function() {
          return this.tickPositions.filter(x => Math.floor(x) === x);        
        } : null,
        labels: {
          formatter: xAxisDataType === 'datetime' ? ({ value }) => formatDate(value) : null
        },
        plotLines: xLines,
        softMax  : softXMax,
        softMin  : softXMin,
        max      : finalSeries?.length ? null : softXMax,
        min      : finalSeries?.length ? null : softXMin,
      },
      series: finalSeries?.length ? finalSeries : [{}],
      yAxis : {
        ...axis,
        title    : { text: yAxisLabel, style: axisLabelStyle },
        plotLines: yLines,
        softMax  : softYMax,
        softMin  : softYMin,
        max      : finalSeries?.length ? null : softYMax,
        min      : finalSeries?.length ? null : softYMin,
      },
      legend: {
        ...getLegend(data),
        enabled: series.length > 1
      },
      responsive: getResponsiveRules(data),
      credits   : {
        enabled: false,
      } }} />;
};

export default Scatterplot;