import React, { useState, useEffect } from 'react';

import { createClass } from '../../styles/StyleUtils';

const LayoutTable = ({ 
  context, 
  data : { columns = 0, columnWidths, rows : rowCount = 0, style },
  processChildren, 
  rows, 
  getProps, 
  ...props }) => {
  const [cols, setCols] = useState();
  const [trs, setTrs] = useState();

  useEffect(() => {
    setCols(Array.from (Array(columns).keys())
      .map((_, index) => columnWidths[index])
      .map((width, index) => <col key={index} style={{ width: `${width}px` }}/>));
  }, [columnWidths, columns]);

  useEffect(() => {
    setTrs(Array.from(Array(rowCount).keys())
      .map((_, index) => 
        <tr key={index}>
          {rows[index].map(({ attributes, children }, index) => {
            let props = getProps(attributes);
            props = { 
              ...props, 
              key  : index,
              style: {
                ...props.style,
                maxWidth: columnWidths.slice(+attributes.Column, +attributes.Column + +(attributes.ColumnSpan || 1))
                  .reduce((a, b) => a + b) } 
            };
            return <td {...props}>{processChildren(children, context)}</td>;
          })}
        </tr>));
  }, [columnWidths, context, getProps, processChildren, rowCount, rows]);

  return <table {...props} className={style && createClass(style)}>
    <colgroup>
      {cols}
    </colgroup>
    <tbody>
      {trs}        
    </tbody>
  </table>;

};
  

export default LayoutTable;
