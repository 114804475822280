import React from 'react';
import PropTypes from 'prop-types';

import '../constants.scss';
import './spinner.scss';

const Spinner = ({ size, buffer }) => {
  const svg = (
    <svg className="mtb-spinner" height={`${size}px`} viewBox="0 0 66 66" width={`${size}px`} x="0px" y="0px">
      <g transform="translate(1, 1)">
        <path d="M1,32C1,14.9,14.9,1,32,1s31,13.9,31,31S49.1,63,32,63" vectorEffect="non-scaling-stroke" />
      </g>
    </svg>
  );
  return buffer ? <div style={{ width: buffer.width, height: buffer.height }}>{svg}</div> : svg;
};

Spinner.propTypes = {
  size  : PropTypes.number,
  buffer: PropTypes.object
};

Spinner.defaultProps = {
  size: 50
};

export default Spinner;
