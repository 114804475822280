import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createClass } from '../../styles/StyleUtils';

const getIcon = type => {
  switch (type) {
    case 'OrangeArrow':
      return 'play_options_16x16.png';
    case 'Help':
      return 'help_options_16x16.png';
    case 'Edit':
      return 'document_edit_16x16.png';
    case 'Table':
      return 'table_edit_16x16.png';
    case 'Check':
      return 'check_options_16x16.png';
    case 'Calculator':
      return 'calculation_options_16x16.png';
    case 'Graph':
      return 'chart_bar_chart_16x16.png';
    case 'Warning':
      return 'warning_16x16.png';
    default:
      return '';
  }
};


const OS = ({ data, children }) => {
  const { iconType, caption, hidden, isExpanded, styleId, width } = data;
  const [t] = useTranslation('shared');
  const [expanded, setExpanded] = useState(isExpanded);
  const [icon] = useState(() => getIcon(iconType));
  if (hidden) {
    return null;
  }

  return <div className={`companion-os ${expanded ? 'expanded' : 'collapsed'}`} style={ { width: width === 'NaN' ? '100%' : `${width}px` }}>
    <button className={createClass(styleId)} onClick={() => setExpanded(!expanded) }>
      <span>{icon ? <img alt='' src={`/assets/images/${icon}`}/> : null}
        <span>
          {caption}
        </span>
      </span>
      <span>
        {expanded ? t('close') : t('open')}
      </span>
    </button>  
    {expanded && (
      <section className={createClass(styleId)}>
        {children}
      </section>
    )}
  </div>;
};

export default OS;

