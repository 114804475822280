
import { toColor } from '../../../styles/StyleUtils';

export const getMax = (max, ...values) => 
  max && Number.isFinite(max) ? Math.max(max, ...values) : values.length ? Math.max(...values) : undefined;

export const getMin = (min, ...values) =>
  min && Number.isFinite(min) ? Math.min(min, ...values) : values.length ? Math.min(...values) : undefined;

export const axisLabelStyle = { fontSize: 16 };

export const getLegend = ({ legendPosition }) => ({
  layout       : legendPosition === 'right' ? 'vertical' : 'horizontal',
  verticalAlign: legendPosition === 'right' ? 'top' : 'bottom',
  align        : legendPosition === 'right' ? 'right' : 'center',
  floating     : legendPosition === 'right',
  borderColor  : '#999',
  borderWidth  : 1.0
});

const intersects = (a, b) => a.bottom >= b.top && a.top <= b.bottom && a.right >= b.left && a.left <= b.right;

export const getResponsiveRules = ({ legendPosition }) => ({
  rules: [legendPosition === 'right' && {
    condition: {
      callback: function() {
        if (!this.legend.options.enabled) {
          return false;
        }
        if (this.legend.options.verticalAlign !== 'top') {
          return true;
        }
        if (this.title.textStr && this.legend.box?.element && this.title?.element &&
          ((this.legend.box.element.getBoundingClientRect().left - 10)
            < this.title.element.getBoundingClientRect().right)) {
          // title and legend are colliding 
          return true;
        }
        // check to see if legend is potentially colliding with any series items
        // the only reason this happens is because our top right legends are set to float
        // this improves the display of the graph otherwise the plot area is shrunk thin 
        const legendBounds = this.legend.box?.element.getBoundingClientRect();
        if (legendBounds && this.series.flatMap(x => [x.group?.element, x.markerGroup?.element])
          .filter(Boolean)
          .some(x => {
            // check to see if series overlaps 
            if (intersects(x.getBoundingClientRect(), legendBounds)) {
              // if so check the actual contents of the series
              for (let i = 0; i < x.children.length; ++i) {
                if (intersects(x.children[i].getBoundingClientRect(), legendBounds)) {
                  return true;
                }
              }
            }
            return false;
          })) {
          return true;
        }
        // check reference lines
        return this.axes
          .flatMap(x => x.plotLinesAndBands.map(x => x?.svgElem?.element))
          .filter(Boolean)
          .some(x => intersects(x.getBoundingClientRect(), legendBounds));
      }
    },
    chartOptions: {
      legend: getLegend('bottom')
    }
  }].filter(Boolean)
});

export const getReferenceLines = (type, values, color, vertical, resolver) => {
  if (!values) {
    return [];
  }
  return values?.split(';').flatMap(pair => {
    const [value, label] = pair.split(':');
    const labelSource = type === 'control' ? resolver(label, true).values : [[label]];
    const values = (type === 'control' ? resolver(value).values : [[value]]);
    return values?.flatMap((column, columnIndex) => {
      const lineColor = Array.isArray(color) ? color[columnIndex % color.length] : color;
      return column.map(Number.parseFloat).filter(Number.isFinite).map((value, i) => ({
        value    : Number.parseFloat(value),
        color    : lineColor,
        dashStyle: 'ShortDash',
        width    : 1,
        label    : {
          style        : { color: lineColor, fontWeight: 'Normal', fontSize: 11 },
          text         : (labelSource[columnIndex] || labelSource[0] || [])[i], 
          align        : 'right', 
          verticalAlign: 'top',
          x            : vertical ? -5 : undefined,
          rotation     : vertical ? -90 : 0,
        },
        zIndex: 100
      }));
    });
  }).filter(Boolean).filter(({ value }) => Number.isFinite(value));
};

export const getCalculatedLines = lines => {
  if (!lines) {
    return [];
  }
  return lines.split(';').flatMap(pair => {
    const [name, values, color, showLabel, manualLabel, x, y] = pair.split(':');
    const points = values.split('|');
    const data = [];
    while (points.length) {
      const [y, x] = points.splice(0, 2);
      data.push([Number.parseFloat(x), Number.parseFloat(y)]);
    }

    let labelX;
    let labelY;
    if (showLabel === 'true') {
      if (manualLabel === 'true') {
        labelX = Number.parseFloat(x);
        labelY = Number.parseFloat(y);
      } else if (data.length) {
        ([labelX, labelY] = data[Math.ceil(data.length / 2) - 1]);
      }
    }

    return [data.length && {
      type               : 'line',
      color              : toColor(color),
      enableMouseTracking: false,
      data,
      marker             : { enabled: false, },
      lineWidth          : 1,
      showInLegend       : false,
    }, 
    Number.isFinite(labelX) && Number.isFinite(labelY) && {
      data: [{
        name,
        dataLabels: { enabled: true, format: '{point.name}', padding: 2, style: { fontWeight: 'Normal', fontSize: 11, color: toColor(color) } },
        x         : labelX,
        y         : labelY
      }],
      enableMouseTracking: false,
      marker             : { enabled: false },
      lineWidth          : 0,
      showInLegend       : false,
    }].filter(Boolean);
  });
};
