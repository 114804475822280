import React, { useCallback } from 'react';

import { BarChart, ParetoChart, Scatterplot, GanttChart } from './Graphs';
import { formatValue, toEditValue } from './Control.Utils';
import { useCulture } from '../../services/i18n';
  
const Placeholder = ({ data :{ height, width } }) => {
  return <div className='chart shimmer' style={({ height, width })}/>;
};

const G = ({ data: { type }, data, controls, ...props }) => {

  const culture = useCulture();
  const resolver = useCallback((source, forDisplay) => {
    source = { ...(source && source.rawValues ? 
      { ...source, rawValue: [source.rawValues] } : 
      controls[source] || { rawValue: [[]], name: undefined }), culture };
    const { 
      name, 
      listValues: list,
      unitType,
      formatting
    } = source;
    let { rawValue: values = [] } = source;
    if (list) {
      const lookup = list.split(',').reduce((prev, curr) => {
        const [display, value, id] = curr.split('|'); 
        return { ...prev, [id]: forDisplay ? display : value }; 
      }, {});
      values = values.map(x => x.map(id => lookup[id]));
    } else if (forDisplay) {
      values = values.map(x => x.map(value => formatValue(source, value)));
    } else if (unitType && unitType !== 'none') {
      values = values.map(x => x.map(value => toEditValue(source, value)));
    }
    return { values, name, isDate: ['date/time', 'd', 'D'].includes(formatting) };
  }, [controls, culture]);
  
  switch (type) {
    case 'paretochart':
      return <ParetoChart {...props} data={data} resolver={resolver}/>;
    case 'scatterplot':
      return <Scatterplot {...props} data={data} resolver={resolver}/>;
    case 'barchart':
      return <BarChart {...props} data={data} resolver={resolver}/>;
    case 'ganttchart':
      return <GanttChart {...props} data={data} resolver={resolver}/>;
    default:
      return <Placeholder data={data}/>;
  }
};

export default G;

