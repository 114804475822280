import { ZERO_WIDTH_SPACE } from './StyleUtils';

const parseStyle = require('inline-style-parser');

const toHex = str => Number.parseInt(str, 10).toString(16);

function escapeXml(unsafe) {
  return unsafe.replace(/[<>&'"]/g, c => {
    switch (c) {
      case '<': return '&lt;';
      case '>': return '&gt;';
      case '&': return '&amp;';
      case '\'': return '&apos;';
      case '"': return '&quot;';
      default:
        return c;
    }
  });
}

const toColor = color => {
  if (color.startsWith('rgb(')) {
    return `#${toHex(color.substring(4, 6))}${toHex(color.substring(6, 8))}${toHex(color.substring(8, 10))}`;
  } else if (color.startsWith('rgba(')) {
    return `#${(Number.parseInt(color.substring(10, 12), 10) * 255).toString(16)}${toHex(color.substring(4, 6))}${toHex(color.substring(6, 8))}${toHex(color.substring(8, 10))}`;
  } 
  return color;
};

const toSize = value => {
  if (!value) {
    return '0';
  } else if (value.endsWith('px')) {
    return value.slice(0, -2);
  } else if (value.endsWith('pt')) {
    return Number.parseFloat(value.slice(0, -2)) * (4 / 3);
  } 
  return value;
};

const toThickness = ({ top, left, bottom, right }) => [toSize(left), toSize(top), toSize(right), toSize(bottom)].join(',');
const unknown = (name, value) => console.warn(`Unknown attribute ${name} : ${value}`);
const makeAttribute = (property, value) => `${property}="${value}"`;
const makeElement = (name, attributes, children) => children ? (`<${name}${attributes ? ` ${attributes}` : ''}>${children}</${name}>`) : `<${name}${attributes ? ` ${attributes}` : ''}/>`;

const getFlowDocumentValues = attributes => {
  let padding;
  let margin;
  let borderWidth;
  let textDecorations = '';
  const flowAttributes = [
    ...[
      ...Object.entries(attributes || {}).map(([property, value]) => ({ property, value })), 
      ...parseStyle((attributes || {}).style || '')
      // eslint-disable-next-line complexity
    ].map(({ property, value }) => {
      const lower = value?.toLowerCase();
      switch (property) {
        case 'background':
        case 'background-color':
          return makeAttribute('Background', toColor(lower));
        case 'border-color':
          return makeAttribute('BorderColor', toColor(lower));
        case 'colspan':
          return makeAttribute('ColumnSpan', lower);
        case 'border-spacing':
          return makeAttribute('CellSpacing', lower);
        case 'font-family':
          return makeAttribute('FontFamily', lower);
        case 'font-size':
          return makeAttribute('FontSize', toSize(lower));
        case 'font-stretch':
          return makeAttribute('FontStretch', lower);
        case 'font-style':
          return makeAttribute('FontStyle', lower);
        case 'font-weight':
          return makeAttribute('FontWeight', lower);
        case 'color':
          return makeAttribute('Foreground', toColor(lower));
        case 'height':
          return makeAttribute('Height', toSize(lower));
        case 'line-height':
          return makeAttribute('LineHeight', lower ? toSize(lower) : 'auto');
        case 'list-style-type':
          return makeAttribute('MarkerStyle', lower);
        case 'href':
          return makeAttribute('NavigateUri', value);
        case 'title':
          return makeAttribute('ToolTip', value);
        case 'padding-top':
        case 'padding-left':
        case 'padding-bottom':
        case 'padding-right':
          if (!padding) {
            padding = {};
          }
          padding[property.replace('padding-', '')] = toSize(lower);
          return '';
        case 'margin-top':
        case 'margin-left':
        case 'margin-bottom':
        case 'margin-right':
          if (!margin) {
            margin = {};
          }
          margin[property.replace('margin-', '')] = toSize(lower);
          return '';
        case 'border-top-width':
        case 'border-left-width':
        case 'border-bottom-width':
        case 'border-right-width':
          if (!borderWidth) {
            borderWidth = {};
          }
          borderWidth[property.replace('border-', '').replace('-width', '')] = toSize(lower);
          return '';
        case 'rowspan':
          return makeAttribute('RowSpan', lower);
        case 'text-align':
          return makeAttribute('TextAlignment', lower);
        case 'width':
          return makeAttribute('Width', toSize(lower));
        case 'text-decoration':
          const decorations = lower.toLowerCase().split(' ').map(x => x === 'line-through' ? 'strikethrough' : x);
          if (decorations.length > 1) {
            textDecorations = makeElement('TextDecorationCollection', '', decorations.map(x => makeElement('TextDecoration', makeAttribute('Location', x))).join(''));
            return '';
          } 
          return makeAttribute('TextDecorations', decorations[0]);
        
        case 'style':
        case 'class':
        case 'src' :
        case 'alt' :
          return '';
        default:
          unknown(property, value);
          return '';        
      }
    }),
    margin && makeAttribute('Margin', toThickness(margin)),
    padding && makeAttribute('Padding', toThickness(padding)),
    borderWidth && makeAttribute('BorderThickness', toThickness(borderWidth))
  ].filter(Boolean).join(' ');
  return { 
    flowAttributes,
    textDecorations 
  };
};

const inlineUnstyledTextElements = ['abbr', 'bdi', 'bdo', 'cite', 'code', 'data', 'dfn', 'kdb', 'mark', 'rb', 'rp', 'rt', 'rtc', 'ruby', 'pre', 'samp', 'small', 'sub', 'sup', 'time', 'var', 'wbr'];

export const toFlowDocument = (
  { children, name, attributes, type, text }, 
  { inInline, ...context }
) => {
  if (type === 'text') {
    text = escapeXml(text.replace(ZERO_WIDTH_SPACE, ''));
    return inInline ? text : makeElement('Run', '', text);
  } 
  const tunnel = inInline => children.map(x => toFlowDocument(x, { ...context, inInline })).join('');

  if (type !== 'element') {
    return makeElement('Paragraph', '', 
      `${makeElement('Run', getFlowDocumentValues({ 'font-size': '36px', color: 'red' }).flowAttributes, `Unknown type ${name}`)}${tunnel()}`);
  }  
  const { flowAttributes, textDecorations } = getFlowDocumentValues(attributes);
  switch (name) {
    case 'table' :
      return makeElement('Table', flowAttributes, tunnel());
    case 'colgroup' :
      return makeElement('Table.Columns', flowAttributes, tunnel());
    case 'col' :
      return makeElement('TableColumn', flowAttributes, tunnel());
    case 'tbody' :
      return makeElement('TableRowGroup', flowAttributes, tunnel());
    case 'tr' :
      return makeElement('TableRow', flowAttributes, tunnel());
    case 'td' :
      return makeElement('TableCell', flowAttributes, tunnel());
    case 'i':
    case 'em':
      return makeElement('Italic', flowAttributes, tunnel(true));
    case 'b':
    case 'strong':
      return makeElement('Bold', flowAttributes, tunnel(true));
    case 's':
      return makeElement('Run', `${makeAttribute('TextDecorations', 'strikethrough')}${flowAttributes}`, tunnel(true));
    case 'u':
      return makeElement('Underline', flowAttributes, tunnel(true));
    case 'q':
      return makeElement('Run', flowAttributes, `"${tunnel(true)}"`);
    case 'p':
      return makeElement('Paragraph', flowAttributes, `${textDecorations ? makeElement('Paragraph.TextDecorations', '', textDecorations) : ''}${tunnel()}`);
    case 'span':
      const element = (children && children.every(({ type }) => type === 'text')) ? 'Run' : 'Span'; 
      return makeElement(element, flowAttributes, `${textDecorations ? makeElement(`${element}.TextDecorations`, '', textDecorations) : ''}${tunnel(true)}`);
    case 'br' :
      return makeElement('LineBreak');
    case 'ul' :
      return makeElement('List', flowAttributes, tunnel());
    case 'ol' :
      return makeElement('List', `${makeAttribute('MarkerStyle', 'decimal')}${flowAttributes}`, tunnel());
    case 'li' :
      return makeElement('ListItem', flowAttributes, tunnel());
    case 'section' : 
      return makeElement('Section', ['xml:space="preserve" xmlns="http://schemas.microsoft.com/winfx/2006/xaml/presentation"', flowAttributes].filter(Boolean).join(' '), tunnel());
    case 'img':
      return makeElement('InlineUIContainer', '', 
        makeElement('Image', [
          flowAttributes, 
          makeAttribute('Stretch', flowAttributes.includes('Width=') || flowAttributes.includes('Height=') ? 'fill' : 'none')
        ].join(' '), 
        makeElement('Image.Source', '', 
          makeElement('BitmapImage', [
            !attributes.src.startsWith('data:') && makeAttribute('UriSource', (attributes.src[0] !== '/' ? `/${attributes.src}` : attributes.src).replace(`${context.rootUri}`, '')), 
            makeAttribute('CacheOption', 'OnLoad')
          ].filter(Boolean).join(' '),
          attributes.src.startsWith('data:') && attributes.src.split(',').pop()
          ))));
    case 'a' :
      return makeElement('Hyperlink', flowAttributes, tunnel());
    default:
      if (inlineUnstyledTextElements.includes(name)) {
        return tunnel(true);
      }
      return makeElement('Paragraph', '', 
        `${makeElement('Run', getFlowDocumentValues({ 'font-size': '36px', color: 'red' }).flowAttributes, `Unknown type ${name}`)}${tunnel()}`);
  }
};