import React from 'react';
import { useTranslation } from 'react-i18next';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { axisLabelStyle, getLegend, getResponsiveRules } from './Graph.Util';
import { toColor } from '../../../styles/StyleUtils';
import { useCulture } from '../../../services/i18n';

const ParetoChart = ({ data, resolver }) => {
  const [t] = useTranslation('shared');
  const { 
    barGroupColors,
    categoryPercentage,
    colorGroups,
    comparisonBarColor,
    comparisonDataValues,
    dataValues, 
    displayCumulativePercent, 
    displayOthers,
    height,
    mainBarColor,
    title, 
    xAxisLabel, 
    xLabels,
    xLabelAngle,
    yAxisLabel,
    summaryData 
  } = data;

  const culture = useCulture();
  const toValue = raw => {
    const value = Number.parseFloat(raw);
    return Number.isFinite(value) ? Math.max(0, value) : 0;
  };
  const hasComparison = !colorGroups && comparisonDataValues;
  const valueSource = resolver(dataValues);
  const compareSource = resolver(comparisonDataValues, colorGroups);
  let groups;
  if (summaryData) {
    const values = valueSource.values[0]?.map(toValue);
    const comparisonValues = hasComparison ? compareSource.values[0]?.map(toValue) : compareSource.values[0];
    groups = (xLabels ? resolver(xLabels, true) : resolver(dataValues, true)).values[0]?.map((category, i) => ({
      category: category || '*', 
      value   : values ? values[i] : 0, 
      compare : comparisonDataValues ? comparisonValues[i] : 0
    }));
  } else {
    const summarized = resolver(dataValues, valueSource.isDate).values[0]?.reduce((prev, curr) => {
      if (!curr) {
        return prev;
      }
      return { ...prev, [curr]: (prev[curr] || 0) + 1 };
    }, {});
    const summarizedCompare = hasComparison && 
    resolver(comparisonDataValues, compareSource.isDate).values[0]?.reduce((prev, curr) => {
      if (!curr) {
        return prev;
      }
      return { ...prev, [curr]: (prev[curr] || 0) + 1 };
    }, {});
    groups = Object.entries(summarized || [])
      .map(([category, value]) => {
        const group = { category, value, compare: summarizedCompare ? (summarizedCompare[category] || 0) : 0 };
        if (summarizedCompare) {
          delete summarizedCompare[category];
        }
        return group;
      });

    if (summarizedCompare) {
      Object.entries(summarizedCompare)
        .forEach(([category, value]) => groups.push({ category, value: 0, compare: value }));
    }
      
  }
  groups = groups?.filter(({ value, compare }) => value > 0 || (hasComparison && compare > 0))
    .sort((a, b) => b.value - a.value);

  const total = groups?.reduce((total, { value }) => total + value, 0);
  const totalCompare = groups?.reduce((total, { compare }) => total + compare, 0);
  
  const colors = colorGroups ? barGroupColors.split('|').map(toColor) : null;
  let nextColor = 0;
  const colorMap = {};

  const result = groups?.reduce((prev, group) => {
    if ((prev.cumulative / total) >= categoryPercentage) {
      return prev;
    }
    prev.cumulative += group.value;
    prev.compareCumulative += group.compare;
    const color = colorGroups ? 
      (colorMap[group.compare] || (colorMap[group.compare] = colors[nextColor++])) 
      : null; 
    prev.groups.push({ 
      ...group,
      percent          : group.value / total, 
      cumulativePercent: prev.cumulative / total,
      compareCumulative: prev.compareCumulative / totalCompare,
      comparePercent   : group.compare / totalCompare, 
      color 
    });
    return prev;
  }, { cumulative: 0, compareCumulative: 0, groups: [] });
  if (result && displayOthers && result.groups.length < groups.length) {
    const remaining = comparisonDataValues && groups.slice(result.groups.length);
    const compare = remaining && remaining.reduce((total, { compare }) => total + compare, 0);
    groups = [
      ...result.groups,
      { 
        category         : t('graphs.others'), 
        cumulativePercent: 1,
        percent          : (total - result.cumulative) / total,
        value            : total - result.cumulative,

        compareCumulative: 1,
        comparePercent   : compare / totalCompare,
        compare,
        
        color: colorGroups ? colors[nextColor++] : null
      } 
    ];
  } else {
    groups = result?.groups;
  }
  const categories = groups?.map(x => x.category).filter(Boolean);
  const displayPercent = !hasComparison && displayCumulativePercent;
  const toPercent = Intl.NumberFormat(culture.id, {
    style                : 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format;
  const tooltip = {
    headerFormat  : '<b>{point.x}</b><br>',
    pointFormatter: function () {
      return `${t('graphs.valueTooltip')} ${this.y}<br/>${t('graphs.percentTooltip')} ${toPercent(this.custom.percent)}<br/>${t('graphs.cumulativePercentageTooltip')} ${toPercent(this.custom.cumulativePercent)}`;
    },
  };
  return <HighchartsReact
    containerProps={{ style: { height: '100%' } }} 
    highcharts={Highcharts} 
    options={{
      height,
      title: { text: title },
      chart: {
        type     : 'column',
        animation: false,
      },
      plotOptions: {
        column: {
          animation   : false,
          borderColor : '#333',
          groupPadding: hasComparison ? 0.05 : 0,
          pointPadding: 0,
          borderWidth : 1.0,
          dataLabels  : { enabled: true, padding: 0, style: { fontWeight: 'Normal', fontSize: 10 } }
        },
        series: {
          label: { enabled: false }
        }
      },
      xAxis: {
        categories,
        labels: {
          rotation: -xLabelAngle || null,
        },
        tickWidth        : 1,
        tickLength       : 5,
        tickmarkPlacement: 'on',
        title            : { text: xAxisLabel, style: axisLabelStyle },
      },
      series: [
        { 
          color: toColor(mainBarColor),
          data : groups?.map(({ color, cumulativePercent, percent, value }) =>
            ({
              y     : value, 
              color, 
              custom: { cumulativePercent, percent }
            })) || [], 
          name: valueSource.name,
          tooltip
        }, 
        hasComparison ?
          { 
            color: toColor(comparisonBarColor),
            data : groups?.map(({ compareCumulative, comparePercent, compare }) => 
              ({ y: compare, custom: { cumulativePercent: compareCumulative, percent: comparePercent } })) || [],
            name: compareSource.name,
            tooltip,
          }
          : null,
        displayPercent ? {
          animation   : false,
          baseSeries  : 0,
          color       : '#0054A6',
          name        : t('graphs.cumulativePercentage'),
          showInLegend: false,
          tooltip     : {
            headerFormat  : '<b>{point.x}</b><br>',
            pointFormatter: function () {
              return `${t('graphs.cumulativePercentageTooltip')} ${toPercent(this.y)}`;
            },
          },
          dataLabels: { 
            enabled  : true, 
            formatter: function() {
              return toPercent(this.y); 
            },
            style: { fontWeight: 'Normal', fontSize: 10 }
          },
          stickyTracking: false,
          data          : groups?.map(x => x.cumulativePercent) || [],
          type          : 'line',
          yAxis         : 1,
          zIndex        : 10
        } : null
      ].filter(Boolean),
      yAxis: [{
        offset       : hasComparison ? 0 : 5,
        title        : { text: yAxisLabel, style: axisLabelStyle },
        lineWidth    : 1,
        gridLineWidth: 0,
        max          : displayPercent ? total : null,
        endOnTick    : false,
        tickWidth    : 1,
        tickLength   : 5,
      },
      displayPercent ? {
        offset           : 5,
        title            : { text: t('graphs.percent'), style: axisLabelStyle },
        tickWidth        : 1,
        tickLength       : 5,
        opposite         : true,
        lineWidth        : 1,
        gridLineWidth    : 1,
        gridLineDashStyle: 'LongDash',
        min              : 0,
        max              : 1,
        maxPadding       : 0,
        miPadding        : 0,
        tickPositions    : [0, 0.2, 0.4, 0.6, 0.8, 1],
        labels           : { formatter: ({ value }) => toPercent(value) }
      } : null
      ].filter(Boolean),
      legend: {
        ...getLegend(data),
        enabled: hasComparison,
      },
      responsive: getResponsiveRules(data),
      credits   : {
        enabled: false,
      } }} />;
};
  

export default ParetoChart;