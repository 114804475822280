export const createClass = id => `style${id}`;
export const ZERO_WIDTH_SPACE = String.fromCharCode(8203); // used for maintaining line height of p/span/div even when they have no content to mimic the wpf richtext control behavior 

const fallbackFonts = '\'Segoe UI\', \'Open Sans\', sans-serif';
export const toCSSFont = ({ italic, bold, fontSize, fontName }) => `${italic ? 'italic ' : ''}${bold ? 'bold ' : ''}${fontSize}px '${fontName}', ${fallbackFonts}`;
export const toColor = color => {
  if (!color) {
    return 'transparent';
  }
  if (color.length < 9 || color[0] !== '#') {
    return color;
  }
  if (color.substring(1, 3).toUpperCase() === 'FF') {
    return `#${color.substring(3, 9)}`;
  }
  return `rgba(${[Number.parseInt(color.substring(3, 5), 16),
    Number.parseInt(color.substring(5, 7), 16),
    Number.parseInt(color.substring(7, 9), 16),
    Number.parseInt(color.substring(1, 3), 16) / 255].join(',')})`;
};

const toBorderStyle = (style, color) => {
  if (color.length >= 9 && color.substring(1, 3).toUpperCase() === '00') {
    // transparent 
    return 'none';
  }
  switch (style) {
    case 'Solid':
      return 'solid';
    case 'Dash':
      return 'dashed';
    case 'Dot':
      return 'dotted';
    case 'DashDot':
      return 'dotted ';
    case 'DashDotDot':
      return 'dotted ';
    case 'None':
      return 'hidden';
    case 'InsideFrame':
      return 'solid';
    case 'Double':
      return 'double';
    default:
      return 'solid';
  }
};

export const toDashArray = (style, size) => {
  switch (style) {
    case 'Dash':
      return [2 * size, size];
    case 'Dot':
      return [size, size];
    case 'DashDot':
      return [2 * size, size, size, size];
    case 'DashDotDot':
      return [2 * size, size, size, size, size, size];
    default:
      
  }
};

const toBorder = ({
  borderBottom,
  borderLeft,
  borderRight,
  borderStyle,
  borderTop,
  borderColor,
}) => {
  const same = borderBottom === borderLeft && borderRight === borderTop && borderLeft === borderRight;
  if (same) {
    return borderBottom ? `border-style: ${toBorderStyle(borderStyle, borderColor)};` : 'border-style: none;';
  }
  const style = toBorderStyle(borderStyle, borderColor);
  const borders = [];
  if (borderBottom) {
    borders.push(`border-bottom-style: ${style};`);
  }
  if (borderLeft) {
    borders.push(`border-left-style: ${style};`);
  }
  if (borderRight) {
    borders.push(`border-right-style: ${style};`);
  }
  if (borderTop) {
    borders.push(`border-top-style: ${style};`);
  }
  return borders.join('\n  ');
};


export const ToFlexJustify = align => {
  switch (align) {
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

const toListStyle = style => {
  switch (style) {
    case 'number' :
      return 'decimal';
    case 'bullet' :
      return 'disc';
    case 'none' :
    default:
      return 'none';
  }
};

const toLineHeight = style => {
  switch (style) {
    case 'oneandonehalf' :
      return 1.5;
    case 'double' :
      return 2;
    case 'single' :
    default:
      return 1;
  }
};

const toBackgroundStyle = style => {
  switch (style) {
    case 'Solid':
      return 'solid';
    case 'Gradient':
    case 'HorizontalLines':
    case 'VerticalLines':
    case 'DiagonalLinesLeftToRight':
    case 'DiagonalLinesRightToLeft':
    case 'PerpendicularDiagonalLines':
    case 'PerpendicularStandardLines':
    default:
      return 'solid'; // work to do here
  }
};

const toDecoration = (underline, strikethrough) => {
  if (!underline && !strikethrough) {
    return null;
  }
  return `text-decoration: ${underline ? 'underline' : ''} ${strikethrough ? 'line-through' : ''};`;
};

const tryStyle = (style, fallback = '') => style || fallback;

export const createConditionalFormat = (baseStyle, format) => {
  if (!format) {
    return;
  }
  const { 
    backgroundColor, 
    bold, 
    fontColor, 
    italic, 
    strikethrough = baseStyle.strikethrough,
    underline = baseStyle.underline 
  } = format;
  return {
    color          : fontColor === undefined ? undefined : toColor(fontColor),
    backgroundColor: backgroundColor === undefined ? undefined : toColor(backgroundColor),
    fontWeight     : bold === undefined ? undefined : bold ? 'bold' : 'normal',
    fontStyle      : italic === undefined ? undefined : italic ? 'italic' : 'normal',
    textDecoration : !underline && !strikethrough ? 'none' : `${underline ? 'underline' : ''} ${strikethrough ? 'line-through' : ''}`
  };
};

export const createCss = (styles, size) => {
  const existing = document.getElementById('form-styles');
  if (existing) {
    existing.remove();
  }
  const style = document.createElement('style');
  style.type = 'text/css';
  style.id = 'form-styles';

  const styleValues = styles.map(style => 
    `.companion-form .${createClass(style.id)} {
  ${[tryStyle(style.fontName && `font-family: '${style.fontName}', ${fallbackFonts};`),
    tryStyle(style.fontSize && `font-size: ${style.fontSize}px;`),
    tryStyle(style.color && `color: ${toColor(style.color)};`),
    tryStyle(style.bold && 'font-weight: bold;'),
    tryStyle(style.italic && 'font-style: italic;'),
    toDecoration(style.underline, style.strikethrough),
    tryStyle(style.textAlign && `text-align: ${style.textAlign};`, 'text-align: left;'),
    tryStyle(style.textAlign && `text-align-last: ${style.textAlign};`, 'text-align-last: left;'),
    tryStyle(style.textAlign && `justify-content: ${ToFlexJustify(style.textAlign)};`, 'justify-content: left;'),
    tryStyle(style.lineSpacing && `line-height: ${toLineHeight(style.lineSpacing)};`),
    tryStyle(style.indentFirst && `text-indent: ${style.indentFirst}px;`),
    tryStyle(style.borderWidth && `border-width: ${style.borderWidth}px;`),
    tryStyle(toBorder(style)),
    tryStyle(style.borderColor && `border-color: ${toColor(style.borderColor)};`),
    tryStyle(style.indentRight && `padding-right: ${style.indentRight}px;`),
    tryStyle(style.indentLeft && `padding-left: ${style.indentLeft}px;`),
    tryStyle(style.spacingBefore && `padding-top: ${style.spacingBefore}px;`),
    tryStyle(style.spacingAfter && `padding-bottom: ${style.spacingAfter}px;`),
    tryStyle(style.numbering && `list-style-type: ${toListStyle(style.numbering)};`),
    tryStyle(style.backgroundColor && `background-color: ${toColor(style.backgroundColor)};`),
    tryStyle(style.patternStyle && `background-style: ${toBackgroundStyle(style.patternStyle)};`)].filter(Boolean).join('\n  ')}
}`);
  styleValues.push(`.companion-form {
    min-width : ${size.width}px;      
  }`);

  style.innerHTML = styleValues.join('\n');
  document.getElementsByTagName('head')[0].appendChild(style);
};