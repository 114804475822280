
import { 
  Binding, Diagram, GraphObject, Link, Margin, Node, Panel, 
  Shape, Spot, TextBlock, TreeLayout, TreeModel, ForceDirectedLayout
} from 'gojs';
import { CTreeLayout } from './CTreeLayout';
import { DoubleTreeLayout } from './DoubleTreeLayout';
import { FishboneLayout, FishboneLink } from './FishboneLayout';
import { toColor } from '../../styles/StyleUtils';

Diagram.licenseKey = '73f14fe4b70537c702d90776423d6af919a17564ce8149a4080412f6ec0d6b06329fe92802d3df90d5af4efe1c7f93d0d5c039209348023ce131d7db10e484aaba3375e5431a5788f15320c3cbaa2bb3ec7b70f1c3aa73bdda7a';
const $ = GraphObject.make;

const PropertyColors = {
  '1': '#D06060',
  '2': '#DE8B0A',
  '3': '#A69933',
  '4': '#425FDC',
  '5': '#448C44'
};

const linkStroke = '#777777';
const createLinkShape = () => $(Shape, { strokeWidth: 2, stroke: linkStroke });
const tableLink = $(Link, { routing: Link.Orthogonal, layerName: 'Background' }, createLinkShape());

const vertexComparer = ({ node: a }, { node: b }) =>
 a?.data.parentIndex || Number.MAX_SAFE_INTEGER - b?.data.parentIndex || Number.MAX_SAFE_INTEGER;

export const createDiagram = elementId => {
  const diagram = $(Diagram, elementId, {   
    padding                     : new Margin(60, 15, 15, 60),
    initialContentAlignment     : Spot.TopLeft,
    isReadOnly                  : false,
    allowHorizontalScroll       : true,
    allowVerticalScroll         : true,
    allowZoom                   : false,
    allowSelect                 : true,
    allowMove                   : false,
    allowDragOut                : false,
    allowRelink                 : false,
    allowReshape                : false,
    allowResize                 : false,
    allowInsert                 : false,
    allowDelete                 : false,
    allowGroup                  : false,
    allowLink                   : false,
    allowTextEdit               : false,
    allowUngroup                : false,
    contentAlignment            : Spot.TopLeft,
    maxSelectionCount           : 1,
    'animationManager.isEnabled': false,
    'undoManager.isEnabled'     : false,
  });  
  diagram.toolManager.dragSelectingTool.isEnabled = false;
  diagram.toolManager.linkingTool.isEnabled = false;
  diagram.toolManager.draggingTool.isEnabled = false;
  return diagram;
};

export const addModel = (diagram, ideas) => {
  const model = new TreeModel();
  model.nodeKeyProperty = 'id';
  model.nodeParentKeyProperty = 'parent';
  model.nodeDataArray = Object.values(ideas);
  diagram.model = model;
  diagram.add($(Node, 'Auto', { name: 'spine', selectable: false, layerName: 'Adornment' }, $(Shape, 'LineH', { strokeWidth: 2, stroke: linkStroke })));
};

export const NodeCategory = {
  Root     : 'root',
  MainTopic: 'maintopic',
  SubTopic : 'subtopic',
};

const ExpanderLocations = {
  Top   : [new Spot(0.5, 0, 0, 1), Spot.BottomCenter, 180],
  Bottom: [new Spot(0.5, 1, 0, -1), Spot.TopCenter, 0],
  Left  : [new Spot(1, 0.5, -1, 0), Spot.LeftCenter, 270],
  Right : [new Spot(0, 0.5, 1, 0), Spot.RightCenter, 90]
};

const createNodeTemplate = (actions, expanderLocation) => $(Node, 'Spot', { isTreeExpanded: false },
  new Binding('isTreeExpanded', 'isExpanded', isExpanded => !!isExpanded),
  $(Panel, 'Auto', { isPanelMain: true },
    $(Shape, 'Rectangle', { portId: '' },
      new Binding('fill', 'style', ({ backgroundColor }) => toColor(backgroundColor)),
      new Binding('strokeWidth', 'style', ({ borderWidth }) => borderWidth),
      new Binding('stroke', 'style', ({ borderColor }) => toColor(borderColor))),
    $(Panel, 'Horizontal', { alignment: Spot.Center, margin: new Margin(7, 10, 5, 10) },
      $(Panel, 'Auto', { margin: new Margin(0, 5, 0, 0), alignment: Spot.Center },
        // comment shape
        new Binding('visible', ({ comment }) => !!comment), 
        new Binding('toolTip', 'comment'), 
        $(Shape, { 
          stroke         : null,
          fill           : '#626262',
          width          : 13,
          height         : 13, 
          geometryStretch: GraphObject.Fill,
          alignment      : Spot.TopLeft,
          geometryString : 'F1M13,11C13,12.104,12.104,13,11,13L2,13C0.896000000000001,13,0,12.104,0,11L0,2C0,0.896000000000001,0.896000000000001,0,2,0L11,0C12.104,0,13,0.896000000000001,13,2z'
        }),
        $(Shape, { 
          stroke         : '#fff',
          fill           : '#e8e8e8',
          width          : 8.928,
          height         : 5.681, 
          geometryStretch: GraphObject.Fill,
          alignment      : Spot.TopLeft,
          margin         : new Margin(3.273, 0, 0, 1.877),
          geometryString : 'F1M1,3L1,10L8,10L8,8L8,7L12,7L12,3z'
        }),
        $(Shape, { 
          stroke         : null,
          fill           : '#fff',
          width          : 2.434,
          height         : 1.623, 
          geometryStretch: GraphObject.Fill,
          alignment      : Spot.TopLeft,
          margin         : new Margin(7.331, 0, 0, 8.371),
          geometryString : 'F1M9,10L12,8L9,8z'
        }),
        $(Shape, { 
          stroke         : null,
          fill           : '#b5b5b5',
          width          : 8.928,
          height         : 2.43, 
          geometryStretch: GraphObject.Fill,
          alignment      : Spot.TopLeft,
          margin         : new Margin(7.331, 0, 0, 1.877),
          geometryString : 'F1M12,11L1,11L1,10L9,10L12,8z'
        })
      ),
      $(Panel, 'Auto', { margin: new Margin(0, 5, 0, 0), alignment: Spot.Center },
        // hyperlink shape
        new Binding('visible', ({ linkType, linkDisplay, link }) => !!(linkType && linkDisplay && link)), 
        new Binding('toolTip', 'linkDisplay'), 
        $(Shape, { 
          stroke         : null,
          fill           : '#626262',
          width          : 13,
          height         : 13, 
          geometryStretch: GraphObject.Fill,
          alignment      : Spot.TopLeft,
          geometryString : 'F1M13,11C13,12.104,12.104,13,11,13L2,13C0.896000000000001,13,0,12.104,0,11L0,2C0,0.896000000000001,0.896000000000001,0,2,0L11,0C12.104,0,13,0.896000000000001,13,2z'
        }),
        $(Shape, { 
          stroke         : null,
          fill           : '#fff',
          width          : 4,
          height         : 5, 
          geometryStretch: GraphObject.Fill,
          alignment      : Spot.TopLeft,
          margin         : new Margin(4.327, 0, 0, 0.982),
          geometryString : 'F1M2.8242,8C2.3692,8,2.0002,7.63,2.0002,7.176L2.0002,5.824C2.0002,5.37,2.3692,5,2.8242,5L5.0002,5L5.0002,4L2.9282,4C1.8632,4,1.0002,4.862,1.0002,5.927L1.0002,7.073C1.0002,8.138,1.8632,9,2.9282,9L5.0002,9L5.0002,8z'
        }),
        $(Shape, { 
          stroke         : null,
          fill           : '#fff',
          width          : 5,
          height         : 1, 
          geometryStretch: GraphObject.Fill,
          alignment      : Spot.TopLeft,
          margin         : new Margin(6.327, 0, 0, 3.982),
          geometryString : 'F1M9,6.6758C9,6.8548,8.855,6.9998,8.676,6.9998L4.324,6.9998C4.145,6.9998,4,6.8548,4,6.6758L4,6.3238C4,6.1458,4.145,5.9998,4.324,5.9998L8.676,5.9998C8.855,5.9998,9,6.1458,9,6.3238z'
        }),
        $(Shape, { 
          stroke         : null,
          fill           : '#fff',
          width          : 4,
          height         : 5, 
          geometryStretch: GraphObject.Fill,
          alignment      : Spot.TopLeft,
          margin         : new Margin(4.327, 0, 0, 7.982),
          geometryString : 'F1M10.1758,8C10.6308,8,10.9998,7.63,10.9998,7.176L10.9998,5.824C10.9998,5.37,10.6308,5,10.1758,5L7.9998,5L7.9998,4L10.0718,4C11.1368,4,11.9998,4.862,11.9998,5.927L11.9998,7.073C11.9998,8.138,11.1368,9,10.0718,9L7.9998,9L7.9998,8z'
        })
      ),
      $(TextBlock, { textAlign: 'center', alignment: Spot.Center, verticalAlignment: Spot.Center },
        new Binding('text', 'caption'),
        new Binding('stroke', 'style', ({ color }) => toColor(color)),
        new Binding('font', 'style', ({ fontName, fontSize }) => ` ${fontSize}px ${fontName}`)
      ))),
  $(Panel, 'Auto', 
    {
      cursor    : 'pointer',
      mouseEnter: (_e, self) => self.part.findObject('arc').fill = '#6DB4EF',
      mouseLeave: (_e, self) => self.part.findObject('arc').fill = '#FFF',
      click     : (_e, { part:{ data:{ id, isExpanded } } }) => actions.setIdeaExpanded(id, !isExpanded),
    },
    new Binding('visible', ({ category, children }) => category !== NodeCategory.Root && (children?.length || 0) > 0),
    new Binding('alignment', data => expanderLocation(data)[0]),
    new Binding('alignmentFocus', data => expanderLocation(data)[1]),
    new Binding('angle', data => expanderLocation(data)[2]),       
    $(Shape, { 
      name          : 'arc',
      geometryString: 'F M0,29 a1,1 0 0,0 29,0',
      fill          : '#FFF',
      strokeWidth   : 2,
      height        : 15, 
      width         : 29,
    },
    new Binding('stroke', 'style', ({ borderColor }) => toColor(borderColor)),
    ),
    $(Shape, 'PlusLine', { strokeWidth: 2, alignment: Spot.Center, height: 7, width: 7 },
      new Binding('stroke', 'style', ({ borderColor }) => toColor(borderColor)),
      new Binding('visible', 'isExpanded', isExpanded => !isExpanded),
    ),
    $(Shape, 'MinusLine', { strokeWidth: 2, alignment: Spot.Center, height: 2, width: 7 },
      new Binding('stroke', 'style', ({ borderColor }) => toColor(borderColor)),
      new Binding('visible', 'isExpanded', isExpanded => isExpanded === true),
    )         
  ),
  $(Panel, 'Auto', { alignment: Spot.TopRight }, new Binding('visible', ({ priority }) => priority && priority !== '0'),
    $(Shape, 'Circle', { height: 16, width: 16, fill: '#FFF', strokeWidth: 2 },        
      new Binding('stroke', 'priority', priority => PropertyColors[priority]),
    ),
    $(TextBlock, { margin: new Margin(2, 0, 0, 0), textAlign: 'center', alignment: Spot.Center, verticalAlignment: Spot.Center, font: 'bold 10px Segoe UI, Open Sans, sans-serif' },
      new Binding('text', 'priority'),
      new Binding('stroke', 'priority', priority => PropertyColors[priority]),
    )
  )
);

export const createLayout = (layout, outlineLevel, actions) => {
  switch (layout) {
    case 'fishbone':
      return {
        linkTemplate: $(FishboneLink, { layerName: 'Background' }, createLinkShape()),
        layout      : $(FishboneLayout, { 
          angle              : 180,
          layerSpacing       : 10,
          nodeSpacing        : 40,
          rowSpacing         : 25,
          arrangementSpacing : 15,
          alignment          : FishboneLayout.AlignmentBusBranching,
          alternateSorting   : FishboneLayout.SortingAscending,
          sorting            : FishboneLayout.SortingAscending,
          alternateCompaction: FishboneLayout.CompactionNone,
          compaction         : FishboneLayout.CompactionNone,
          alternateComparer  : vertexComparer,
          comparer           : vertexComparer,
        }),
        nodeTemplate: createNodeTemplate(actions, ({ fishboneOrientation, level }) => fishboneOrientation === 'toporleft' ? 
          level % 2 ? ExpanderLocations.Left : ExpanderLocations.Bottom 
          : level % 2 ? ExpanderLocations.Right : ExpanderLocations.Top)
      };
    case 'cttree': 
      return {
        linkTemplate: tableLink,
        layout      : $(CTreeLayout, { 
          treeStyle            : TreeLayout.StyleLayered,
          level                : outlineLevel,
          angle                : 90,
          alternateAngle       : 90,
          layerSpacing         : 50,
          alternateLayerSpacing: 25,  
          rowSpacing           : 10,
          alternateRowSpacing  : 10,
          rowIndent            : 10,
          alignment            : TreeLayout.AlignmentCenterChildren,
          alternateAlignment   : TreeLayout.AlignmentBottomRightBus,
          nodeSpacing          : 30,
          alternateNodeSpacing : 30,
          alternateSorting     : TreeLayout.SortingAscending,
          sorting              : TreeLayout.SortingAscending,
          alternateCompaction  : TreeLayout.CompactionNone,
          compaction           : TreeLayout.CompactionNone,
          alternateComparer    : vertexComparer,
          comparer             : vertexComparer,
        }),
        nodeTemplate: createNodeTemplate(actions, () => ExpanderLocations.Bottom)
      };
    case 'ideamap': 
      return {
        linkTemplate: tableLink,
        layout      : $(DoubleTreeLayout, { 
    
          directionFunction : ({ data: { ideaMapOrientation } = {} }) => ideaMapOrientation !== 'left',
          bottomRightOptions: {
            nodeSpacing: 35,
            rowSpacing : 10,
            sorting    : TreeLayout.SortingAscending,
            comparer   : vertexComparer,
          },
          topLeftOptions: {
            sorting : TreeLayout.SortingAscending,
            comparer: vertexComparer,
          },
        }),
        nodeTemplate: createNodeTemplate(actions, ({ ideaMapOrientation }) => ideaMapOrientation === 'right' ? ExpanderLocations.Left : ExpanderLocations.Right)
      };
    case 'righttree': 
      return {
        linkTemplate: tableLink,
        layout      : $(TreeLayout, { 
          nodeSpacing: 35,
          rowSpacing : 10,
          angle      : 0,
          sorting    : TreeLayout.SortingAscending,
          comparer   : vertexComparer,
        }),
        nodeTemplate: createNodeTemplate(actions, () => ExpanderLocations.Left)
      };
    case 'lefttree': 
      return {
        linkTemplate: tableLink,
        layout      : $(TreeLayout, { 
          nodeSpacing: 35,
          rowSpacing : 10,
          angle      : 180,
          sorting    : TreeLayout.SortingAscending,
          comparer   : vertexComparer,
        }),
        nodeTemplate: createNodeTemplate(actions, () => ExpanderLocations.Right)
      };
    case 'mindmap':
      return {
        linkTemplate: $(Link, { routing: Link.Normal, layerName: 'Background', selectable: false }, createLinkShape()),
        layout      : $(ForceDirectedLayout, {
          randomNumberGenerator         : null,
          maxIterations                 : 100,
          infinityDistance              : 700,
          arrangesToOrigin              : false,
          defaultCommentElectricalCharge: 150,
          defaultSpringLength           : 40,
          defaultSpringStiffness        : 0.1,
          defaultGravitationalMass      : 0.1
        }),
        nodeTemplate: createNodeTemplate(actions, () => ExpanderLocations.Right)
      };
    default:
      throw new Error('unknown layout');
  }

};