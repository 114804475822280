
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useServerUpdate, BrainstormActions } from '../../services/apiService';
import { NodeCategory, createLayout, addModel, createDiagram } from './BrainstormUtils';

import './Brainstorm.scss';

const toViewState = (_, newState) => {
  const state = {
    ...newState,
    ideas: newState.ideas.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {})
  };
  const prepareForView = (idea, isExpanded, level, parentIndex) => {
    idea.category = idea.id === state.rootRuntimeId ? NodeCategory.Root
      : idea.parent === state.rootRuntimeId ? NodeCategory.MainTopic
        : NodeCategory.SubTopic;
    idea.isExpanded = idea.isExpanded && isExpanded;
    idea.level = level;
    idea.parentIndex = parentIndex;
    idea.children.forEach((id, i) => prepareForView(state.ideas[id], isExpanded && idea.isExpanded, level + 1, i));
  };    
  prepareForView(state.ideas[state.rootRuntimeId], true, 1);
  return state;
};

const Brainstorm = ({ data: rawData, id, projectId }) => {
  const [diagram, setDiagram] = useState();
  const [bound, setBounds] = useState();
  const [actions, setActions] = useState();
  const [data, setData] = useServerUpdate(rawData, toViewState);
  useEffect(() => {
    setActions({
      setIdeaExpanded: (ideaId, isExpanded) => 
        setData(() => BrainstormActions.setIdeaExpanded(projectId, id, ideaId, isExpanded)),
    });
  }, [projectId, id, setData]);

  const { ideas, layout, outlineLevel } = data || {};
  useEffect(() => {
    const diagram = createDiagram(`${projectId}-${id}`);
    const changeEvent = () => setBounds(diagram.documentBounds);
    diagram.addDiagramListener('DocumentBoundsChanged', changeEvent);
    setDiagram(diagram);
    return () => diagram.removeDiagramListener('DocumentBoundsChanged', changeEvent);    
  }, [id, projectId]);

  useEffect(() => {
    if (ideas) {
      addModel(diagram, ideas);
    }
  }, [diagram, ideas]);

  useEffect(() => {
    if (!layout) {
      return;
    }
    const layoutData = createLayout(layout, outlineLevel, actions);
    diagram.linkTemplate = layoutData.linkTemplate;
    diagram.layout = layoutData.layout;
    diagram.nodeTemplate = layoutData.nodeTemplate;
  }, [diagram, actions, layout, outlineLevel]);
  
  return (
    <div className='companion-brainstorm'>
      <div className='diagram' diagram-height={bound && bound.height} diagram-width={bound && bound.width} id={`${projectId}-${id}`}/>
    </div>
  );
};
   
export default Brainstorm;
  
Brainstorm.defaultProps = {};
  
Brainstorm.propTypes = {
  data       : PropTypes.object.isRequired,
  id         : PropTypes.string.isRequired,
  projectId  : PropTypes.string.isRequired,
  setUndoRedo: PropTypes.func
};
  