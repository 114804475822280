import * as go from 'gojs';
export class CTreeLayout extends go.TreeLayout {

  constructor() {
    super(...arguments);
    this._level = 1;
  }

  get level() {
    return this._level; 
  }
  set level(value) {
    if (this._level !== value) {
      this._level = value;
      this.invalidateLayout();
    }
  }

  initializeTreeVertexValues(v) {
    if (v.level >= this.level - 1) {
      v.copyInheritedPropertiesFrom(this.alternateDefaults);
      v.initialized = true;
    } else {
      super.initializeTreeVertexValues(v);
    }
  }
}