import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { undo, redo, FormActions, useServerUpdate } from '../../services/apiService';
import messageService from '../../services/messageService';
import { splitWithEscape } from './Control.Utils';
import FormContent from './FormContent';

const ProjectSummaryCategoryName = 'projectsummary';
const ProjectSummaryNameTag = 'name';

const transformRawValue = (controls, resolver) => Object.values(controls).forEach(data => {
  const {
    controlLocation,
    dataTableId,
    rawValue,
    type
  } = data;
  if (!rawValue) {
    return;
  }
  if (controlLocation === 'single') {
    data.rawValue = [type === 'richedit' ? [rawValue] : splitWithEscape(rawValue, '')];
    return;
  }

  const {
    columnValueOrder,
    repeatingColumnIds,
    rowIds,
    rowValueOrder
  } = (controls[dataTableId] ?? resolver[dataTableId]);
  const columns = splitWithEscape(rawValue, ';', false).map(col => {
    const rows = splitWithEscape(col, ',');
    return rows.length > 1 ? rowIds.map(id => rows[rowValueOrder[id]]) : rows;
  });
  data.rawValue = columns.length > 1 ? repeatingColumnIds.map(id => columns[columnValueOrder[id]]) :
    columns;
});

const mashState = (oldState, mergeState) => {
  transformRawValue(mergeState.controls, oldState.controls);
  return {
    ...oldState,
    ...mergeState,
    controls: { ...oldState.controls, ...mergeState.controls }
  };
};

const Form = ({ data:rawData, projectId, id, setUndoRedoState }) => {
  const [data, setData, pendingUpdates] = useServerUpdate(rawData, mashState);
  const [actions, setActions] = useState();
  const [editUndoRedo, setEditUndoRedo] = useState();

  useEffect(() => {
    if (!setUndoRedoState) {
      return;
    }
    setUndoRedoState({
      canRedo: data?.canRedo || editUndoRedo?.canRedo,
      canUndo: data?.canUndo || editUndoRedo?.canUndo,
      redo   : data?.canRedo ? actions.redo : editUndoRedo?.redo,
      undo   : editUndoRedo?.canUndo ? editUndoRedo.undo : actions?.undo
    });
  }, [actions, data, editUndoRedo, setUndoRedoState]);

  useEffect(() => {
    messageService.registerGetPendingChangesCount(() => {
      messageService.sendPendingUpdatesCount(projectId, id, pendingUpdates);
    });
  }, [projectId, id, pendingUpdates]);

  useEffect(() => {
    setActions({
      addRows: (tableId, count, direction, insertReference) => setData(() =>
        FormActions.addRows(projectId, id, tableId, { insertReference, direction, count })
      ),
      addColumns: (tableId, count, direction, insertReference) => setData(() =>
        FormActions.addColumns(projectId, id, tableId, { insertReference, direction, count })
      ),
      deleteRows: (tableId, context, ids) => setData(() =>
        FormActions.deleteRows(projectId, id, tableId, { ids, context })
      ),
      deleteColumns: (tableId, context, ids) => setData(() =>
        FormActions.deleteColumns(projectId, id, tableId, { ids, context })
      ),
      redo: () => setData(() => redo(projectId, id), { shouldExecute: latest => latest.canRedo }),
      undo: () => setData(() => undo(projectId, id), { shouldExecute: latest => latest.canUndo }),

      update: (controlId, data) => setData(async () => {
        const result = await FormActions.setControlValue(projectId, id, controlId, data);
        const map = result.controls;
        // eslint-disable-next-line no-unused-vars
        for (const ctrlId in map) {
          const control = map[ctrlId];
          if (control.category === ProjectSummaryCategoryName && control.codename === ProjectSummaryNameTag) {
            messageService.sendProjectRenamed(projectId, control.rawValue);
          }
        }
        return result;
      }, { dedup: `Set-${controlId}` }),
      findUsers     : (searchValue) => messageService.sendGetUserList(searchValue),
      getTeamMembers: () => FormActions.getTeamMembers(projectId, id)
    });
  }, [projectId, id, setData]);

  return data ? (
    <FormContent {...data}
      actions={actions} id={id} projectId={projectId} setUndoRedo={setEditUndoRedo}/>
  ) : null;
};

export default Form;

Form.defaultProps = {};

Form.propTypes = {
  data       : PropTypes.object.isRequired,
  projectId  : PropTypes.string.isRequired,
  id         : PropTypes.string.isRequired,
  setUndoRedo: PropTypes.func,
};
