import { v4 as uuidv4 } from 'uuid';
const WEB_EDITOR_MESSAGE = 'WEB_EDITOR_MESSAGE';
const CBM_ONLINE_MESSAGE = 'CBM_ONLINE_MESSAGE';
const CBM_FETCH = 'CBM_FETCH';
const WEB_EDITOR_HANDSHAKE = 'WEB_EDITOR_HANDSHAKE';
const CBM_HANDSHAKE = 'CBM_HANDSHAKE';
const CBM_FETCH_RESPONSE = 'CBM_FETCH_RESPONSE';
const WEB_EDITOR_ERROR = 'WEB_EDITOR_ERROR';
const CBM_CLOSE_FILE = 'CBM_CLOSE_FILE';
const CBM_UNDO = 'CBM_UNDO';
const CBM_REDO = 'CBM_REDO';
const WEB_EDITOR_CAN_UNDO = 'WEB_EDITOR_CAN_UNDO';
const WEB_EDITOR_CAN_REDO = 'WEB_EDITOR_CAN_REDO';
const CBM_GET_PENDING_UPDATES_COUNT = 'CBM_GET_PENDING_UPDATES_COUNT';
const WEB_EDITOR_PENDING_UPDATES_COUNT = 'WEB_EDITOR_PENDING_UPDATES_COUNT';
const CBM_USER_LIST = 'CBM_USER_LIST';
const WEB_EDITOR_GET_USERS = 'WEB_EDITOR_GET_USERS';
const CBM_SET_PROJECT_NAME = 'CBM_SET_PROJECT_NAME';
const WEB_EDITOR_SET_PROJECT_NAME_COMPLETED = 'WEB_EDITOR_SET_PROJECT_NAME_COMPLETED';
const CBM_WORKFLOW_UPDATE = 'CBM_WORKFLOW_UPDATE';
const WEB_EDITOR_PROJECT_RENAMED = 'WEB_EDITOR_PROJECT_RENAMED';
const CBM_RELOAD = 'CBM_RELOAD';
const WEB_EDITOR_UNAUTHORIZED = 'WEB_EDITOR_UNAUTHORIZED';

class MessageService {
  version = '1.0';
  pendingFetches = {};
  receivedHandshake = false;
  parentHost = '';
  userSearches = {};
  pendingMessages = {};

  constructor() {
    // If there is no parent window, we're standalone in CEF or WebKit
    // Otherwise we're hosted in an iframe
    if (self !== top) { // eslint-disable-line no-restricted-globals
      const fetchProto = fetch;
      global.fetch = (...args) => {
        // Allow requesting resources from this own server
        if (!args[0].startsWith('api')) {
          const url = args[0].startsWith('/') ? args[0].slice(1) : args[0];
          return fetchProto(url, ...args.slice(1));
        }
        const id = uuidv4();
        this.postMessage(CBM_FETCH, { args, id });
        return new Promise((res, rej) => {
          this.pendingFetches[id] = res;
          this.pendingFetches[`${id}-error`] = rej;
          // 10 second default timeout
          setTimeout(10000, () => {
            delete this.pendingFetches[id];
            rej(new Error('FAILED_FETCH_FORWARD'));
          });
        });
      };

      this.parentHost = process.env.REACT_APP_SITE_URL.replace('://editor.', '://').replace(/\/$/, '');

      // Register window listener
      window.addEventListener('message', this.messageHandler);

      this.sendHandshake();

      // Register known callbacks
      this.registerEventHandler(CBM_FETCH_RESPONSE, this.fetchResponse);
      this.registerEventHandler(CBM_HANDSHAKE, this.receiveHandshake);      
      this.registerEventHandler(CBM_USER_LIST, this.receiveUserList);
      this.registerEventHandler(CBM_RELOAD, this.receiveReloadRequest);
    }
  }

  registerCloseFile = fn => this.registerEventHandler(CBM_CLOSE_FILE, fn);
  registerUndo = fn => this.registerEventHandler(CBM_UNDO, fn);
  registerRedo = fn => this.registerEventHandler(CBM_REDO, fn);
  registerGetPendingChangesCount = fn => this.registerEventHandler(CBM_GET_PENDING_UPDATES_COUNT, fn);
  registerSetProjectName = fn => this.registerEventHandler(CBM_SET_PROJECT_NAME, fn);
  registerUpdateWorkflow = fn => this.registerEventHandler(CBM_WORKFLOW_UPDATE, fn);

  registerEventHandler = (name, fn) => {
    if (typeof fn === 'function') {
      this[name] = fn;
      (this.pendingMessages[name] || []).forEach(msg => {
        fn.call(null, ...msg);
      });
      this.pendingMessages[name] = [];
    } else {
      console.warn(`Tried registering ${name} which isn't a function. Type: ${typeof fn}`);
    }
  };

  invokeRegisteredEvent = (name, ...args) => {
    if (this[name] && typeof this[name] === 'function') {
      this[name].call(null, ...args);
    } else {
      const pendingMsgs = this.pendingMessages[name] || [];
      pendingMsgs.push(args);
      this.pendingMessages[name] = pendingMsgs;
      console.warn(`Tried invoking ${name} which doesn't exist or is not a function`);
    }
  };

  messageHandler = event => {
    if (event.origin !== this.parentHost) {
      return;
    }
    const action = event.data;    
    switch (action.sender) {
      case CBM_ONLINE_MESSAGE:
        this.invokeRegisteredEvent(action.data.type, ...action.data.arguments);
        break;
      default:
        break;
    }
  };

  postMessage = (type, payload) => {
    self !== top && window.parent.postMessage( // eslint-disable-line no-restricted-globals
      {
        sender: WEB_EDITOR_MESSAGE,
        data  : {
          type,
          payload,
        },
      },
      this.parentHost
    );
  }

  sendHandshake = () => {
    this.postMessage(WEB_EDITOR_HANDSHAKE, this.version);
  }
    
  receiveHandshake = version => {    
    if (this.version !== version) {
      console.error(`Version mismatch. Our version: ${this.version}, theirs: ${version}`);
    }
    this.receivedHandshake = true;
  }

  sendPendingUpdatesCount = (projectId, toolId, pendingUpdatesCount) => {   
    const payload = { projectId, toolId, pendingUpdatesCount };
    this.postMessage(WEB_EDITOR_PENDING_UPDATES_COUNT, payload);    
  };
  sendCanUndo = canUndo => this.postMessage(WEB_EDITOR_CAN_UNDO, canUndo);
  sendCanRedo = canRedo => this.postMessage(WEB_EDITOR_CAN_REDO, canRedo);
     
  sendGetUserList = async (searchTerm) => {
    const transactionId = uuidv4();
    const payload = { searchTerm, transactionId };
    this.postMessage(WEB_EDITOR_GET_USERS, payload);
    return new Promise((res) => {
      this.userSearches[transactionId] = res;
    });
  }
  
  sendRenameProjectComplete = (projectId, result) => {
    const payload = 
    { success: result.success, networkError: result.networkError, errorCode: result.errorCode, projectId: projectId };
    this.postMessage(WEB_EDITOR_SET_PROJECT_NAME_COMPLETED, payload);    
  };
  
  sendProjectRenamed = (projectId, name) => {
    const payload = { newName: name, projectId: projectId };
    this.postMessage(WEB_EDITOR_PROJECT_RENAMED, payload);    
  };

  sendUnauthorized = () => {
    this.postMessage(WEB_EDITOR_UNAUTHORIZED, {});
  };

  fetchResponse = ({ id, response }) => {
    if (this.pendingFetches[id]) {

      if (response.error) {
        this.pendingFetches[`${id}-error`](response.error);
      } else {
        // Implement Response interface
        this.pendingFetches[id]({ text() {
          return response.text;
        }, json() {
          return response.json;
        } });
      }
      
      delete this.pendingFetches[id];
      delete this.pendingFetches[`${id}-error`];
    }
  }  

  receiveUserList = (findUserResult) => {      
    if (this.userSearches[findUserResult.transactionId]) {
      this.userSearches[findUserResult.transactionId](findUserResult.users);
      delete this.userSearches[findUserResult.transactionId];
    }    
  }

  dialogErrorPopup = (shown) => {
    this.postMessage(WEB_EDITOR_ERROR, shown);
  }

  receiveReloadRequest = () => {
    window.location.reload();
  }
}

export const messageService = new MessageService();
export default messageService;