import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Delay = ({ time, children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setShow(true), time);
    return () => clearTimeout(id);
  }, [time]);

  return show ? children : null;
};

Delay.propTypes = {
  time: PropTypes.number.isRequired,
};

export default Delay;
