import { useState, useEffect, useCallback, useRef } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import formatDate from 'date-fns/format';
const epoch = Date.UTC(1899, 11, 30);
const msPerDay = 8.64e7;
const roundingError = 1E10;

const toUTC = date => Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
      
export const AddDirection = {
  above: 'Above',
  below: 'Below',
  left : 'Left',
  right: 'Right',
};

export const DeleteContext = {
  project: 'Project',
  table  : 'Table'
};

export const toDate = value => {
  const date = new Date((value * msPerDay) + +epoch);
  return new Date(+date + (date.getTimezoneOffset() * 60000));
};

const fixRoundingError = numeric => {
  const rounded = Math.round(numeric * roundingError) / roundingError;
  return Math.abs(rounded - numeric) < 1e13 ? rounded : numeric;
};

const toNumeric = (unitType, unitFactors, value) => {
  if (unitType && unitType !== 'none') {
    const [baseValue, unit] = value.split('?');
    const numeric = Number.parseFloat(baseValue) / Number.parseFloat(unitFactors[+unit]);
    return fixRoundingError(numeric);
  }
  return Number.parseFloat(value);
};

export const fromDate = date => Math.floor((toUTC(date) - epoch) / msPerDay);

export const fromEditValue = ({ 
  dataFormat,
  currencySymbol, 
  culture: { id:cultureId },
  formatting, 
  quickFillCategory,
  quickFillField,
  isReadOnly,
  unitType,
  unitDefinition: { unitFactors } = {}
}, value, unit) => {
  const unitted = unitType && unitType !== 'none';
  const addUnit = value => unitted && unit ? `${value}?${unit}` : value;

  if (value && quickFillCategory === 'teammember' && quickFillField === 'name' && !isReadOnly) {
    return value;
  }

  if (!value || dataFormat === 'Guid' || dataFormat === 'String') {
    return addUnit(value);
  }
  const parts = Intl.NumberFormat(cultureId, {
    style          : 'currency',
    currencyDisplay: 'symbol',
    currency       : currencySymbol,
    useGrouping    : true
  }).formatToParts(10000.123);
  
  const groupingSeparator = parts.find(({ type }) => type === 'group')?.value;
  const currency = parts.find(({ type }) => type === 'currency')?.value;
  const decimal = parts.find(({ type }) => type === 'decimal' && value !== '.')?.value;

  value = value.trim();
  if (currency) {
    const valid = `[${currency}]?`;
    value = value.replace(new RegExp(`^${valid}([-+])?${valid}(.*?)${valid}$`), '$1$2');
  }

  if (groupingSeparator) {
    value = value.replace(groupingSeparator, '');
    if (groupingSeparator.charCodeAt(0) === 8239) {
      // if the symbol is non breaking space also check for regular space
      value = value.replace(' ', '');
    }
  }

  if (decimal) {
    value = value.replace(decimal, '.');
    if (decimal.charCodeAt(0) === 8239) {
      // if the symbol is non breaking space also check for regular space
      value = value.replace(' ', '.');
    }
  }
  
  let numeric = Number(value);
  if (!Number.isFinite(numeric)) {
    return addUnit(value);
  }
  if (formatting === 'percent') {
    numeric = fixRoundingError(numeric / 100);
  }
  if (unitted) {
    return addUnit(numeric * Number.parseFloat(unitFactors[+unit]));
  }   
  return numeric.toString();
};
  
export const toEditValue = ({ 
  conditionalFormat : format,
  culture: { id:cultureId },
  dataFormat,
  formatting,
  quickFillCategory,
  quickFillField,
  isReadOnly,
  unitType,
  unitDefinition: { unitFactors } = {}
}, value) => {
  
  if (format?.formulaOverwriteValue) {
    return format?.formulaOverwriteValue;
  }
  // team member roles are format <name> - <email> - <uid> - 
  if (value && quickFillCategory === 'teammember' && quickFillField === 'name' && !isReadOnly) {
    return value.split(' - ')[0];
  }

  if (!value || value === '*' || dataFormat === 'Guid' || dataFormat === 'String') {
    return value;
  }
  
  const numeric = toNumeric(unitType, unitFactors, value); 
  if (!Number.isFinite(numeric)) {
    return unitType && unitType !== 'none' ? '' : value;
  }
  return Intl.NumberFormat(cultureId, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 15,
    useGrouping          : false
  }).format(formatting === 'percent' ? fixRoundingError(numeric * 100) : numeric);
};

export const getUnit = ({ unitType }, rawValue) => {
  if (!unitType || unitType === 'none') {
    return '';
  }
  const result = rawValue.split('?');
  return result.length > 1 ? result[result.length - 1] : '';
};

export const getBaseValue = rawValue => {
  const result = rawValue.split('?');
  return result.length > 1 ? result[0] : '';
};

export const formatValue = (data, value) => {
  const { defaultUnit, unitDefinition: { unitList } = {}, unitType } = data;
  const formatted = formatValueNoUnit(data, value);
  return !unitType || unitType === 'none' ? formatted : `${formatted} ${unitList[getUnit(data, value) || defaultUnit]}`;
};

export const formatValueNoUnit = ({ 
  conditionalFormat : format,
  currencySymbol, 
  culture: { id:cultureId, dateLocale },
  dataFormat,
  decimalPlaces,
  formatting, 
  quickFillCategory, 
  quickFillField, 
  isReadOnly,
  unitType,
  unitDefinition: { unitFactors } = {},
  thousandsSeparator = false 
}, value) => {
  
  if (format?.formulaOverwriteValue) {
    return format?.formulaOverwriteValue;
  }

  if (value && quickFillCategory === 'teammember' && quickFillField === 'name' && !isReadOnly) {
    return value.split(' - ')[0];
  }

  if (!value || value === '*' || dataFormat === 'Guid' || dataFormat === 'String') {
    return value;
  }
  
  const numeric = toNumeric(unitType, unitFactors, value); 
  if (!Number.isFinite(numeric)) {
    return unitType !== 'none' ? '' : value;
  }

  const minimumFractionDigits = decimalPlaces || 0; 
  const maximumFractionDigits = decimalPlaces || (decimalPlaces === 0 ? 0 : 15); 
  switch (formatting) {
    case 'percent':
      return Intl.NumberFormat(cultureId, {
        style      : 'percent',
        minimumFractionDigits,
        maximumFractionDigits,
        useGrouping: !!thousandsSeparator
      }).format(numeric);
    case 'number':
      return Intl.NumberFormat(cultureId, {
        minimumFractionDigits,
        maximumFractionDigits,
        useGrouping: !!thousandsSeparator
      }).format(numeric);
    case 'currency':
      return Intl.NumberFormat(cultureId, {
        style          : 'currency',
        minimumFractionDigits,
        maximumFractionDigits,
        currencyDisplay: 'symbol',
        currency       : currencySymbol,
        useGrouping    : !!thousandsSeparator
      }).format(numeric);
    case 'scientific':
      if (new Intl.NumberFormat(cultureId).resolvedOptions().notation) {
        return Intl.NumberFormat(cultureId, {
          minimumFractionDigits,
          maximumFractionDigits,
          useGrouping: !!thousandsSeparator,
          notation   : 'scientific'
        }).format(numeric);
      }
      return numeric.toExponential(minimumFractionDigits);
    case 'date/time':
    case 'd':
      return formatDate(toDate(numeric), 'P', { locale: dateLocale });
    case 'D':
      // ideally we use PPPP but there are issues https://github.com/date-fns/date-fns/pull/1795, https://github.com/date-fns/date-fns/pull/1792
      return formatDate(toDate(numeric), 'PPP', { locale: dateLocale });
    default:
      return value;
  }
};

export const splitWithEscape = (value, delim, escapeAll = true, escapeChar = '\\') => {
  const [token, list, _] = value.split('')
    .reduce(([token, list, escape], x) => {
      return escape ? 
        [token + ((escapeAll || x === delim) ? x : escapeChar + x), list, false ]
        : [ x === delim ? '' : (token + (x === escapeChar ? '' : x)),
          list.concat(x === delim ? token : []),
          x === escapeChar
        ];
    }, ['', [], false]);

  return list.concat(token);
};

const formualError = 'FORMULA_ERRORMESSAGEBLOCK_BEGIN_{793EE4B7-2FFA-41fd-BE44-7A75D958B310}:';

const isDataSourceIncompatible = ({ category, codename, controlType, dataFormat, type }) => {

  switch (type === 'textbox' && controlType === 'formula' ? controlType : type) {
    case 'richedit':
      return controlType === 'richtext';
    case 'formula':
      return (category && codename) ? controlType === 'formula' && dataFormat !== 'date' : true;
    case 'memo':
      return controlType === 'memo';
    case 'textbox':
      return controlType === 'single';
    case 'radiobuttons':
    case 'dropdown':
    case 'listbox':
      return controlType === 'list';
    case 'datepicker':
      return controlType === 'date';
    case 'checkbox':
      return true;
    case 'hyperlink':
      return true;
    default:
      return true;
  }
};

export const checkForDataOverrides = (data, t) => {
  const { 
    category, 
    codename, 
    formulaVariables,
    isCategoryCompatible,
    isDataSourceMissing, 
    lockInfo,
    value
  } = data;
  if (isDataSourceMissing) {
    return { dataError: true, value: '', isReadOnly: true, tooltip: t('errors.data.dataSourceMissing', { category, codename }) };
  } 
  if (!isCategoryCompatible) {
    return { dataError: true, value: '', isReadOnly: true, tooltip: t('errors.data.categoryIncompatible', { category }) };
  }
  if (!isDataSourceIncompatible(data)) {
    return { dataError: true, value: '', isReadOnly: true, tooltip: t('errors.data.dataSourceIncompatible', { category, codename }) };
  }

  if (lockInfo) {
    return { value: (value.startsWith(formualError) ? '*' : value), isReadOnly: true, tooltip: lockInfo.message || t('forms.lockedControl', { field: lockInfo.field, category: lockInfo.category }) };
  }

  if (value.startsWith(formualError)) {
    const [code, ...hints] = splitWithEscape(value.substr(formualError.length), '?');
    return { value: '*', tooltip: code && t(`errors.formulas.${code}`, hints.map(hint => (formulaVariables && formulaVariables[hint]) || hint)) };
  } 

};

export const useUndoRedo = (value, setUndoRedoState) => {
  const [initialValue, setInitialValue] = useState(value);
  const [current, setCurrent] = useState(initialValue);
  const [position, setPosition] = useState(0);
  const [changes, setChanges] = useState();
  const focusRef = useRef();

  useEffect(() =>
    setUndoRedoState(!changes ? undefined : {
      canRedo: position < changes.length - 1,
      canUndo: position > 0,
      redo   : () => {
        setPosition(position + 1);
        setCurrent(changes[position + 1]);
        if (focusRef.current) {
          focusRef.current.focus();
        }
      },
      undo: () => {
        setPosition(position - 1);
        setCurrent(changes[position - 1]);
        if (focusRef.current) {
          focusRef.current.focus();
        }
      }
    }), [changes, position, setUndoRedoState]);

  const [push, cancelPending] = useDebouncedCallback(
    (changes, position) => {
      setChanges(changes);
      setPosition(position);
    },
    200,
  );

  return [
    current,
    useCallback((value) => {
      const next = position + 1;
      push([...(changes ? changes.slice(0, next) : [initialValue]), value], next);
      setCurrent(value);
    }, [initialValue, changes, push, position]),
    useCallback(value => {
      cancelPending();
      setChanges();
      setPosition(0);
      setCurrent(value);
      setInitialValue(value);
    }, [cancelPending]),
    focusRef
  ];
};

