import React, { useState, useEffect, useCallback } from 'react';

import { getProject, getTool, heartbeat, updateWorkflowFields } from './services/apiService';
import { Brainstorm, Form, ProcessMap, ProjectManager, Delay, SplashScreen } from './components';
import messageService from './services/messageService';

const Project = ({ projectId, renamed, setUndoRedo, showProjectManager }) => {
  const [projectData, setProjectData] = useState();
  const [selected, setSelected] = useState(JSON.parse(sessionStorage.getItem('activeTool') || '{}'));
  const [activeTool, setActiveTool] = useState();

  useEffect(() => sessionStorage.setItem('activeTool', JSON.stringify(selected || {})), [selected]);

  useEffect(() => {
    const heartbeatInterval = 2.5 * 60 * 1000;
    const interval = setInterval(async () => {      
      // heartbeat to keep open project alive in the cache      
      await heartbeat(projectId);
    }, heartbeatInterval);
    return () => clearInterval(interval);
  }, [projectId]);

  const createTool = useCallback((id, data) => {
    switch (data.type) {
      case 'form':
        return <Form data={data} id={id} projectId={projectId} setUndoRedoState={setUndoRedo} />;
      case 'brainstorm':
        return <Brainstorm data={data} id={id} projectId={projectId} setUndoRedoState={setUndoRedo} />;
      case 'processmap':
        return <ProcessMap data={data} id={id} projectId={projectId} setUndoRedoState={setUndoRedo} />;
      default:
        return null;
    }
  }, [projectId, setUndoRedo]);

  useEffect(() => {
    setProjectData();
    if (!projectId) {
      return;
    }
    let active = true;
    (async () => {
      try {
        const data = await getProject(projectId);
        if (!active) {
          return;
        }
        setProjectData(data);
        if (!showProjectManager) {
          setSelected({ projectId, tool: Object.keys(data)[0] }); // Assume we're showing an idea form and just display the first item
        }
      } catch (e) {
        setProjectData();
      }
    })();
    return () => active = false; 
  }, [projectId, showProjectManager]);

  useEffect(() => {
    messageService.registerUpdateWorkflow(async workflowUpdate => {
      if (workflowUpdate.Id !== projectId) {
        return;
      }
      setProjectData();
      const data = await updateWorkflowFields(workflowUpdate.Id, workflowUpdate);
      setProjectData(data);
      if (!showProjectManager) {
        setSelected({ projectId, tool: Object.keys(data)[0] }); // Assume we're showing an idea form and just display the first item
      }
    });
  }, [projectId, showProjectManager]);

  useEffect(() => {
    if (!selected || !selected.projectId || !selected.tool || selected.projectId !== projectId) {
      setActiveTool();
      return;
    }
    let active = true;
    setActiveTool(<Delay time={400}><SplashScreen/></Delay>);
    (async () => {
      try {
        const data = await getTool(selected.projectId, selected.tool);
        if (!active) {
          return;
        }
        setActiveTool(createTool(selected.tool, data));
      } catch (e) {
        setActiveTool();
      }
    })(); 
    return () => active = false; 
  }, [projectId, renamed, selected, createTool]);

  return (
    <div className="app-container">
      { 
        showProjectManager && <ProjectManager
          items={projectData}
          selected={selected && selected.tool}
          setSelected={tool => setSelected({ projectId, tool })} 
        />
      }
      {/* 
      onScroll is a total hack to get tinymce to response to the inner scroll container. 
      They don't support this with inline they only look at the window scrolling. 
      Its all over the github issues, hopefully they implement at some point.
      */ }
      <div className="companion-tool-host" onScroll={() => window.dispatchEvent(new Event('resize'))}> 
        {activeTool}    
      </div>
    </div> 
  );
};

export default Project;